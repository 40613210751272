import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLogged: false,
  userType: 1,
  selectedDetailsType: 1,
};
export const AcademySlice = createSlice({
  name: "academy",
  initialState,
  reducers: {
    setSelectedCourseDetailsType: (state, action) => {
      state.selectedDetailsType = action.payload.detailsType;
    },
  },
});
export const { setSelectedCourseDetailsType } = AcademySlice.actions;

export default AcademySlice.reducer;

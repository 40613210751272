import "./AddTrainer.scss";
import * as Yup from "yup";
import arrow from "../../../../Assets/Icons/arrow.jpeg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMainHeaderName } from "../../../../redux/Slices/MainHeaderSlice";
import { Loading } from "../../../Loading/Loading";
export const AddTrainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [addObject, setAddObject] = useState({
    name: "",
    farmArea: 0,
    birdTypeId: "",
    breedId: "",
    farmTypeId: "",
    farmCapacityId: 0,
    ventilationTypeId: "",
    facilitiesId: "",
    farmLicense: "",
    cityId: "",
    village: "",
    detailedDescription: "",
    ownership: "",
    userId: "",
  });
  const [file, setFile] = useState<File | null>(null);
  const [imageUploadWrapClass, setImageUploadWrapClass] =
    useState("image-upload-wrap");
  const [fileUploadContentVisible, setFileUploadContentVisible] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dependencyText, setDependecyText] = useState("اختر الأعتماد");
  useEffect(() => {
    let mainHeaderName = "اضافة مدرب";
    dispatch(setMainHeaderName({ mainHeaderName }));
  }, []);

  const removeUpload = () => {
    setFile(null);
    setImageUploadWrapClass("image-upload-wrap");
    setFileUploadContentVisible(false);
  };
  const readURL = (input: any) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageUploadWrapClass("image-upload-wrap image-dropping");
        setFileUploadContentVisible(true);
        setFile(input.files[0]);
      };

      reader.readAsDataURL(input.files[0]);
    } else {
      removeUpload();
    }
  };

  const handleDragOver = () => {
    setImageUploadWrapClass("image-upload-wrap image-dropping");
  };

  const handleDragLeave = () => {
    setImageUploadWrapClass("image-upload-wrap");
  };

  const handleChangeValues = (value: any, fieldName: string) => {
    // Update addObject with the new value
    setAddObject((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    console.log(addObject);
  };
  const handleChangeDependency = (id: number) => {
    switch (id) {
      case 1:
        setDependecyText("معتمد");
        break;
      case 2:
        setDependecyText("غير معتمد");
        break;

      default:
        break;
    }
  };
  return (
    <div className="AddTrainer">
      {isLoading === true ? <Loading /> : null}
      {/* المعلومات الرئيسية */}
      <div className="main-info-section mt-4 grid grid-cols-2 gap-6">
        {/* Header */}
        <div className="col-start-1 pr-4 pt-4 flex items-center ">
          <div className="main-info-title w-1/2">المعلومات الرئيسية</div>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>
        {/* Upload Image */}
        <div className="col-span-full flex gap-8  pr-4 items-center">
          <div className={imageUploadWrapClass}>
            <input
              onDragOver={() => handleDragOver()}
              onDragLeave={() => handleDragLeave()}
              className="file-upload-input"
              type="file"
              onChange={(e) => readURL(e.target)}
              accept="image/*"
            />
            <div className="drag-text">
              <h3>ارفق صورة المدرب</h3>
              <p className="">دعم جميع الملفات، الحجم الأقصى 60 ميجابايت</p>
            </div>
          </div>
          <div className="pl-8" onClick={() => removeUpload()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                stroke="#EB001B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="col-span-full">
          {fileUploadContentVisible && file && (
            <div className="row mb-3">
              <div className="file-upload-content">
                <img
                  className="file-upload-image"
                  src={URL.createObjectURL(file)}
                  alt="your"
                />
              </div>
            </div>
          )}
        </div>
        {/* اسم المدرب */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">اسم المدرب</p>
        </div>
        {/* الاعتماد */}
        <div className="col-start-2 pr-4 mt-4">
          <p className="title">الاعتماد</p>
        </div>

        {/* اسم المدرب input */}
        <div className="col-start-1 pr-4 ">
          <input
            className="title-content"
            type="text"
            // value=" محمود اشرف النبوي"
            placeholder="اكتب اسم المدرب"
            onChange={(e) => handleChangeValues(e.target.value, "name")}
            required
          />
        </div>

        {/* الاعتماد input */}
        <div className="col-start-2 pr-4 pl-4 ">
          <div className="dropdown-container">
            <div className="dropdown w-full">
              <label tabIndex={0}>
                <div className="flex justify-between select-mission-container">
                  <input
                    className="select-mission-type pr-4"
                    id="ownership"
                    name="ownership"
                    value={dependencyText}
                    disabled
                  />

                  <div className="arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                </div>
              </label>
              <div className="dropdown-menu w-full bg-white">
                <p
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeDependency(1)}
                >
                  معتمد
                </p>
                <p
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeDependency(2)}
                >
                  غير معتمد
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* /////////// */}
        {/* الوصف */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">الوصف</p>
        </div>

        {/* الرقم السري input */}
        <div className="col-span-full pl-4 pr-4 ">
          <div className="form-field">
            <textarea
              placeholder="اكتب الوصف"
              className="mission-address-input"
              id="detailedDescription"
              name="detailedDescription"
              onChange={(e) =>
                handleChangeValues(e.target.value, "description")
              }
            />
          </div>
        </div>
      </div>
      <div className="add-actions p-5 mr-4 ml-4">
        <div className="grid grid-cols-2">
          <div className="col-start-1">
            <div className="flex gap-4">
              <button className="add-btn">اضافة</button>
              <div className="cancel-btn" onClick={() => navigate(-1)}>
                الغاء
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

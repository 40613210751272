import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedUserType: 1,
  showAddUser: false,
  showEditUser: false,
  levelId: 0,
  levelName: "",
  stageChanged: false,
  specialtiesId: 0,
  specialtiesName: "",
  SpecialtiesChanged: false,
  userId: null,
  activeUserData: [],
  isSuspendActive: false,
  workAreas_ids: [],
  workAreaChanged: false,
  workAreas_text: "",
  showUserProfile: false,
  userName: "",
  creationDate: "",
  headerType: 0,
  filterDataObj: [],
  isFiltered: false,
  filterTotalCount: 0,
};
export const UsersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
    selectedUsersType: (state, action) => {
      state.selectedUserType = action.payload.type;
    },
    toggleShowAddUser: (state, action) => {
      state.showAddUser = action.payload.isVisible;
    },
    toggleShowEditUser: (state, action) => {
      state.showEditUser = action.payload.isVisible;
      if (action.payload.isVisible === false) {
        Object.assign(state, initialState);
      }
    },
    setSelectedStage: (state, action) => {
      state.levelId = action.payload.levelId;
      state.levelName = action.payload.levelName;
      state.stageChanged = action.payload.stageChanged;
    },
    setSelectedSpecialties: (state, action) => {
      state.specialtiesId = action.payload.specialtiesId;
      state.specialtiesName = action.payload.specialtiesName;
      state.SpecialtiesChanged = action.payload.SpecialtiesChanged;
    },
    setUserId: (state, action) => {
      state.userId = action.payload.userId;
    },
    setUserName: (state, action) => {
      state.userName = action.payload.userName;
    },
    setUserCreationDate: (state, action) => {
      state.creationDate = action.payload.creationDate;
    },
    setActiveUserData: (state, action) => {
      state.activeUserData = action.payload.activeUserData;
    },
    toggleShowSuspendPopUp: (state, action) => {
      state.isSuspendActive = action.payload.isSuspendActive;
    },
    setSelectedWorkAreas: (state, action) => {
      state.workAreas_ids = action.payload.rangeIds;
      state.workAreas_text = action.payload.rangeTitle;
      state.workAreaChanged = action.payload.workAreaChanged;
    },
    toggleShowUserProfile: (state, action) => {
      state.showUserProfile = action.payload.isVisible;
    },
    handleSubmitUsersFilterResult: (state, action) => {
      state.headerType = action.payload.type;
      state.filterDataObj = action.payload.filterObj;
      state.isFiltered = action.payload.isFiltered;
      state.filterTotalCount = action.payload.totalCount;
    },
  },
});
export const {
  selectedUsersType,
  toggleShowAddUser,
  toggleShowEditUser,
  setSelectedStage,
  setSelectedSpecialties,
  setUserId,
  setActiveUserData,
  toggleShowSuspendPopUp,
  setSelectedWorkAreas,
  setUserName,
  toggleShowUserProfile,
  setUserCreationDate,
  handleSubmitUsersFilterResult,
} = UsersSlice.actions;

export default UsersSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  farmsIds: [],
  farmsText: "",
  farmsModalChanged: false,
  farmId: 0,
  farmName: "",
  breederName: "",
  farmrCity: "",
  farmGovernorateCity: "",
  cityId: 0,
  governorateId: 0,
};
export const FarmsModalSlice = createSlice({
  name: "farmsModal",
  initialState,
  reducers: {
    setSelectedMultiFarms: (state, action) => {
      state.farmsIds = action.payload.farmsIds;
      state.farmsText = action.payload.farmsText;
      state.farmsModalChanged = action.payload.isChanged;
    },
    setSelectedOneFarm: (state, action) => {
      state.farmId = action.payload.farmId;
      state.farmName = action.payload.farmName;
      state.farmsModalChanged = action.payload.isChanged;
      state.farmrCity = action.payload.farmrCity;
      state.farmGovernorateCity = action.payload.farmGovernorateCity;
      state.cityId = action.payload.cityId;
      state.governorateId = action.payload.governorateId;
    },
  },
});
export const { setSelectedMultiFarms, setSelectedOneFarm } =
  FarmsModalSlice.actions;

export default FarmsModalSlice.reducer;

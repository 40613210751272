import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { useEffect, useState } from "react";
import "./AddBreeders.scss";
import eye from "../../../Assets/Icons/eye.svg";
import arrow from "../../../Assets/Icons/arrow.jpeg";
import { Cities } from "../../Farms/SubComponents/Cities";
import { Governorates } from "../../Farms/SubComponents/Governorates";
import { Loading } from "../../Loading/Loading";
export const AddBreeders = () => {
  const [file, setFile] = useState<File | null>(null);
  const [imageUploadWrapClass, setImageUploadWrapClass] =
    useState("image-upload-wrap");
  const [fileUploadContentVisible, setFileUploadContentVisible] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [hasWhatsApp, setHasWhatsApp] = useState(false);
  const [showGovernoratePopUp, setShowGovernoratePopUp] = useState(false);
  const [showCityPopUp, setShowCityPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [farmSizeId, setFarmSizeId] = useState(0);
  const [ownerShiptTitle, setOwnerShipTitle] = useState("اختر");
  const [activityType, setActivityType] = useState(0);
  const [activityText, setActivityText] = useState("اختر");
  const [farmSizeTxt, setFarmSizeText] = useState("اختر");

  const [addObject, setAddObject] = useState({
    name: "",
    mobile: "",
    email: "",
    whatsapp: "",
    address: "",
    description: "",
    type: 0,
    cityId: 0,
    activityTypeId: 0,
    approximateBreedingSize: 0,
    farmsCount: 0,
  });
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let mainHeaderName = "اضافة مربي";
    dispatch(setMainHeaderName({ mainHeaderName }));
  }, []);

  const removeUpload = () => {
    setFile(null);
    setImageUploadWrapClass("image-upload-wrap");
    setFileUploadContentVisible(false);
  };
  const readURL = (input: any) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageUploadWrapClass("image-upload-wrap image-dropping");
        setFileUploadContentVisible(true);
        setFile(input.files[0]);
      };

      reader.readAsDataURL(input.files[0]);
    } else {
      removeUpload();
    }
  };

  const handleDragOver = () => {
    setImageUploadWrapClass("image-upload-wrap image-dropping");
  };

  const handleDragLeave = () => {
    setImageUploadWrapClass("image-upload-wrap");
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleGovernoratePopUp = () => {
    setShowGovernoratePopUp(!showGovernoratePopUp);
  };
  const handleCityPopUp = () => {
    setShowCityPopUp(!showCityPopUp);
  };
  const handleChangeActivityType = (id: number) => {
    setActivityType(id);
    if (id === 1) {
      setActivityText("فردي");
    } else if (id === 2) {
      setActivityText("مؤسسي");
    } else {
      setActivityText("اختر");
    }
  };

  const handleChangeFarmSize = (id: number) => {
    setFarmSizeId(id);
    if (id === 1) {
      setFarmSizeText("اققل من 1000");
    } else if (id === 2) {
      setFarmSizeText("اققل من 5000");
    } else if (id === 3) {
      setFarmSizeText("اققل من 10000");
    } else if (id === 4) {
      setFarmSizeText("اققل من 25000");
    } else if (id === 5) {
      setFarmSizeText("اققل من 50000");
    } else if (id === 6) {
      setFarmSizeText("اققل من 100000");
    } else if (id === 7) {
      setFarmSizeText("اققل من 200000");
    } else if (id === 8) {
      setFarmSizeText("اققل من 500000");
    } else {
      setFarmSizeText("اختر");
    }
  };

  const handleChangeValues = (value: any, fieldName: string) => {
    // Update addObject with the new value
    setAddObject((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <div className="AddBreeders">
      {/* Comming Soon */}
      {/* <CommingSoon /> */}
      {showGovernoratePopUp === true ? <Governorates isEdit={false} /> : null}
      {showCityPopUp === true ? <Cities isEdit={false} /> : null}
      {isLoading === true ? <Loading /> : null}

      {/* المعلومات الرئيسية */}
      <div className="main-info-section mt-4 grid grid-cols-2 gap-6">
        {/* Header */}
        <div className="col-start-1 pr-4 pt-4 flex items-center ">
          <div className="main-info-title w-1/2">المعلومات الرئيسية</div>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>
        {/* Upload Image */}
        <div className="col-span-full flex gap-8 pr-4 items-center">
          <div className={imageUploadWrapClass}>
            <input
              onDragOver={() => handleDragOver()}
              onDragLeave={() => handleDragLeave()}
              className="file-upload-input"
              type="file"
              onChange={(e) => readURL(e.target)}
              accept="image/*"
            />
            <div className="drag-text">
              <h3>ارفق صورة ملف شخصي</h3>
              <p className="">دعم جميع الملفات، الحجم الأقصى 60 ميجابايت</p>
            </div>
          </div>
          <div onClick={() => removeUpload()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                stroke="#EB001B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="col-span-full">
          {fileUploadContentVisible && file && (
            <div className="row mb-3">
              <div className="file-upload-content">
                <img
                  className="file-upload-image"
                  src={URL.createObjectURL(file)}
                  alt="your"
                />
              </div>
            </div>
          )}
        </div>
        {/* الأسم ثلاثي */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">الاسم ثلاثي</p>
        </div>
        {/* البريد الالكتروني */}
        <div className="col-start-2 pr-4 mt-4">
          <p className="title">البريد الالكتروني</p>
        </div>

        {/* الاسم الثلاثي input */}
        <div className="col-start-1 pr-4 ">
          <input
            className="title-content"
            type="text"
            value=" محمود اشرف النبوي"
            onChange={(e) => handleChangeValues(e.target.value, "name")}
            required
          />
        </div>

        {/* البريد الالكتروني input */}
        <div className="col-start-2 pr-4 pl-4 ">
          <input
            className="title-content"
            type="email"
            required
            onChange={(e) => handleChangeValues(e.target.value, "email")}
          />
        </div>

        {/* /////////// */}
        {/* الرقم السري */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">الرقم السري</p>
        </div>
        {/* تاكيد الرقم السري */}
        <div className="col-start-2 pr-4 mt-4 ">
          <p className="title">تاكيد الرقم السري</p>
        </div>

        {/* الرقم السري input */}
        <div className="col-start-1 pr-4 ">
          <div className="form-field">
            <input
              type={showPassword === true ? "text" : "password"}
              className="input input-lg max-w-full first-name-input"
              placeholder="****"
              onChange={(e) => handleChangeValues(e.target.value, "password")}
              id="password-user"
            />

            <span
              className="relative inset-y-0  inline-flex items-center"
              style={{ marginTop: "-40px", right: "28rem" }}
            >
              <img
                src={eye}
                alt="eye-pw"
                onClick={() => togglePasswordVisibility()}
              />
            </span>
          </div>
        </div>

        {/* تاكيد الرقم السري input */}
        <div className="col-start-2 pr-4 pl-4">
          <div className="form-field">
            <input
              type={showPassword === true ? "text" : "password"}
              className="input input-lg max-w-full first-name-input"
              placeholder="****"
              onChange={(e) =>
                handleChangeValues(e.target.value, "re_password")
              }
              id="password-user"
            />

            <span
              className="relative inset-y-0  inline-flex items-center"
              style={{ marginTop: "-40px", right: "28rem" }}
            >
              <img
                src={eye}
                alt="eye-pw"
                onClick={() => togglePasswordVisibility()}
              />
            </span>
          </div>
        </div>

        {/* /////////// */}
        {/* رقم الهاتف */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">رقم الهاتف</p>
        </div>

        {/* رقم الواتساب */}
        <div className="col-start-2 pr-4 mt-4">
          <p className="title">رقم الواتساب</p>
        </div>

        {/* رقم الهاتف input */}
        <div className="col-start-1 pr-4 ">
          <div className="form-control relative">
            <input
              type="number"
              pattern="[0-9]*"
              className="input input-lg max-w-full search-input bg-white"
              placeholder="رقم الهاتف"
              onChange={(e) => handleChangeValues(e.target.value, "mobile")}
            />

            <span className="absolute inset-y-0 right-4 inline-flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
              >
                <rect
                  x="5.03564"
                  y="1.93036"
                  width="12.8703"
                  height="18.3861"
                  rx="2.75791"
                  stroke="#28303F"
                  stroke-width="1.37896"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5514 17.5585H12.39"
                  stroke="#28303F"
                  stroke-width="1.37896"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>

        {/* رقم الواتساب input */}
        <div className="col-start-2 pr-4 pl-4">
          <div className="form-control relative">
            <input
              type="number"
              pattern="[0-9]*"
              className="input input-lg max-w-full search-input bg-white"
              placeholder="رقم الواتساب"
              onChange={(e) => handleChangeValues(e.target.value, "whatsapp")}
            />

            <span className="absolute inset-y-0 right-4 inline-flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
              >
                <g clip-path="url(#clip0_3071_13332)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.8926 3.12334C13.258 1.48729 11.0849 0.585567 8.7698 0.584839C3.99918 0.584839 0.116465 4.46683 0.114282 9.23962C0.113554 10.7651 0.512379 12.2541 1.27 13.567L0.0415039 18.0516L4.62944 16.8479C5.8936 17.5378 7.31714 17.901 8.76543 17.9017H8.76907C13.539 17.9017 17.4224 14.019 17.4246 9.24617C17.426 6.93401 16.5265 4.75867 14.8926 3.12334ZM8.7698 16.4396H8.76688C7.4758 16.4396 6.21019 16.0924 5.10541 15.4367L4.84268 15.2809L2.12005 15.9949L2.8471 13.3399L2.67608 13.0678C1.95557 11.9222 1.57567 10.5984 1.5764 9.23962C1.57785 5.27321 4.80557 2.04623 8.77343 2.04623C10.6948 2.04623 12.5011 2.79584 13.8592 4.15534C15.2172 5.51556 15.9647 7.32265 15.9639 9.24472C15.9617 13.2126 12.7348 16.4396 8.7698 16.4396ZM12.7158 11.0518C12.4997 10.9434 11.4364 10.4201 11.2377 10.348C11.0397 10.276 10.8956 10.2396 10.7508 10.4557C10.606 10.6719 10.1926 11.1595 10.066 11.3043C9.94007 11.4484 9.81344 11.4666 9.59728 11.3582C9.38113 11.2498 8.68392 11.022 7.85788 10.2847C7.21525 9.71123 6.78077 9.0031 6.65486 8.78622C6.52895 8.56934 6.64176 8.45289 6.74947 8.34518C6.847 8.24839 6.96562 8.09264 7.07406 7.96601C7.18323 7.84083 7.21889 7.75058 7.29167 7.60575C7.36372 7.46165 7.32806 7.33502 7.27348 7.22658C7.21889 7.11887 6.78659 6.05412 6.60683 5.62109C6.43143 5.19898 6.25313 5.25647 6.11994 5.24992C5.99403 5.24337 5.84993 5.24264 5.7051 5.24264C5.561 5.24264 5.32666 5.2965 5.1287 5.51338C4.93074 5.73026 4.37181 6.25353 4.37181 7.31755C4.37181 8.3823 5.1469 9.41065 5.25461 9.55475C5.36232 9.69886 6.77931 11.8837 8.94883 12.8203C9.46483 13.043 9.86802 13.1762 10.1817 13.2759C10.6999 13.4404 11.1715 13.4171 11.5441 13.3618C11.9597 13.2999 12.8235 12.8385 13.004 12.3334C13.1845 11.8283 13.1845 11.3946 13.1299 11.3051C13.0761 11.2141 12.932 11.1602 12.7158 11.0518Z"
                    fill="#70D44B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3071_13332">
                    <rect
                      width="17.4668"
                      height="17.4668"
                      fill="white"
                      transform="translate(0 0.584595)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
        </div>

        {/* حساب الفيس بوك */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">حساب الفيس بوك</p>
        </div>

        {/* حساب الفيس بوك input */}
        <div className="col-start-1 pr-4 ">
          <input
            className="title-content"
            type="text"
            // value="mahmoud@yahoo.com"
          />
        </div>

        {/* هذا الهاتف عليه واتساب */}
        <div className="col-start-2 mt-4 pr-4">
          <div className="flex gap-20">
            <span className="title">هذا الهاتف عليه واتساب ؟</span>
            <div className="ml-">
              <input
                type="checkbox"
                className="switch switch-success"
                checked={hasWhatsApp}
                onChange={() => setHasWhatsApp(!hasWhatsApp)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* العنوان */}
      <div className="location-section mt-4 grid grid-cols-2 gap-4">
        {/* Header */}
        <div className="col-start-1 pr-4 pt-4 flex items-center ">
          <div className="farm-info-title w-1/2">العنوان</div>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/*  المحافظة */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">المحافظة</p>
        </div>

        {/*  المدينة */}
        <div className="col-start-2 pr-4 mt-4">
          <p className="title">المدينة</p>
        </div>

        {/* المحافظة  input   */}
        <div className="col-start-1 pr-4 ">
          <label htmlFor="modal-12">
            <div
              className="flex justify-between select-mission-container"
              onClick={() => handleGovernoratePopUp()}
            >
              <input
                className="select-mission-type pr-4"
                value={
                  stateFromFarmsSlice.selectedGovernorateId === 0
                    ? "اختر المحافظة"
                    : stateFromFarmsSlice.selectedGovernorateName
                }
                disabled
                id="govId"
                name="govId"
              />

              <div className="arrow">
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          </label>
        </div>
        {/*  المدينة :input */}
        <div className="col-start-2 pr-4 pl-4">
          <label htmlFor="modal-13">
            <div
              className="flex justify-between select-mission-container"
              onClick={() => handleCityPopUp()}
            >
              <input
                className="select-mission-type pr-4"
                value={
                  stateFromFarmsSlice.selectedCityId === 0
                    ? "اختر المدينة"
                    : stateFromFarmsSlice.selectedCityName
                }
                disabled
                id="cityId"
                name="cityId"
              />

              <div className="arrow">
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          </label>
        </div>

        {/*  حي / قرية */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">حي / قرية</p>
        </div>

        {/*  الوصف التفصيلي */}
        <div className="col-start-2 pr-4 mt-4">
          <p className="title">الوصف التفصيلي</p>
        </div>

        {/* حي / قرية  input   */}
        <div className="col-start-1 pr-4 ">
          <input
            type="text"
            placeholder="القرية"
            className="mission-address-input"
            id="village"
            name="village"
            onChange={(e) => handleChangeValues(e.target.value, "address")}
          />
        </div>
        {/*  الوصف التفصيلي :input */}
        <div className="col-start-2 pr-4 pl-4">
          <textarea
            placeholder="الوصف التفصيلي"
            className="mission-address-input"
            id="detailedDescription"
            name="detailedDescription"
            onChange={(e) => handleChangeValues(e.target.value, "description")}
          />
        </div>
      </div>

      <div className="activites-section mt-4 grid grid-cols-2 gap-4">
        {/* Header */}
        <div className="col-start-1 pr-4 pt-4 flex items-center ">
          <div className="farm-info-title w-1/2">معلومات النشاط</div>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/*  نوع النشاط */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">نوع النشاط</p>
        </div>

        {/*  حجم التربية التقريبي */}
        <div className="col-start-2 pr-4 mt-4">
          <p className="title">حجم التربية التقريبي</p>
        </div>

        {/* نوع النشاط  input   */}
        <div className="col-start-1 pr-4 ">
          <div className="dropdown-container">
            <div className="dropdown w-full">
              <label tabIndex={0}>
                <div className="flex justify-between select-mission-container">
                  <input
                    className="select-mission-type pr-4"
                    id="ownership"
                    name="ownership"
                    value={activityText}
                    disabled
                  />

                  <div className="arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                </div>
              </label>
              <div className="dropdown-menu w-full bg-white">
                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeActivityType(1)}
                >
                  فردي
                </a>
                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeActivityType(2)}
                >
                  مؤسسي
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*  حجم التربية التقريبي :input */}
        <div className="col-start-2 pr-4 pl-4">
          <div className="dropdown-container">
            <div className="dropdown w-full">
              <label tabIndex={0}>
                <div className="flex justify-between select-mission-container">
                  <input
                    className="select-mission-type pr-4"
                    id="ownership"
                    name="ownership"
                    value={farmSizeTxt}
                    disabled
                  />

                  <div className="arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                </div>
              </label>
              <div className="dropdown-menu w-full bg-white">
                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(1)}
                >
                  اققل من 1000
                </a>
                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(2)}
                >
                  اققل من5000
                </a>

                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(3)}
                >
                  اققل من 10000
                </a>

                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(4)}
                >
                  اققل من 25000
                </a>

                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(5)}
                >
                  اققل من 50000
                </a>

                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(6)}
                >
                  اققل من 100000
                </a>
                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(7)}
                >
                  اققل من 200000
                </a>

                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(8)}
                >
                  اققل من 500000
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="add-actions p-5 ">
        <div className="grid grid-cols-2">
          <div className="col-start-1">
            <div className="flex gap-4">
              <button className="add-btn">اضافة</button>
              <div className="cancel-btn" onClick={() => navigate(-1)}>
                الغاء
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

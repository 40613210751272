import "./Users.scss";
import userIcon from "../../Assets/Icons/40px.svg";
import { useEffect, useState } from "react";
import API from "../../Api";
import { Loading } from "../Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserCreationDate,
  setUserId,
  setUserName,
  toggleShowEditUser,
} from "../../redux/Slices/UsersSlice";
import { useNavigate } from "react-router-dom";
import { TotalCount } from "../../SubComponents/TotalCount/TotalCount";

export const StoppedUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const stateFromUserSlice = useSelector((state: any) => state.users);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    if (
      stateFromUserSlice.isFiltered === true &&
      stateFromUserSlice.headerType === 3 &&
      stateFromUserSlice.filterDataObj.length > 0
    ) {
      if (stateFromUserSlice.filterDataObj[0].length > 0) {
        setUsers(stateFromUserSlice.filterDataObj[0]);
        setTotalRows(stateFromUserSlice.filterTotalCount);
        setIsLoading(false);
      } else {
        setUsers([]);
        setTotalRows(0);
        setIsLoading(false);
      }
    } else {
      API.get("dashboard/salesman?account_status=3&limit=50000000").then(
        (res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setTotalRows(res.data.totalCount);
            setIsLoading(false);
          }
        }
      );
    }
  }, [stateFromUserSlice.isFiltered]);
  const showEditUser = (userId: number) => {
    navigate(`/Users/Edit/${userId}`);
    dispatch(setUserId({ userId }));
  };
  const showUserDetailsComponent = (
    userId: Number,
    userName: string,
    creationDate: string
  ) => {
    dispatch(setUserId({ userId }));
    dispatch(setUserName({ userName }));
    dispatch(setUserCreationDate({ creationDate }));
    navigate(`/Users/Profile/${userId}`);
  };
  return (
    <div className="Users">
      <div className="mt-4">
        <TotalCount count={totalRows} title="الموقوفون" />
      </div>

      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
      <div className="grid grid-cols-2 gap-4">
        {users.map((user: any, index) => (
          <div
            key={index}
            className={`grid col-start-${index % 2 === 0 ? 1 : 2} p-4`}
          >
            <div className="userCard pr-4 pt-8 ">
              <div className="flex justify-between">
                <div className="flex gap-4">
                  <div>
                    <img src={userIcon} alt="user" />
                  </div>
                  <div className="grid">
                    <span
                      className="user-name cursor-pointer"
                      onClick={() =>
                        showUserDetailsComponent(
                          Number(user.id),
                          user.name,
                          user.created_at
                        )
                      }
                    >
                      {user.name}
                    </span>
                    <div className="flex">
                      <span className="typeOfSpecialization">التخصص</span>
                      <span className="type-status">
                        {`. ${user.graduationStatus} . عضو منذ ${user.memberSince}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

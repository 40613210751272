import "../Farms.scss";
import chicken from "../../../Assets/Icons/Chicken.jpg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../Api";
import { Loading } from "../../Loading/Loading";
import { Link, useNavigate } from "react-router-dom";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { CommingSoon } from "../../CommingSoon/CommingSoon";
import { TotalCount } from "../../../SubComponents/TotalCount/TotalCount";

export const UnderRevisionFarm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [farms, setFarms] = useState([]);
  const [pageSize, setPageSize] = useState(12);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);

  useEffect(() => {
    setIsLoading(true);
    let mainHeaderName = "العنابر";
    dispatch(setMainHeaderName({ mainHeaderName }));
    /*
    * Back End
      1 => تحت المراجعة
      0=> كل العنابر
    * Front End
      1=> كل العنابر
      2=> تحت المراجعة 
      3=> مفعلة
     */

    let url = `farms?status_id=1&page=${pageNumber}&limit=${pageSize}&sort_by=id&sort_order=DESC`;

    API.get(url).then((res) => {
      if (res) {
        setTotalRows(res.data.meta.totalItems);
        const updatedFarmersData = res.data.items.map((farmer: any) => ({
          ...farmer,
          photo: farmer.photo !== null ? farmer.photo : { chicken },
        }));
        setFarms(updatedFarmersData);

        setIsLoading(false);
      }
    });
  }, [pageNumber, pageSize, setFarms]);

  const hanldeChangePage = (targetPN: number) => {
    setIsLoading(true);
    API.get(`farms?page=${pageNumber}&limit=${pageSize}`).then((res) => {
      if (res) {
        setFarms(res.data.items);
        setTotalRows(res.data.totalCount);
        setPageNumber(targetPN);

        setIsLoading(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    });
  };

  const renderPagination = () => {
    const nPages = Math.ceil(totalRows / pageSize);
    const numbers = Array.from({ length: nPages }, (_, index) => index + 1);
    let paginationItem = numbers.map((n) => {
      return (
        <>
          <button
            className={pageNumber === n ? "btn btn-pagination-active" : "btn"}
            onClick={() => hanldeChangePage(n)}
          >
            {n}
          </button>
        </>
      );
    });
    return (
      <div
        className={
          totalRows > 5
            ? "pagination w-full max-w-xs overflow-auto"
            : "pagination"
        }
      >
        {paginationItem}
      </div>
    );
  };

  const handleImageError = (event: any) => {
    // Set a fallback image source when the original image fails to load
    event.target.src = chicken;
  };

  const sortData = (property: string) => {
    setIsLoading(true);
    let data = farms;

    const sortedData = [...data].sort((a, b) => {
      if (a[property] < b[property]) return -1;
      if (a[property] > b[property]) return 1;
      return 0;
    });
    setFarms(sortedData);
    setIsLoading(false);
  };

  /* 
 * sorty by value inside object 
 const sortDataByNestedValue = (data: any[], nestedProperty: string, updateState: (sortedData: any[]) => void) => {
    const sortedData = [...data].sort((a, b) => {
      const nestedValueA = a[nestedProperty];
      const nestedValueB = b[nestedProperty];

      if (nestedValueA < nestedValueB) return -1;
      if (nestedValueA > nestedValueB) return 1;
      return 0;
    });
    updateState(sortedData);
  };

  // Usage example: Sort farms by user's mobile number
  const handleSortByUserMobileNumber = () => {
    sortDataByNestedValue(farms, 'user.mobile_number', setFarms);
  };
 */

  return (
    <>
      <div
        className={`${
          stateFromFarmsSlice.displayTypeId === 1 ? "FarmList" : "Farms"
        }  grid grid-cols-2 pt-5 pr-5 pl-5`}
      >
        <>
          {stateFromFarmsSlice.displayTypeId === 1 ? (
            <>
              <div className="mt-4">
                {/* Total Count */}
                <TotalCount count={totalRows} title="العنابر" />
              </div>

              {farms.map((item: any, index: any) => {
                return (
                  <div className="grid grid-cols-2 col-span-full mb-4 mr-4 ml-4 mt-4  mission-content">
                    <div className="col-start-1">
                      <span
                        className="title cursor-pointer"
                        onClick={() => navigate(`/Farms/${item.id}`)}
                      >
                        {item.user != null ? item.user.name : ""}-{item.name}-{" "}
                        {item.city != null && item.governorate != null ? (
                          <>
                            {item.governorate.name}-{item.city.name}
                          </>
                        ) : null}
                      </span>
                    </div>

                    <div className="col-start-2 pl-4 flex justify-end">
                      <span className="title">{item.id}</span>
                    </div>

                    <div className="col-start-1  mt-2 flex gap-4 justify-start">
                      <div className="flex gap-2">
                        <span className="title">المربي :</span>
                        <span className="mission-type">
                          {item.user != null ? (
                            <>
                              <Link to={`/Breeders/${item.user.id}`}>
                                <a>{item.user.name}</a>
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>

                      <div className="flex gap-2">
                        <span className="title">تمت الاضافة بواسطة :</span>
                        <span className="mission-type">
                          {item.creator != null &&
                          item.creator !== undefined ? (
                            <>
                              <div className="flex gap-4 assigned-to">
                                <Link to={`/Users/Profile/${item.creator.id}`}>
                                  {item.creator.name}
                                </Link>
                              </div>
                            </>
                          ) : null}
                        </span>
                      </div>
                    </div>

                    <div className="col-start-2 pl-4 mt-2 flex justify-end">
                      <>
                        {item.status_id === "1" ? (
                          <>
                            <div className="status-revision flex    p-2">
                              <span> قيد المراجعة </span>
                            </div>
                          </>
                        ) : item.status_id === "2" ? (
                          <>
                            <div className="status-empty flex    p-2">
                              <span> فارغ </span>
                            </div>
                          </>
                        ) : item.status_id === "3" ? (
                          <>
                            <div className="status-inprogress flex    p-2">
                              <span> في التشغيل </span>
                            </div>
                          </>
                        ) : item.status_id === "4" ? (
                          <>
                            <div className="status-sale flex absolute bottom-4 left-3  p-2">
                              <span> معروض للبيع </span>
                            </div>
                          </>
                        ) : item.status_id === "5" ? (
                          <>
                            <div className="status-refused flex    p-2">
                              <span> مرفوض </span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    </div>

                    <div className="col-start-1 flex gap-4 justify-start mt-1">
                      <div className="flex gap-2">
                        <span className="title">المساحة :</span>
                        <span className="mission-type">{item.area}</span>
                      </div>

                      <div className="flex gap-2">
                        <span className="title">السعة :</span>
                        <span className="mission-type">
                          {item.capacity != null ? item.capacity.capacity : ""}
                        </span>
                      </div>

                      <div className="flex gap-2">
                        <span className="title">نوع التربية :</span>
                        <span className="mission-type">
                          {item.farmingType != null
                            ? item.farmingType.type
                            : ""}
                        </span>
                      </div>

                      <div className="flex gap-2">
                        <span className="title">نوع التهوئة :</span>
                        <span className="mission-type">
                          {item.ventilationType != null
                            ? item.ventilationType.type
                            : ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-start-1 flex gap-4 justify-start mt-1">
                      {item.latitude != null && item.longitude ? (
                        <>
                          <a
                            href={`https://www.google.com/maps/dir/?api=1&destination=${item.latitude},${item.longitude}`}
                            target="_blank"
                            className="title"
                            rel="noreferrer"
                          >
                            العنوان علي الخريطة
                          </a>
                        </>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div className="mt-4">
                {/* Total Count */}
                <TotalCount count={totalRows} title="العنابر" />
              </div>

              <div className="grid grid-cols-3 gap-6 mt-5 pr-4 pl-4 col-span-full">
                {isLoading === true ? (
                  <>
                    <Loading />
                  </>
                ) : null}
                {farms.map((item: any, index: any) => {
                  return (
                    <div className="big-section mb-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
                      <div className="course-cover relative">
                        <>
                          {item.status_id === "1" ? (
                            <>
                              <div className="status-revision flex absolute bottom-4 left-3  p-2">
                                <span> قيد المراجعة </span>
                              </div>
                            </>
                          ) : item.status_id === "2" ? (
                            <>
                              <div className="status-empty flex absolute bottom-4 left-3  p-2">
                                <span> فارغ </span>
                              </div>
                            </>
                          ) : item.status_id === "3" ? (
                            <>
                              <div className="status-inprogress flex absolute bottom-4 left-3  p-2">
                                <span> في التشغيل </span>
                              </div>
                            </>
                          ) : item.status_id === "4" ? (
                            <>
                              <div className="status-sale flex absolute bottom-4 left-3  p-2">
                                <span> معروض للبيع </span>
                              </div>
                            </>
                          ) : item.status_id === "5" ? (
                            <>
                              <div className="status-refused flex absolute bottom-4 left-3  p-2">
                                <span> مرفوض </span>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>

                        <img
                          src={item.photo}
                          onClick={() => navigate(`/Farms/${item.id}`)}
                          alt="course-cover"
                          className="course-img"
                          onError={handleImageError}
                          loading="lazy"
                        />
                      </div>
                      <div className="grid grid-cols-2 pl-4 ">
                        <div className="col-start-1">
                          <p
                            className="farm-name mb-2 mt-2 cursor-pointer"
                            onClick={() => navigate(`/Farms/${item.id}`)}
                          >
                            {item.user != null ? item.user.name : ""}-
                            {item.name}-{" "}
                            {item.city != null && item.governorate != null ? (
                              <>
                                {item.governorate.name}-{item.city.name}
                              </>
                            ) : null}
                          </p>
                        </div>
                        <div className="col-start-2 flex justify-end">
                          <p className="farm-name mb-2 mt-2">{item.id}</p>
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <span className="title">تمت الاضافة بواسطة:</span>
                        <span className="title-content cursor-pointer">
                          {item.creator != null ? (
                            <>
                              <Link to={`/Users/Profile/${item.creator.id}`}>
                                <a>{item.creator.name}</a>
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </>

        {/* Pagination */}
        <div className="flex justify-end col-start-2 pl-8">
          {renderPagination()}
        </div>
      </div>

      {isLoading === true ? <Loading /> : null}
    </>
  );
};

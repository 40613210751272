import "./UsersFilter.scss";
import Fuse from "fuse.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import API from "../../../Api";
import { Loading } from "../../Loading/Loading";
import { handleSubmitUsersFilterResult } from "../../../redux/Slices/UsersSlice";
export const UsersFilter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setLocationData] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(1);
  const [selectedItemName, setSelectedItemName] = useState("");
  const [selectedTypeId, setSelectedTypeId] = useState(0);
  const [selectedTypeName, setSelectedTypeName] = useState("");
  const [specializationSearchResults, setSpecializationSearchResults] =
    useState<any[]>([{}]);
  const [selectedItemId, setSelectedItemId] = useState(1);
  const [locationSearchResults, setLocationSearchResults] = useState<any[]>([
    {},
  ]);

  const [filterObject, setFilterObject] = useState({
    gov_ids: 0,
    city_ids: 0,
    specialty_id: 0,
  });

  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["name"],
  };

  const Specialization_Options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["name"],
  };
  const fuse = new Fuse(locationSearchResults, options);
  const Specialization_Fuse = new Fuse(
    specializationSearchResults,
    Specialization_Options
  );

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    //governorates API
    API.get(`governorates`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setLocationSearchResults(res.data);
          setLocationData(res.data);
          setIsLoading(false);
        }
      }
    });
    API.get(`specialties`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setSpecializationSearchResults(res.data);

          setIsLoading(false);
        }
      }
    });
  }, []);

  const handleSpecializationSearch = (event: any) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setSpecializationSearchResults(data);
      return;
    }

    const results = Specialization_Fuse.search(value);
    const items = results.map((result) => result.item);
    setSpecializationSearchResults(items);
  };
  const handleChangeType = (itemId: number, itemName: string) => {
    setSelectedItemId(itemId === selectedItemId ? 0 : itemId);
    setSelectedItemName(itemId === selectedItemId ? "null" : itemName);
  };

  const handleLocationSearch = (event: any) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setLocationSearchResults(data);
      return;
    }

    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setLocationSearchResults(items);
  };

  const handleChangeLocationType = (itemId: number, itemName: string) => {
    setIsLoading(true);
    setSelectedLocationId(0);
    setSelectedItemId(itemId === selectedItemId ? 0 : itemId);
    setSelectedItemName(itemId === selectedItemId ? "null" : itemName);
    if (itemId === 2) {
      API.get(`cities`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    } else {
      API.get(`governorates`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    }
  };

  const handleChangeLocationContent = (itemId: number, itemName: string) => {
    setSelectedLocationId(itemId === selectedLocationId ? 0 : itemId);
    if (selectedItemId === 1) {
      //محافظات
      setFilterObject((prevState) => ({
        ...prevState,
        ["gov_ids"]: Number(itemId),
        ["city_ids"]: 0,
      }));
    } else {
      //مناطق
      setFilterObject((prevState) => ({
        ...prevState,
        ["city_ids"]: Number(itemId),
        ["gov_ids"]: 0,
      }));
    }
  };

  const handleSubmitFilter = () => {
    setIsLoading(true);

    let param1 =
      filterObject.gov_ids !== 0 ? `gov_ids=${filterObject.gov_ids}&` : "";
    let param2 =
      filterObject.city_ids !== 0 ? `city_ids=${filterObject.city_ids}&` : "";
    let param3 = selectedItemId !== 0 ? `specialty_id=${selectedItemId}&` : "";
    let status = `2`;
    let type = 0;
    let filterObj: {}[] = [];
    let isFiltered = true;
    let totalCount = 0;

    if (location.pathname.includes("ActiveSalesMan")) {
      type = 1;
      status = `2`;

      API.get(
        `dashboard/salesman?account_status=${status}&sort_by=id&sort_order=DESC&${param1}${param2}${param3}`
      ).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitUsersFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
          })
        );
      });
    } else if (location.pathname.includes("DisabledSalesMan")) {
      type = 2;
      status = `1`;

      API.get(
        `dashboard/salesman?account_status=${status}&sort_by=id&sort_order=DESC&${param1}${param2}${param3}`
      ).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitUsersFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
          })
        );
      });
    } else if (location.pathname.includes("StoppedSalesMan")) {
      type = 3;
      status = `3`;

      API.get(
        `dashboard/salesman?&account_status=${status}&sort_by=id&sort_order=DESC&${param1}${param2}${param3}`
      ).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitUsersFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
          })
        );
      });
    }
  };

  return (
    <div className="UsersFilter">
      {isLoading === true ? <Loading /> : null}
      <input className="modal-state" id="modal-2200" type="checkbox" />
      <div className="modal w-screen">
        <label className="modal-overlay" htmlFor="modal-2200"></label>
        <div
          className="modal-content flex flex-col gap-5 max-w-3xl"
          style={{ width: "600px" }}
        >
          <div className="grid grid-cols-2">
            {/* المحافظة / المكان */}
            <>
              <div className="col-start-1 flex justify-start pr-4">
                <div className="head">المكان</div>
              </div>
              <div className="col-span-full mt-4 flex gap-8 items-center">
                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeLocationType(1, "governorate")}
                    checked={1 === selectedItemId}
                  />
                  <span className="txt">المحافظة</span>
                </div>

                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeLocationType(2, "city")}
                    checked={2 === selectedItemId}
                  />
                  <span className="txt">المدينة</span>
                </div>
              </div>
              {/* اكتب كلمة البحث للمحافظة */}
              <div className="col-span-full p-4">
                <div className="form-control relative w-full">
                  <input
                    type="text"
                    className="input input-lg max-w-full input-search"
                    onChange={handleLocationSearch}
                    placeholder={
                      selectedItemId === 1
                        ? "ابحث عن المحافظة"
                        : "ابحث عن المدينة"
                    }
                  />

                  <span className="absolute inset-y-0 right-4 inline-flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.4998 17.4998L14.1665 14.1665"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              {/* قائمة المحافظات */}
              <div className="col-span-full mt-4 p-4 mb-4  h-40 overflow-y-auto ">
                <div className="types-list ">
                  <ul className="list-none scrollable-list">
                    {locationSearchResults.map((item) => {
                      return (
                        <>
                          {isLoading === true ? (
                            <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                          ) : (
                            <li className="flex justify-between pl-4 py-2">
                              <span className="list-text">{item.name}</span>
                              <div>
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-bordered-success"
                                  onChange={() =>
                                    handleChangeLocationContent(
                                      item.id,
                                      item.name
                                    )
                                  }
                                  checked={item.id === selectedLocationId}
                                />
                              </div>
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </>

            <div className="col-span-full">
              <div className="divider"></div>
            </div>
            {/* التخصصات */}
            <>
              <div className="col-start-1 flex justify-start pr-4">
                <div className="head">التخصص</div>
              </div>
              {/* اكتب كلمة البحث للتخصص */}
              <div className="col-span-full p-4">
                <div className="form-control relative w-full">
                  <input
                    type="text"
                    className="input input-lg max-w-full input-search"
                    onChange={handleSpecializationSearch}
                    placeholder="ابحث عن تخصص"
                  />

                  <span className="absolute inset-y-0 right-4 inline-flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.4998 17.4998L14.1665 14.1665"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              {/* قائمة التخصصات */}
              <div className="col-span-full mt-4 p-4 mb-4 h-32 overflow-y-auto ">
                <div className="types-list ">
                  <ul className="list-none scrollable-list">
                    {specializationSearchResults.map((item) => {
                      return (
                        <>
                          {isLoading === true ? (
                            <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                          ) : (
                            <li className="flex justify-between pl-4 py-2">
                              <span className="list-text">{item.name}</span>
                              <div>
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-bordered-success"
                                  onChange={() =>
                                    handleChangeType(item.id, item.name)
                                  }
                                  checked={item.id === selectedItemId}
                                />
                              </div>
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </>
          </div>

          <div className="pt-4">
            <div className="grid grid-cols-2">
              <div className="col-span-full">
                <label htmlFor="modal-2200">
                  <div
                    className="add-btn w-full p-4"
                    onClick={() => handleSubmitFilter()}
                  >
                    تأكيد
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { setMainHeaderName } from "../../../../redux/Slices/MainHeaderSlice";
import "./Add_Course.scss";
import { useDispatch } from "react-redux";
import { Loading } from "../../../Loading/Loading";
import arrow from "../../../../Assets/Icons/arrow.jpeg";
import { useNavigate } from "react-router-dom";
export const AddCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [file, setFile] = useState<File | null>(null);
  const [imageUploadWrapClass, setImageUploadWrapClass] =
    useState("image-upload-wrap");
  const [fileUploadContentVisible, setFileUploadContentVisible] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [hasWhatsApp, setHasWhatsApp] = useState(false);
  const [showGovernoratePopUp, setShowGovernoratePopUp] = useState(false);
  const [showCityPopUp, setShowCityPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activityType, setActivityType] = useState(0);
  const [activityText, setActivityText] = useState("اختر تصنيف الكورس");
  const [farmSizeId, setFarmSizeId] = useState(0);
  const [farmSizeTxt, setFarmSizeText] = useState("اختر الاعتماد");

  const [addObject, setAddObject] = useState({
    name: "",
    mobile: "",
    email: "",
    whatsapp: "",
    address: "",
    description: "",
    type: 0,
    cityId: 0,
    activityTypeId: 0,
    approximateBreedingSize: 0,
    farmsCount: 0,
  });
  useEffect(() => {
    let mainHeaderName = "اضافة كورس";
    dispatch(setMainHeaderName({ mainHeaderName }));
  }, []);
  const removeUpload = () => {
    setFile(null);
    setImageUploadWrapClass("image-upload-wrap");
    setFileUploadContentVisible(false);
  };
  const readURL = (input: any) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageUploadWrapClass("image-upload-wrap image-dropping");
        setFileUploadContentVisible(true);
        setFile(input.files[0]);
      };

      reader.readAsDataURL(input.files[0]);
    } else {
      removeUpload();
    }
  };

  const handleDragOver = () => {
    setImageUploadWrapClass("image-upload-wrap image-dropping");
  };

  const handleDragLeave = () => {
    setImageUploadWrapClass("image-upload-wrap");
  };
  const handleChangeValues = (value: any, fieldName: string) => {
    // Update addObject with the new value
    setAddObject((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
  const handleChangeActivityType = (id: number) => {
    setActivityType(id);
    if (id === 1) {
      setActivityText("فردي");
    } else if (id === 2) {
      setActivityText("مؤسسي");
    } else {
      setActivityText("اختر");
    }
  };

  const handleChangeFarmSize = (id: number) => {
    setFarmSizeId(id);
    if (id === 1) {
      setFarmSizeText("اققل من 1000");
    } else if (id === 2) {
      setFarmSizeText("اققل من 5000");
    } else if (id === 3) {
      setFarmSizeText("اققل من 10000");
    } else if (id === 4) {
      setFarmSizeText("اققل من 25000");
    } else if (id === 5) {
      setFarmSizeText("اققل من 50000");
    } else if (id === 6) {
      setFarmSizeText("اققل من 100000");
    } else if (id === 7) {
      setFarmSizeText("اققل من 200000");
    } else if (id === 8) {
      setFarmSizeText("اققل من 500000");
    } else {
      setFarmSizeText("اختر");
    }
  };

  return (
    <div className="Add_Course">
      {isLoading === true ? <Loading /> : null}
      {/* تفاصيل الكورس */}
      <div className="main-info-section mt-4 grid grid-cols-2 gap-6">
        {/* Header */}
        <div className="col-start-1 pr-4 pt-4 flex items-center ">
          <div className="main-info-title w-1/2">تفاصيل الكورس</div>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>
        {/* Upload Image */}
        <>
          <div className="col-span-full flex gap-8 pl-4 pr-4 items-center">
            <div className={imageUploadWrapClass}>
              <input
                onDragOver={() => handleDragOver()}
                onDragLeave={() => handleDragLeave()}
                className="file-upload-input"
                type="file"
                onChange={(e) => readURL(e.target)}
                accept="image/*"
              />
              <div className="drag-text">
                <h3>ارفق صورة الكورس</h3>
                <p className="">دعم جميع الملفات، الحجم الأقصى 60 ميجابايت</p>
              </div>
            </div>
            <div onClick={() => removeUpload()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                  stroke="#EB001B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="col-span-full ">
            {fileUploadContentVisible && file && (
              <div className="row mb-3">
                <div className="file-upload-content">
                  <img
                    className="file-upload-image"
                    src={URL.createObjectURL(file)}
                    alt="your"
                  />
                </div>
              </div>
            )}
          </div>
        </>

        {/* العنوان */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">العنوان</p>
        </div>
        {/* التصنيف  */}
        <div className="col-start-2 pr-4 mt-4">
          <p className="title">التصنيف </p>
        </div>

        {/* العنوان input */}
        <div className="col-start-1 pr-4 ">
          <input
            className="title-content"
            type="text"
            placeholder=" اكتب عنوان المحاضرة"
            onChange={(e) => handleChangeValues(e.target.value, "name")}
            required
          />
        </div>

        {/* تصنيف الكورس input */}
        <div className="col-start-2 pr-4 pl-4">
          <div className="dropdown-container">
            <div className="dropdown w-full">
              <label tabIndex={0}>
                <div className="flex justify-between select-mission-container">
                  <input
                    className="select-mission-type pr-4"
                    id="ownership"
                    name="ownership"
                    value={activityText}
                    disabled
                  />

                  <div className="arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                </div>
              </label>
              <div className="dropdown-menu w-full bg-white">
                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeActivityType(1)}
                >
                  فردي
                </a>
                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeActivityType(2)}
                >
                  مؤسسي
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* وصف الكورس */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">وصف الكورس</p>
        </div>

        <div className="col-span-full pl-4 pr-4 ">
          <textarea
            placeholder="الوصف التفصيلي"
            className="mission-address-input"
            id="detailedDescription"
            name="detailedDescription"
            onChange={(e) => handleChangeValues(e.target.value, "description")}
          />
        </div>
      </div>
      {/* تفاصيل المدرب */}
      <div className="trainer-info-section mt-4 grid grid-cols-2 gap-6">
        {/* Header */}
        <div className="col-start-1 pr-4 pt-4 flex items-center ">
          <div className="main-info-title w-1/2">تفاصيل المدرب</div>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>
        {/* Upload Image */}
        <>
          <div className="col-span-full flex gap-8 pl-4 pr-4 items-center">
            <div className={imageUploadWrapClass}>
              <input
                onDragOver={() => handleDragOver()}
                onDragLeave={() => handleDragLeave()}
                className="file-upload-input"
                type="file"
                onChange={(e) => readURL(e.target)}
                accept="image/*"
              />
              <div className="drag-text">
                <h3>ارفق صورة المدرب</h3>
                <p className="">دعم جميع الملفات، الحجم الأقصى 60 ميجابايت</p>
              </div>
            </div>
            <div onClick={() => removeUpload()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                  stroke="#EB001B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="col-span-full ">
            {fileUploadContentVisible && file && (
              <div className="row mb-3">
                <div className="file-upload-content">
                  <img
                    className="file-upload-image"
                    src={URL.createObjectURL(file)}
                    alt="your"
                  />
                </div>
              </div>
            )}
          </div>
        </>

        {/* اسم المدرب */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">اسم المدرب</p>
        </div>
        {/* الاعتماد  */}
        <div className="col-start-2 pr-4 mt-4">
          <p className="title">الاعتماد </p>
        </div>

        {/* اسم المدرب input */}
        <div className="col-start-1 pr-4 ">
          <input
            className="title-content"
            type="text"
            placeholder=" اكتب اسم المدرب"
            onChange={(e) => handleChangeValues(e.target.value, "name")}
            required
          />
        </div>

        {/* الاعتماد input */}
        <div className="col-start-2 pr-4 pl-4">
          <div className="dropdown-container">
            <div className="dropdown w-full">
              <label tabIndex={0}>
                <div className="flex justify-between select-mission-container">
                  <input
                    className="select-mission-type pr-4"
                    id="ownership"
                    name="ownership"
                    value={farmSizeTxt}
                    disabled
                  />

                  <div className="arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                </div>
              </label>
              <div className="dropdown-menu w-full bg-white">
                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(1)}
                >
                  اققل من 1000
                </a>
                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(2)}
                >
                  اققل من5000
                </a>

                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(3)}
                >
                  اققل من 10000
                </a>

                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(4)}
                >
                  اققل من 25000
                </a>

                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(5)}
                >
                  اققل من 50000
                </a>

                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(6)}
                >
                  اققل من 100000
                </a>
                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(7)}
                >
                  اققل من 200000
                </a>

                <a
                  className="dropdown-item text-sm text-right"
                  onClick={() => handleChangeFarmSize(8)}
                >
                  اققل من 500000
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* الوصف */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">الوصف</p>
        </div>

        <div className="col-span-full pl-4 pr-4 ">
          <textarea
            placeholder="اكتب الوصف"
            className="mission-address-input"
            id="detailedDescription"
            name="detailedDescription"
            onChange={(e) => handleChangeValues(e.target.value, "description")}
          />
        </div>
      </div>

      <div className="add-actions p-5 ">
        <div className="grid grid-cols-2">
          <div className="col-start-1">
            <div className="flex gap-4">
              <button className="add-btn">اضافة</button>
              <div className="cancel-btn" onClick={() => navigate(-1)}>
                الغاء
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import "./CommingSoon.scss";
import loading from "../../Assets/Icons/loading 2 1 1.png";
export const CommingSoon = () => {
  return (
    <div className="CommingSoon flex justify-center mt-10">
      <div className="flex justify-center items-center">
        <div className="grid ">
          <img src={loading} alt="loading" />
          <h2 className="text text-center">انتظرونا قريبا</h2>
        </div>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../../../../Api";
import "../../../Farms/Farms.scss";
import { Loading } from "../../../Loading/Loading";
import chicken from "../../../../Assets/Icons/Chicken.jpg";
import { useParams } from "react-router-dom";

export const AccountFarms = () => {
  const { breederId } = useParams<{ breederId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [farms, setFarms] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [idAndNameArray, setIdAndNameArray] = useState<
    { id: string; name: string }[]
  >([]);
  const [governorateArray, setGovernorateArray] = useState<
    { governorateId: string; governorateName: string }[]
  >([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    API.get(`breeders/${breederId}/farms`).then((res) => {
      if (res) {
        setTotalRows(res.data.totalCount);
        const updatedFarmersData = res.data.items.map((farmer: any) => ({
          ...farmer,
          photo: farmer.photo !== null ? farmer.photo : { chicken },
        }));
        setFarms(updatedFarmersData);

        API.get(`work-areas`).then((response) => {
          const idAndNameData = response.data.map((item: any) => ({
            id: item.id,
            name: item.name,
          }));
          const governorateData = response.data.map((item: any) => ({
            governorateId: item.governorate.id,
            governorateName: item.governorate.name,
          }));
          const getDistinctValues = (
            arr: { governorateId: string; governorateName: string }[]
          ) => {
            const uniqueValues = arr.filter(
              (value, index, self) =>
                index ===
                self.findIndex((v) => v.governorateId === value.governorateId)
            );
            return uniqueValues;
          };
          const distinctGovernorateData = getDistinctValues(governorateData);

          setIdAndNameArray(idAndNameData);
          setGovernorateArray(distinctGovernorateData);
        });

        setIsLoading(false);
      }
    });
  }, [dispatch, pageNumber, pageSize, setFarms]);
  const renderFarmLocation = (city_id: string, gov_id: string) => {
    let govName = "";
    let cityName = "";
    if (
      governorateArray.length > 0 &&
      governorateArray != null &&
      idAndNameArray.length > 0 &&
      idAndNameArray != null
    ) {
      govName =
        governorateArray.find((c) => c.governorateId === gov_id)
          ?.governorateName ?? "";
      cityName = idAndNameArray.find((c) => c.id === city_id)?.name ?? "";
    }
    return `${govName},${cityName}`;
  };
  const handleImageError = (event: any) => {
    // Set a fallback image source when the original image fails to load
    event.target.src = chicken;
  };
  return (
    <div className="Farms pt-5 pr-5 pl-5">
      {/* Comming Soon */}
      {/* <CommingSoon /> */}
      <div className="grid grid-cols-3 gap-6 mt-5 pr-4 pl-4">
        {isLoading === true ? (
          <>
            <Loading />
          </>
        ) : null}
        {farms.map((item: any, index: any) => {
          return (
            <div className="big-section mb-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
              <div className="course-cover relative">
                {/* <div className='status flex absolute bottom-4 left-3  p-2'>
                                <span>معروض للبيع</span>
                            </div> */}

                <div className="three_dots flex absolute top-4 left-3  p-2">
                  <div className="popover" style={{ backgroundColor: "white" }}>
                    <svg
                      className="popover-trigger mx-2 arrow"
                      tabIndex={0}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z"
                        fill="#A7AEC1"
                      />
                      <path
                        d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z"
                        fill="#A7AEC1"
                      />
                      <path
                        d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                        fill="#A7AEC1"
                      />
                    </svg>
                    <div
                      className="popover-content popover-right-top right-auto"
                      tabIndex={0}
                    >
                      <ul>
                        <label
                          className="three-dots-li"
                          htmlFor="modal-1"
                          onClick={() => navigate(`/Farmers/${item.id}`)}
                        >
                          تفاصيل العنبر
                        </label>
                        <input
                          className="modal-state"
                          id="modal-1"
                          type="checkbox"
                        />
                        <label
                          className="three-dots-li"
                          htmlFor="modal-1"
                          onClick={() => navigate(`/Farmers/${item.id}/Edit`)}
                        >
                          تعديل العنبر
                        </label>
                        <input
                          className="modal-state"
                          id="modal-1"
                          type="checkbox"
                        />
                        <label
                          className="three-dots-li-delete"
                          htmlFor="modal-1"
                          // onClick={() => navigate(item.id)}
                        >
                          حذف العنبر
                        </label>
                        <input
                          className="modal-state"
                          id="modal-2"
                          type="checkbox"
                        />
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <img src={chicken} alt="course-cover" className='course-img' /> */}
                <img
                  src={item.photo}
                  alt="course-cover"
                  className="course-img"
                  onError={handleImageError}
                />
              </div>
              <p className="farm-name mb-2 mt-2">{item.name}</p>
              <div className="flex gap-2">
                {/* Location Icon */}
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="24"
                    viewBox="0 0 18 24"
                    fill="none"
                  >
                    <path
                      d="M9.0855 0.000488281C4.16625 0.000488281 0 4.19074 0 9.15349C0 14.351 4.82925 19.6662 8.0775 23.402C8.09025 23.417 8.61525 23.9997 9.26175 23.9997H9.31875C9.966 23.9997 10.4873 23.417 10.5 23.402C13.548 19.898 18 14.1192 18 9.15349C18 4.18999 14.7502 0.000488281 9.0855 0.000488281ZM9.38625 22.3955C9.36 22.4217 9.32175 22.451 9.288 22.4757C9.2535 22.4517 9.216 22.4217 9.18825 22.3955L8.796 21.944C5.7165 18.4115 1.49925 13.5732 1.49925 9.15274C1.49925 5.00374 4.97325 1.49899 9.08475 1.49899C14.2065 1.49899 16.4993 5.34274 16.4993 9.15274C16.4993 12.5082 14.106 16.9647 9.38625 22.3955ZM9.02625 4.54174C6.5415 4.54174 4.52625 6.55624 4.52625 9.04174C4.52625 11.5272 6.5415 13.5417 9.02625 13.5417C11.511 13.5417 13.5262 11.5265 13.5262 9.04174C13.5262 6.55699 11.5118 4.54174 9.02625 4.54174ZM9.02625 12.0417C7.37175 12.0417 5.99175 10.6632 5.99175 9.00874C5.99175 7.35424 7.33725 6.00874 8.99175 6.00874C10.647 6.00874 11.9918 7.35424 11.9918 9.00874C11.9925 10.6632 10.6815 12.0417 9.02625 12.0417Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <span className="farm-location">
                  {renderFarmLocation(item.city_id, item.gov_id)}
                </span>
              </div>
              <div className="flex gap-3 mt-4">{/* Chicken Type */}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

import "./ModuleHeader.scss";
import { Header } from "../Users/Header/Header";
import { WalletsHeader } from "../Wallets/Header/WalletsHeader";
import { CompetitionsHeader } from "../Competitions/Header/CompetitionsHeader";
import { FarmsHeader } from "../Farms/Header/FarmsHeader";
import { MissionsHeader } from "../Missions/MissionsHeader/MissionsHeader";
import { CoursesHeader } from "../Courses/Header/Header";
import { BreederHeader } from "../Breeders/Header/BreederHeader";
import { AcademyHeader } from "../Academy/Header/AcademyHeader";
import { useLocation } from "react-router-dom";
import { SupplierHeader } from "../Suppliers/Header/SupplierHeader";

export const ModuleHeader = () => {
  const location = useLocation();

  return (
    <div className="ModuleHeader">
      {/* ادارة المستخدمين */}
      {location.pathname.includes("Users") ? (
        <Header />
      ) : location.pathname.includes("Wallets") ? (
        <WalletsHeader />
      ) : location.pathname.includes("Competitions") ? (
        <>
          <CompetitionsHeader />
        </>
      ) : location.pathname.includes("AllFarms") ||
        location.pathname.includes("FarmsUnderRevision") ||
        location.pathname.includes("FarmsActive") ? (
        <>
          <FarmsHeader />
        </>
      ) : location.pathname.includes("Courses") ? (
        <CoursesHeader />
      ) : location.pathname.includes("Breeders") ? (
        <BreederHeader />
      ) : location.pathname.includes("DaginAcademy") ? (
        <AcademyHeader />
      ) : location.pathname.includes("Suppliers") ? (
        <SupplierHeader />
      ) : (
        <>
          {/* Missions Header */}
          <MissionsHeader />
        </>
      )}
    </div>
  );
};

import { useLocation } from "react-router-dom";
import { MainHeader } from "../../Components/MainHeader/MainHeader";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import { CourseDetails } from "../../Components/Academy/Academy_Courses/Details/Course_Details";
import { CourseDetailsHeader } from "../../Components/Academy/Academy_Courses/Details/Course_Details_Header";
import { TrainerDetails } from "../../Components/Academy/Academy_Courses/Details/Trainer_Details";
import { Lectures } from "../../Components/Academy/Academy_Courses/Details/Lectures";
import { LecturesDetails } from "../../Components/Academy/Academy_Courses/Details/Lectures_Details";

export const DetailsCoursePage = () => {
  const location = useLocation();
  return (
    <div className="flex flex-row " style={{ direction: "rtl" }}>
      <div className="sm:w-full sm:max-w-[18rem]">
        <input
          type="checkbox"
          id="sidebar-mobile-fixed"
          className="sidebar-state"
        />
        <label
          htmlFor="sidebar-mobile-fixed"
          className="sidebar-overlay"
        ></label>
        {/* Side bar*/}
        <Sidebar />
      </div>
      <div className="flex w-full flex-col bg-white">
        {/* Expand Button */}
        <div className="w-fit">
          <label
            htmlFor="sidebar-mobile-fixed"
            className="btn-primary btn sm:hidden"
          >
            Open Sidebar
          </label>
        </div>
        <div className="page-container" style={{ backgroundColor: "#F1F5F9" }}>
          <div className="col-span-12 row-span-1">
            <MainHeader />
            <CourseDetailsHeader />
          </div>
        </div>
        {/* Content Will Be Here */}

        <>
          <div className="h-full">
            <>
              {" "}
              {console.log(
                "🚀 ~ DetailsCoursePage ~ ocation.pathname:",
                location.pathname
              )}
            </>

            {location.pathname.includes("/trainer") ? (
              <TrainerDetails />
            ) : location.pathname.includes("/lectures") ? (
              <Lectures />
            ) : location.pathname.includes("lecture/Details") ? (
              <LecturesDetails />
            ) : (
              <CourseDetails />
            )}
            <>
              {console.log(
                "🚀 ~ DetailsCoursePage ~ location.pathname:",
                location.pathname
              )}
            </>
          </div>
        </>
      </div>
    </div>
  );
};

import "./EditHousingCycleMission.scss";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Loading } from "../../../../Loading/Loading";
import { toast } from "react-toastify";
import API, { URL } from "../../../../../Api";
import { Formik, FormikHelpers } from "formik";
import arrow from "../../../../../Assets/Icons/arrow.jpeg";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";

import { HenType } from "../../../../Farms/SubComponents/HenType";
import { BreedType } from "../../../../Farms/SubComponents/BreedType";
import { Companies } from "../../../../Farms/SubComponents/Companies";
type EditHousingCycleMissionProps = {
  farmId: number;
  housingId: number;
};
interface HousingResponse {
  badi_price: any;
  chick_price: any;
  company: {
    id: string;
    type: string;
  };
  creator: {
    id: string;
    name: string;
    mobile_number: string;
  };
  dajinBreed: {
    id: string;
    breed_type: string;
    dajin_id: string;
    dajinType: {
      id: string;
      image: string;
      type: string;
    };
  };
  expected_sale_date: string;
  farm_id: string;
  farmer_id: string;
  feedCompany: {
    id: string;
    name: string;
  };
  housing_date: string;
  id: string;
  nahi_price: any;
  nami_price: any;
  other_brooder_co: any;
  other_feed_co: any;
  suggested_traders: [];
  count: number;
}

export const EditHousingCycleMission = (
  props: EditHousingCycleMissionProps
) => {
  const [housingResponse, setHousingResponse] =
    useState<HousingResponse | null>(null);
  const stateFromFarmsModalSlice = useSelector(
    (state: any) => state.FarmsModal
  );
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);

  const stateFromMissionSlice = useSelector((state: any) => state.missions);

  const { MissionId } = useParams<{ MissionId: string }>();
  const [isLoading, setIsLoading] = useState(false);

  const [showTypePopUp, setShowTypePopUp] = useState(false);
  const [showBreedTypePopUp, setShowBreedTypePopUp] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [expectedDate, setExpectedDate] = useState(new Date());

  const [showDajinPopUp, setShowDajinPopUp] = useState(false);
  const [showCompanyPopUp, setShowCompanyPopUp] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    API.get(`farms/${props.farmId}/housing/${props.housingId}`).then((res) => {
      setHousingResponse(res.data);
      setStartDate(
        res.data.housing_date != null
          ? new Date(res.data.housing_date)
          : new Date()
      );
      setExpectedDate(
        res.data.expected_sale_date != null
          ? new Date(res.data.expected_sale_date)
          : new Date()
      );

      setIsLoading(false);
    });
  }, [stateFromFarmsModalSlice.farmId]);

  const handleChangeHousing = (
    value: string | number | boolean,
    field: string,
    setValues: FormikHelpers<any>["setValues"]
  ) => {
    // Update the Formik form state with the changed values

    setValues((prevValues: { housingResponse: HousingResponse }) => ({
      ...prevValues,
      housingResponse: {
        ...prevValues.housingResponse,
        [field]: value,
      },
    }));
    if (housingResponse) {
      // Create a new object with the updated field
      const updateHousingResponse: HousingResponse = {
        ...housingResponse,
        [field]: value, // Replace 'New Name' with the new value
      };

      // Update the state with the new object
      setHousingResponse(updateHousingResponse);
    }
  };

  const handleEditHousing = () => {
    setIsLoading(true);

    moment.locale("en");
    const data = {
      breedId:
        stateFromFarmsSlice.selectedBreedType === 0
          ? Number(housingResponse?.dajinBreed.id)
          : Number(stateFromFarmsSlice.selectedBreedType),
      companyId:
        stateFromFarmsSlice.selectedCompanyId === 0
          ? Number(housingResponse?.company.id)
          : Number(stateFromFarmsSlice.selectedCompanyId),
      count: Number(housingResponse?.count),
      housingDate: moment(startDate).format("YYYY-MM-DD"),
      expectedSaleDate: moment(expectedDate).format("YYYY-MM-DD"),
    };

    API.patch(`breed-housing/${housingResponse?.id}`, data)
      .then((res) => {
        setIsLoading(false);

        if (res.status === 200) {
          toast.success("تم تعديل دورة التسكين بنجاح");
          setIsLoading(false);
        } else {
          toast.error(
            "حدث خطأ ما في تعديل دورة التسكين يرجي التواصل مع المسؤولين"
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error:", error);
        toast.error(
          "حدث خطأ ما في تعديل دورة التسكين يرجي التواصل مع المسؤولين"
        );
        setIsLoading(false);
        toast.error(
          "حدث خطأ ما في تعديل دورة التسكين  يرجي التواصل مع المسؤولين"
        );
      });
  };

  const handleCompanyPopUp = () => {
    setShowCompanyPopUp(!showCompanyPopUp);
  };
  const handleShowDajinPopUp = () => setShowDajinPopUp(!showDajinPopUp);

  const handleBreedTypePopUp = () => {
    setShowBreedTypePopUp(!showBreedTypePopUp);
  };

  const handleChangeInputDate = (date: any) => {
    setStartDate(date);
  };

  const handleChangeExpectedDate = (date: any) => {
    setExpectedDate(date);
  };

  const dajinTypeText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromFarmsSlice.henTypeChanged === false
        ? housingResponse?.dajinBreed != null
          ? housingResponse?.dajinBreed.dajinType.type
          : "اختر نوع الداجن"
        : stateFromFarmsSlice.selectedHenType !== 0
        ? stateFromFarmsSlice.selectedHenTypeName
        : "اختر نوع الداجن"}
    </div>
  );

  const breederTypeText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromFarmsSlice.breederTypeChanged === false
        ? housingResponse?.dajinBreed != null
          ? housingResponse?.dajinBreed.breed_type
          : "اختر نوع السلالة"
        : stateFromFarmsSlice.selectedBreedType !== 0
        ? stateFromFarmsSlice.selectedBreedTypeName
        : "اختر نوع السلالة"}
    </div>
  );

  const housingCompanyText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromFarmsSlice.companyChanged === false
        ? housingResponse?.company != null
          ? housingResponse?.company.type
          : "اختر الشركة"
        : stateFromFarmsSlice.selectedCompanyId !== 0
        ? stateFromFarmsSlice.selectedCompanyText
        : "اختر الشركة"}
    </div>
  );

  return (
    <>
      <Formik
        onSubmit={() => handleEditHousing()}
        initialValues={{ farmResponse: housingResponse || null }} // Handle null case
        validationSchema={null}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
        }) => (
          <div className="add-section mb-4 EditHousingCycleMission">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                <div className="col-start-1 mt-4 pr-4">
                  <h2 className="flex justify-start mission-details">
                    تفاصيل دورة التسكين
                  </h2>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>
                {/* نوع الداجن */}
                <div className="col-start-1 pr-4">
                  <h3 className="mission-type"> نوع الداجن</h3>
                </div>
                {/* نوع السلالة */}
                <div className="col-start-2  pr-4">
                  <h3 className="mission-type"> نوع السلالة</h3>
                </div>
                {/* نوع الداجن */}
                <div className="col-start-1 pr-4">
                  <label htmlFor="modal-80">
                    <div
                      className="flex justify-between select-dajin-container"
                      onClick={() => handleShowDajinPopUp()}
                    >
                      {dajinTypeText}
                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
                {/* نوع السلالة input */}
                <div className="col-start-2 pr-4 pl-4">
                  <label htmlFor="modal-90">
                    <div
                      className="flex justify-between select-dajin-container"
                      onClick={() => handleBreedTypePopUp()}
                    >
                      {breederTypeText}
                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
                {/* العدد */}
                <div className="col-start-1 mt-4   pr-4">
                  <h3 className="mission-type"> العدد</h3>
                </div>
                {/* الشركة */}
                <div className="col-start-2 mt-4   pr-4">
                  <h3 className="mission-type"> الشركة</h3>
                </div>
                {/* العدد input */}
                <div className="col-start-1 pr-4 ">
                  <div>
                    <input
                      type="number"
                      placeholder="0000"
                      className="mission-address-input"
                      id="count"
                      name="count"
                      value={housingResponse?.count}
                      onChange={(e) =>
                        handleChangeHousing(e.target.value, "count", setValues)
                      }
                    />
                  </div>
                </div>

                {/* الشركة input */}
                <div className="col-start-2 pr-4 pl-4 ">
                  <label htmlFor="modal-15">
                    <div
                      className="flex justify-between select-dajin-container"
                      onClick={() => handleCompanyPopUp()}
                    >
                      {housingCompanyText}
                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/* تاريخ الأدخال */}
                <div className="col-start-1 pr-4">
                  <h3 className="mission-type"> تاريخ الأدخال</h3>
                </div>
                {/* تاريخ البيع المتوقع */}
                <div className="col-start-2 pr-4">
                  <h3 className="mission-type"> تاريخ البيع المتوقع</h3>
                </div>
                {/* تاريخ الادخال input */}
                <div className="col-start-1 pr-4">
                  <DatePicker
                    selected={startDate}
                    onChange={(event) => handleChangeInputDate(event)}
                    className="date-picker-input"
                    maxDate={new Date()}
                  />
                </div>
                {/* تاريخ البيع المتوقع input */}
                <div className="col-start-2 pr-4">
                  <DatePicker
                    selected={expectedDate}
                    onChange={(event) => handleChangeExpectedDate(event)}
                    className="date-picker-input"
                    minDate={new Date()}
                  />
                </div>
                {/* Actions */}
                <div className="col-start-1 pr-4">
                  <button className="add-btn" type="submit">
                    تعديل دورة التسكين
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
      {isLoading === true ? <Loading /> : null}
      {showDajinPopUp === true ? <HenType isEdit={false} /> : null}
      {showBreedTypePopUp === true ? (
        <BreedType
          isEdit={false}
          dajinId={Number(
            housingResponse?.dajinBreed === null
              ? "0"
              : housingResponse?.dajinBreed.dajin_id
          )}
        />
      ) : null}
      {showCompanyPopUp === true ? <Companies isEdit={false} /> : null}
    </>
  );
};

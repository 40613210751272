import { useEffect, useState } from "react";
import "./Breeders.scss";
import { Loading } from "../Loading/Loading";
import { useNavigate } from "react-router";
import moment from "moment";
import "moment/locale/ar"; // Import the Arabic locale
import { ar, enUS } from "date-fns/locale";
import { format } from "date-fns";
import API from "../../Api";
import { setMainHeaderName } from "../../redux/Slices/MainHeaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { TotalCount } from "../../SubComponents/TotalCount/TotalCount";
import { Link } from "react-router-dom";
import userIcon from "../../Assets/Icons/user.jpeg";

export const Breeders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [breeders, setBreeders] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);

  useEffect(() => {
    setIsLoading(true);
    let mainHeaderName = "المربيين";
    dispatch(setMainHeaderName({ mainHeaderName }));
    moment.locale("ar");
    if (stateFromFarmsSlice.breedersFilterData.length > 0) {
      setBreeders(stateFromFarmsSlice.breedersFilterData);
      setTotalRows(stateFromFarmsSlice.breedersFilterTotalCount);

      setIsLoading(false);
    } else {
      API.get(`breeders?page=${pageNumber}&limit=${pageSize}`).then((res) => {
        if (res) {
          setBreeders(res.data.items);
          setTotalRows(res.data.totalCount);
          setIsLoading(false);
        }
      });
    }
  }, [
    pageNumber,
    pageSize,
    setBreeders,
    stateFromFarmsSlice.breedersFilterData,
  ]);

  const hanldeChangePage = (targetPN: number) => {
    setIsLoading(true);
    API.get(`breeders?page=${pageNumber}&limit=${pageSize}`).then((res) => {
      if (res) {
        setBreeders(res.data.items);
        setTotalRows(res.data.totalCount);
        setPageNumber(targetPN);
        setIsLoading(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    });
  };

  const renderPagination = () => {
    const nPages = Math.ceil(totalRows / pageSize);
    const numbers = Array.from({ length: nPages }, (_, index) => index + 1);
    let paginationItem = numbers.map((n) => {
      return (
        <>
          <button
            className={pageNumber === n ? "btn btn-pagination-active" : "btn"}
            onClick={() => hanldeChangePage(n)}
          >
            {n}
          </button>
        </>
      );
    });
    return (
      <div
        className={
          totalRows > 5
            ? "pagination w-full max-w-xs overflow-auto"
            : "pagination"
        }
      >
        {paginationItem}
      </div>
    );
  };

  const sortData = (property: string) => {
    setIsLoading(true);
    let data = breeders;

    const sortedData = [...data].sort((a, b) => {
      if (a[property] < b[property]) return -1;
      if (a[property] > b[property]) return 1;
      return 0;
    });
    setBreeders(sortedData);
    setIsLoading(false);
  };

  return (
    <div className="Breeders grid grid-cols-2">
      <div className="mt-4">
        {/* Total Count */}
        <TotalCount count={totalRows} title="المربيين" />
      </div>

      {/* <div className="col-start-2 pl-40 mt-4">
        <div className="flex items-center justify-end gap-1">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M16.0686 15H7.9313C7.32548 15 7.02257 15 6.88231 15.1198C6.76061 15.2238 6.69602 15.3797 6.70858 15.5393C6.72305 15.7232 6.93724 15.9374 7.36561 16.3657L11.4342 20.4344C11.6323 20.6324 11.7313 20.7314 11.8454 20.7685C11.9458 20.8011 12.054 20.8011 12.1544 20.7685C12.2686 20.7314 12.3676 20.6324 12.5656 20.4344L16.6342 16.3657C17.0626 15.9374 17.2768 15.7232 17.2913 15.5393C17.3038 15.3797 17.2392 15.2238 17.1175 15.1198C16.9773 15 16.6744 15 16.0686 15Z"
                stroke="#949494"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
              <path
                d="M7.9313 9.00005H16.0686C16.6744 9.00005 16.9773 9.00005 17.1175 8.88025C17.2393 8.7763 17.3038 8.62038 17.2913 8.46082C17.2768 8.27693 17.0626 8.06274 16.6342 7.63436L12.5656 3.56573C12.3676 3.36772 12.2686 3.26872 12.1544 3.23163C12.054 3.199 11.9458 3.199 11.8454 3.23163C11.7313 3.26872 11.6323 3.36772 11.4342 3.56573L7.36561 7.63436C6.93724 8.06273 6.72305 8.27693 6.70858 8.46082C6.69602 8.62038 6.76061 8.7763 6.88231 8.88025C7.02257 9.00005 7.32548 9.00005 7.9313 9.00005Z"
                stroke="#949494"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
            </g>
          </svg>
          <div className="dropdown-container ">
            <div className="dropdown">
              <label tabIndex={0}>
                <span className="sorting">الترتيب حسب</span>
              </label>
              <div className="dropdown-menu   bg-white">
                <a
                  className="cursor-pointer border-2 p-1 mt-2 w-full text-sm text-right sorting-item"
                  onClick={() => sortData("approx_breeding_size")}
                >
                  حجم التربية التقريبي
                </a>

                <a
                  className="cursor-pointer border-2 p-1 mt-2 w-full text-sm text-right sorting-item"
                  onClick={() => sortData("farms_count")}
                >
                  اجمالي عنابر التربية
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}

      {breeders.map((item: any) => {
        const createdAtDate = moment(item.created_at);
        moment.locale("ar");
        const createdAtDate_Arabic = createdAtDate.format("DD MMM YYYY");
        return (
          <>
            <div className="grid grid-cols-2 col-span-full mb-4 mr-4 ml-4 mt-4  mission-content">
              <div className="col-start-1">
                <span
                  className="title cursor-pointer"
                  onClick={() => navigate(item.id)}
                >
                  {item.name}
                </span>
              </div>
              <div className="col-start-2 pl-4 flex justify-end">
                <span className="title">{item.id}</span>
              </div>

              <div className="col-start-1 flex gap-4 justify-start mt-1">
                <div className="flex gap-2">
                  <span className="title">المحافظة :</span>
                  <span className="mission-type">
                    {item.governorate != null ? item.governorate.name : ""}
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="title">المدينة :</span>
                  <span className="mission-type">
                    {item.city != null ? item.city.name : ""}
                  </span>
                </div>
                <div className="flex gap-2">
                  <span className="title">القرية :</span>
                  <span className="mission-type">{item.village}</span>
                </div>
              </div>
              <div className="col-start-2 flex justify-end">
                {item.creator != null && item.creator !== undefined ? (
                  <>
                    <div className="flex gap-4 assigned-to">
                      <div>
                        <img src={userIcon} alt="user" />
                      </div>
                      <Link to={`/Users/Profile/${item.creator.id}`}>
                        <a className="flex gap-1 cursor-pointer">
                          <span className="assigned-to-txt">انشأ بواسطة :</span>
                          <span className="assinged-user-name">
                            {item.creator.name}
                          </span>
                        </a>
                      </Link>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="col-start-1 flex gap-4 justify-start mt-1">
                <div className="flex gap-2">
                  <span className="title">رقم الهاتف :</span>
                  <span className="mission-type">{item.mobile_number}</span>
                </div>
                <div className="flex gap-2">
                  <span className="title">رقم الواتساب :</span>
                  <span className="mission-type">{item.whatsapp_number}</span>
                </div>
              </div>
              <div className="col-start-1 felx gap-4 justify-start mt-1">
                <div className="flex gap-2">
                  <span className="title">حجم التربية التقريبي :</span>
                  <span className="mission-type">
                    {item.approx_breeding_size}
                  </span>
                </div>
              </div>

              <div className="col-start-1 felx gap-4 justify-start mt-1">
                <div className="flex gap-2">
                  <span className="title">اجمالي عنابر التربية :</span>
                  <span className="mission-type">{item.farms_count}</span>
                </div>
              </div>

              <div className="col-start-1 flex gap-4 justify-start mt-1">
                <div className="flex gap-2 items-center">
                  <span className="title">الحالة :</span>
                  <span
                    className={
                      item.account_status === 2
                        ? "breeder-active"
                        : "breeder-disable"
                    }
                  >
                    {item.account_status === 2 ? "مفعل" : "غير مفعل"}
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      })}

      {/* Pagination */}
      {stateFromFarmsSlice.breedersFilterData.length > 0 ? null : (
        <div className="flex justify-end col-start-2 pl-8">
          {renderPagination()}
        </div>
      )}
    </div>
  );
};

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import "../../Components/Missions/MissionsHeader/MissionsHeader.scss";
import excel from "../../Assets/Icons/excel2-svgrepo-com.svg";
import moment from "moment";

import API from "../../Api";
type ExportExcelProps = {
  url: string;
  fileName: string;
  page: string;
};
export const ExportExcel = (props: ExportExcelProps) => {
  const fileType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8`;
  const fileExtension = `.xlsx`;
  const exportToExcel = async () => {
    let filtered: any[] = [];
    API.get(props.url).then((res: any) => {
      if (res.data.items !== undefined && res.data.items.length > 0) {
        switch (props.page) {
          case "AllMissions":
            filtered = res.map(
              ({
                farm_id,
                farm_name,
                farm_status,
                f_area,
                farming_type,
                farm_capacity,
                f_photo,
                f_gov,
                f_city,
                f_village,
                f_f_address,
                f_lat,
                f_lng,
                f_created_at,
                breeder_id,
                breeder_name,
                breeder_phone,
                breeder_created_at,
                breeder_address,
                breeder_village,
                breeder_city,
                breeder_gov,
                created_by_name,
                created_by_phone,
                mission_id,
                m_f_addr,
                m_name,
                m_audience,
                m_status,
                m_created_at,
                m_due_at,
                m_type,
                housing_id,
                housing_created_at,
                housing_date,
                expected_sale_date,
                housing_count,
                current_feed_type,
                housing_dajin_company,
                housing_dajin,
                h_dajin_breed,
                badi_price,
                nami_price,
                nahi_price,
                h_feed_co,
              }: any) => ({
                farm_id,
                farm_name,
                farm_status,
                f_area,
                farming_type,
                farm_capacity,
                f_photo,
                f_gov,
                f_city,
                f_village,
                f_f_address,
                f_lat,
                f_lng,
                f_created_at,
                breeder_id,
                breeder_name,
                breeder_phone,
                breeder_created_at,
                breeder_address,
                breeder_village,
                breeder_city,
                breeder_gov,
                created_by_name,
                created_by_phone,
                mission_id,
                m_f_addr,
                m_name,
                m_audience,
                m_status,
                m_created_at,
                m_due_at,
                m_type,
                housing_id,
                housing_created_at,
                housing_date,
                expected_sale_date,
                housing_count,
                current_feed_type,
                housing_dajin_company,
                housing_dajin,
                h_dajin_breed,
                badi_price,
                nami_price,
                nahi_price,
                h_feed_co,
              })
            );
            break;

          case "Pending":
            filtered = res.data.items.map(
              ({
                id,
                created_at,
                name,
                reward,
                due_at,
                early_bonus,
                early_bonus_due_at,
                breeder,

                type,
                status,
              }: any) => ({
                id,
                created_at: moment(created_at).format("YYYY-MM-DD"),
                name,
                price: reward,
                finished_at: moment(due_at).format("YYYY-MM-DD"),
                early_bonus,
                early_bonus_due_at:
                  moment(early_bonus_due_at).format("YYYY-MM-DD"),
                breeder: breeder?.name,
                breeder_Mobile: breeder?.mobile_number,
                type: type?.name,
                status:
                  status === "in_progress"
                    ? "تحت التنفيذ"
                    : status === "pending"
                    ? "لم تستند بعد"
                    : status === "revision"
                    ? "مهمة تامة تنتظر التقييم"
                    : status === "late"
                    ? "مهمة متأخرة"
                    : status === "completed"
                    ? "مهمة تامة"
                    : "مهمة مرفوضة",
              })
            );
            break;

          case "in_progress":
            filtered = res.data.items.map(
              ({
                id,
                created_at,
                name,
                reward,
                due_at,
                early_bonus,
                early_bonus_due_at,
                breeder,

                type,
                status,
              }: any) => ({
                id,
                created_at: moment(created_at).format("YYYY-MM-DD"),
                name,
                price: reward,
                finished_at: moment(due_at).format("YYYY-MM-DD"),
                early_bonus,
                early_bonus_due_at:
                  moment(early_bonus_due_at).format("YYYY-MM-DD"),
                breeder: breeder?.name,
                breeder_Mobile: breeder?.mobile_number,
                type: type?.name,
                status: "تحت التنفيذ",
              })
            );
            break;

          case "revision":
            filtered = res.data.items.map(
              ({
                id,
                created_at,
                name,
                reward,
                due_at,
                early_bonus,
                early_bonus_due_at,
                breeder,

                type,
                status,
              }: any) => ({
                id,
                created_at: moment(created_at).format("YYYY-MM-DD"),
                name,
                price: reward,
                finished_at: moment(due_at).format("YYYY-MM-DD"),
                early_bonus,
                early_bonus_due_at:
                  moment(early_bonus_due_at).format("YYYY-MM-DD"),
                breeder: breeder?.name,
                breeder_Mobile: breeder?.mobile_number,
                type: type?.name,
                status: "بأنتظار التأكيد",
              })
            );
            break;

          case "completed":
            filtered = res.data.items.map(
              ({
                id,
                created_at,
                name,
                reward,
                due_at,
                early_bonus,
                early_bonus_due_at,
                breeder,

                type,
                status,
              }: any) => ({
                id,
                created_at: moment(created_at).format("YYYY-MM-DD"),
                name,
                price: reward,
                finished_at: moment(due_at).format("YYYY-MM-DD"),
                early_bonus,
                early_bonus_due_at:
                  moment(early_bonus_due_at).format("YYYY-MM-DD"),
                breeder: breeder?.name,
                breeder_Mobile: breeder?.mobile_number,
                type: type?.name,
                status: "تامة",
              })
            );
            break;
          case "late":
            filtered = res.data.items.map(
              ({
                id,
                created_at,
                name,
                reward,
                due_at,
                early_bonus,
                early_bonus_due_at,
                breeder,

                type,
                status,
              }: any) => ({
                id,
                created_at: moment(created_at).format("YYYY-MM-DD"),
                name,
                price: reward,
                finished_at: moment(due_at).format("YYYY-MM-DD"),
                early_bonus,
                early_bonus_due_at:
                  moment(early_bonus_due_at).format("YYYY-MM-DD"),
                breeder: breeder?.name,
                breeder_Mobile: breeder?.mobile_number,
                type: type?.name,
                status: "متأخرة",
              })
            );
            break;

          case "rejected":
            filtered = res.data.items.map(
              ({
                id,
                created_at,
                name,
                reward,
                due_at,
                early_bonus,
                early_bonus_due_at,
                breeder,

                type,
                status,
              }: any) => ({
                id,
                created_at: moment(created_at).format("YYYY-MM-DD"),
                name,
                price: reward,
                finished_at: moment(due_at).format("YYYY-MM-DD"),
                early_bonus,
                early_bonus_due_at:
                  moment(early_bonus_due_at).format("YYYY-MM-DD"),
                breeder: breeder?.name,
                breeder_Mobile: breeder?.mobile_number,
                type: type?.name,
                status: "مرفوضة",
              })
            );
            break;

          default:
            break;
        }
        const ws = XLSX.utils.json_to_sheet(filtered);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, props.fileName + fileExtension);
      } else if (res.data !== undefined && res.data.length > 0) {
        const ws = XLSX.utils.json_to_sheet(res.data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, props.fileName + fileExtension);
      }
    });
  };
  return (
    <div className="MissionsHeader flex justify-end pl-8">
      <div className="filter-section flex" onClick={() => exportToExcel()}>
        <img
          src={excel}
          alt="excelIcon"
          style={{
            height: "25px",
            width: "25px",
          }}
          loading="lazy"
        />
        تصدير
      </div>
    </div>
  );
};

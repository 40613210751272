import { useEffect, useState } from "react";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import "./Housing.scss";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { HenType } from "../SubComponents/HenType";
import { BreedType } from "../SubComponents/BreedType";
import { useNavigate } from "react-router-dom";
import arrow from "../../../Assets/Icons/arrow.jpeg";
import { Companies } from "../SubComponents/Companies";
import { DatePickerComponent } from "../../Missions/AddMission/SubComponents/DatePicker/DatePickerComponent";
import { CommingSoon } from "../../CommingSoon/CommingSoon";
import { Loading } from "../../Loading/Loading";
import { useParams } from "react-router-dom";
import API from "../../../Api";

const housingData = {
  farmId: 0,
  breedId: 0,
  companyId: 0,
  chick_price: 1,
  count: 0,
  housingDate: "2024-05-10",
  expectedSaleDate: "2024-05-10",
};
export const Housing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);

  const { farmId } = useParams<{ farmId: string }>();
  const { breederId } = useParams<{ breederId: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [showTypePopUp, setShowTypePopUp] = useState(false);
  const [showBreedTypePopUp, setShowBreedTypePopUp] = useState(false);
  const [showCompanyPopUp, setShowCompanyPopUp] = useState(false);
  const handleShowTypePopUp = () => {
    setShowTypePopUp(!showTypePopUp);
  };

  const handleBreedTypePopUp = () => {
    setShowBreedTypePopUp(!showBreedTypePopUp);
  };

  const handleCompanyPopUp = () => {
    setShowCompanyPopUp(!showCompanyPopUp);
  };
  useEffect(() => {
    let mainHeaderName = "تسكين عنبر";
    dispatch(setMainHeaderName({ mainHeaderName }));
  }, []);

  const handleChangeInput = (e: any, field: string) => {
    if (field === "count") {
      housingData.count = Number(e);
    }
  };
  const handleSubmitHousing = () => {
    setIsLoading(true);
    if (housingData.count === 0) {
      toast.error("من فضلك قم بأدخال العدد ");
      setIsLoading(false);
    } else if (stateFromFarmsSlice.selectedHenType === 0) {
      toast.error("من فضلك قم بأختيار نوع الداجن");
      setIsLoading(false);
    } else if (stateFromFarmsSlice.selectedBreedType === 0) {
      toast.error("من فضلك قم بأختيار نوع السلالة");
      setIsLoading(false);
    } else if (stateFromFarmsSlice.selectedCompanyId === 0) {
      toast.error("من فضلك قم بأختيار الشركة");
      setIsLoading(false);
    } else {
      confirmCreateHousing();
    }
  };
  const confirmCreateHousing = () => {
    setIsLoading(true);

    housingData.breedId = Number(breederId?.substring(1)) ?? 0;
    housingData.farmId = Number(farmId?.substring(1)) ?? 0;
    housingData.companyId = Number(stateFromFarmsSlice.selectedCompanyId);

    API.post("breed-housing", housingData)
      .then((res) => {
        if (res.status === 400) {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        } else {
          toast.success("تم تسكين العنبر");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
        setIsLoading(false);
      });
  };
  return (
    <div className="Housing">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}

      {showTypePopUp === true ? <HenType isEdit={false} /> : null}
      {showBreedTypePopUp === true ? <BreedType isEdit={false} /> : null}
      {showCompanyPopUp === true ? <Companies isEdit={false} /> : null}
      <div className="farm-info-section mt-4 grid grid-cols-2 gap-4">
        {/* Header */}
        <div className="col-start-1 pr-4 pt-4 flex items-center ">
          <div className="farm-info-title w-1/2">تسكين العنبر</div>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>
        {/* نوع الداجن  */}
        <div className="col-start-1 pr-4  mt-4">
          <p className="title">نوع الداجن</p>
        </div>

        {/* نوع السلالة */}
        <div className="col-start-2 pr-4 mt-4">
          <p className="title">نوع السلالة</p>
        </div>

        {/* :input نوع الداجن  */}
        <div className="col-start-1 pr-4 ">
          <label htmlFor="modal-80">
            <div className="flex justify-between select-mission-container">
              <input
                className="select-mission-type pr-4"
                value={
                  stateFromFarmsSlice.selectedHenType !== 0
                    ? stateFromFarmsSlice.selectedHenTypeName
                    : "اختر نوع الداجن"
                }
                disabled
                id="birdTypeId"
                name="birdTypeId"
              />

              <div className="arrow" onClick={() => handleShowTypePopUp()}>
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          </label>
        </div>

        {/* نوع السلالة  input   */}
        <div className="col-start-2 pr-4  ">
          <label htmlFor="modal-90">
            <div className="flex justify-between select-mission-container">
              <input
                className="select-mission-type pr-4"
                value={
                  stateFromFarmsSlice.selectedBreedType === 0
                    ? "اختر نوع السلالة"
                    : stateFromFarmsSlice.selectedBreedTypeName
                }
                disabled
                id="breedId"
                name="breedId"
              />
              <div className="arrow" onClick={() => handleBreedTypePopUp()}>
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          </label>
        </div>
        {/* العدد   */}
        <div className="col-start-1 pr-4  mt-4">
          <p className="title">العدد </p>
        </div>

        {/* الشركة  */}
        <div className="col-start-2 pr-4 mt-4">
          <p className="title">الشركة</p>
        </div>
        {/* العدد input */}
        <div className="col-start-1 pr-4 ">
          <div>
            <input
              type="number"
              placeholder="0000"
              className="mission-address-input"
              id="count"
              name="count"
              onChange={(e) => handleChangeInput(e.target.value, "count")}
            />
          </div>
        </div>
        {/* الشركة input */}
        <div className="col-start-2 pr-4  ">
          <label htmlFor="modal-15">
            <div className="flex justify-between select-mission-container">
              <input
                className="select-mission-type pr-4"
                value={
                  stateFromFarmsSlice.selectedCompanyId === 0
                    ? "اختر الشركة"
                    : stateFromFarmsSlice.selectedCompanyText
                }
                disabled
                id="breedId"
                name="breedId"
              />
              <div className="arrow" onClick={() => handleCompanyPopUp()}>
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          </label>
        </div>

        {/* تاريخ الادخال */}
        <div className="col-start-1 pr-4 mt-4">
          <p className="title">تاريخ الادخال </p>
        </div>
        {/* تاريخ البيع المتوقع* */}
        <div className="col-start-2 pr-4 mt-4">
          <p className="title">تاريخ البيع المتوقع</p>
        </div>

        <div className="col-start-1 pr-4 mt-4">
          <DatePickerComponent activation={false} isEdit={false} />
        </div>

        <div className="col-start-2 pr-4 mt-4">
          <DatePickerComponent activation={false} isEdit={false} />
        </div>
      </div>

      <div className="add-actions p-5">
        <div className="grid grid-cols-2">
          <div className="col-start-1">
            <div className="flex gap-4">
              <button className="add-btn" onClick={() => handleSubmitHousing()}>
                تسكين
              </button>
              <div className="cancel-btn" onClick={() => navigate(-1)}>
                الغاء
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import "./Course_Details.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../../../Loading/Loading";
import { setMainHeaderName } from "../../../../redux/Slices/MainHeaderSlice";
import trainer from "../../../../Assets/Icons/Chicken.jpg";
interface ApiResponse {
  id: string;
  user_id: string;
  name: string;
  area: number;
  license_number: number;
  photo: string;
  gov_id: string;
  city_id: string;
  village: string;
  full_address: string;
  rented: number;
  latitude: number;
  longitude: number;
  dajin_breed_id: string;
  created_at: string;
  updated_at: string;
  user: {
    id: string;
    name: string;
    mobile_number: string;
  };
  dajinBreed: string;
}
export const LecturesDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const { courseId } = useParams<{ courseId: string }>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading(true);
    let mainHeaderName = "تفاصيل الكورس";
    dispatch(setMainHeaderName({ mainHeaderName }));

    setIsLoading(false);
  }, []);
  const handleImageError = (event: any) => {
    // Set a fallback image source when the original image fails to load
    event.target.src = trainer;
  };
  return (
    <div className="Course_Details">
      {isLoading === true ? <Loading /> : null}
      <div className="grid grid-cols-2 content mr-4 ml-4">
        {/* Farm Details */}
        <div className="col-start-1">
          <p className="farm-detials">تفاصيل المحاضرة</p>
        </div>
        {/* Close & Edit & Delete Icon */}
        <div className="col-start-2 flex items-center gap-4 justify-end pl-4">
          {/* Edit */}
          <svg
            onClick={() => navigate(`/DaginAcademy/Courses/Edit/:1`)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M22 12V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2H12M15.6864 4.02275C15.6864 4.02275 15.6864 5.45305 17.1167 6.88334C18.547 8.31364 19.9773 8.31364 19.9773 8.31364M9.15467 15.9896L12.1583 15.5605C12.5916 15.4986 12.9931 15.2978 13.3025 14.9884L21.4076 6.88334C22.1975 6.09341 22.1975 4.81268 21.4076 4.02275L19.9773 2.59245C19.1873 1.80252 17.9066 1.80252 17.1167 2.59245L9.01164 10.6975C8.70217 11.0069 8.50142 11.4084 8.43952 11.8417L8.01044 14.8453C7.91508 15.5128 8.4872 16.0849 9.15467 15.9896Z"
              stroke="#28303F"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          {/* Delete  */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
              stroke="#EB001B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          {/* Close */}
          <svg
            onClick={() => navigate(-1)}
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 13 12"
            fill="none"
          >
            <path
              d="M11.4473 1.21143L1.54777 11.1109"
              stroke="#64748B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.54688 1.21143L11.4464 11.1109"
              stroke="#64748B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        {/* Image and content */}
        <div className="col-span-full flex justify-between mt-4">
          <div className="flex gap-4">
            <div>
              <img
                src={trainer}
                className="lecture-img"
                alt="lecture-img"
                onError={handleImageError}
              />
            </div>
            <div className="farm-info">
              <p className="farm-name w-full mb-4">كورسات أمراض الدواجن</p>
              <div className="flex mb-4">
                <span className="title">اسم الكورس :</span>
                <span className="title-content">
                  أساسيات تشخيص أمراض الدواجن
                </span>
              </div>
              <div className="flex mb-4">
                <span className="title">تاريخ النشر :</span>
                <span className="title-content">24/01/2024</span>
              </div>

              <div className="flex mb-4">
                <span className="title">المشاهدات :</span>
                <span className="title-content">11 مشاهدة</span>
              </div>

              <div className="flex mb-4">
                <span className="title">مدة المحاضرة :</span>
                <span className="title-content">50 دقيقة</span>
              </div>

              <div className="flex mb-4">
                <span className="title">رابط المحاضرة :</span>
                <span className="title-content">
                  https://www.linkedin.com/in/mahmoud-ashraf-elnabwy-4358191a5/
                </span>
              </div>
            </div>
          </div>
          {/* <div className='status'>
                      مغلق
                  </div> */}
        </div>
      </div>
    </div>
  );
};

import "./BreedersModal.scss";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Fuse from "fuse.js";
import closeIcon from "../../Assets/Icons/closeicon.svg";
import API from "../../Api";
import { toast } from "react-toastify";
import { Loading } from "../../Components/Loading/Loading";
import {
  setSelectedBreeders,
  setSelectedOneBreeders,
} from "../../redux/Slices/BreedersModalSlice";
type BreedersModalProps = {
  MultiSelect: boolean;
};
export const BreedersModal = (props: BreedersModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchResults, setSearchResults] = useState<any[]>([{}]);
  const [activeType, setActiveType] = useState(1);
  const [selectedBreeders, stSelectedBreeders] = useState<number[]>([]);
  const [selectedNameSales, setSelectedNameBreeders] = useState<string[]>([]);
  const [selectedNameSpecialties, setSelectedNameSpecialties] = useState<
    string[]
  >([]);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [selectedItemName, setSelectedItemName] = useState("");

  const [selectedspecialties, setSelectedspecialties] = useState<number[]>([]);
  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["name"],
  };
  const fuse = new Fuse(searchResults, options);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    if (activeType === 1) {
    } else {
    }
    API.get("breeders?page=1&limit=500000000").then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          if (activeType === 1) {
            setSearchResults(res.data.items);
            setData(res.data.items);
          } else {
            setSearchResults(res.data);
            setData(res.data);
          }
          setIsLoading(false);
        }
      }
    });
  }, [setSearchResults, setActiveType, activeType]);
  const handleSearch = (event: any) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setSearchResults(data);
      return;
    }

    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setSearchResults(items);
  };
  const handleChangeType = (itemId: number, itemName: string) => {
    setSelectedItemId(itemId === selectedItemId ? 0 : itemId);
    setSelectedItemName(itemId === selectedItemId ? "null" : itemName);
  };

  const submitChoise = () => {
    let breederId = selectedItemId;
    let breederName = selectedItemName;
    let isChanged = breederId === 0 ? false : true;
    let breederCity = "";
    let breederGovernorateCity = "";
    let cityId = 0;
    let governorateId = 0;
    API.get(`breeders/${breederId}`).then((res) => {
      if (res.status === 200) {
        breederCity = res.data.city != null ? res.data.city.name : "";
        breederGovernorateCity =
          res.data.governorate != null ? res.data.governorate.name : "";
        cityId = Number(res.data.city_id);
        governorateId =
          res.data.governorate != null ? Number(res.data.governorate.id) : 0;
        dispatch(
          setSelectedOneBreeders({
            breederId,
            breederName,
            isChanged,
            breederCity,
            breederGovernorateCity,
            cityId,
            governorateId,
          })
        );
      }
    });
  };

  const handleSelectItem = (id: number, name: string) => {
    const isSelectedBefore = selectedBreeders.includes(id);
    const isNameSelectedBefore = selectedNameSales.includes(name);
    if (isSelectedBefore || isNameSelectedBefore) {
      const indexToRemove = selectedBreeders.indexOf(id);
      const nameToRemoveIndex = selectedNameSales.indexOf(name);
      // Remove the ID if it exists in the array
      if (indexToRemove !== -1 && nameToRemoveIndex !== -1) {
        selectedBreeders.splice(indexToRemove, 1);
        selectedNameSales.splice(nameToRemoveIndex, 1);
        stSelectedBreeders(selectedBreeders);
        setSelectedNameBreeders(selectedNameSales);
      }
    } else {
      selectedBreeders.push(id);
      selectedNameSales.push(name);
      stSelectedBreeders(selectedBreeders);
      setSelectedNameBreeders(selectedNameSales);
    }
  };
  const handleSubmitChoise = () => {
    let breedersIds = activeType === 1 ? selectedBreeders : selectedspecialties;

    let breedersText = "";
    let isChanged = false;
    //Get First object from array
    if (selectedNameSales.length > 0 && activeType === 1) {
      let breederName = selectedNameSales[0];
      let count = selectedNameSales.length - 1;
      if (count === 0) {
        breedersText = `${breederName} `;
      } else {
        breedersText = `${breederName} و ${count} أخرون `;
      }
    } else {
      let cityName = selectedNameSpecialties[0];
      let count = selectedNameSpecialties.length - 1;
      let text = `${cityName} و ${count} أخرون`;
      breedersText = text;
    }
    isChanged = breedersIds.length > 0 ? true : false;
    dispatch(setSelectedBreeders({ breedersIds, breedersText, isChanged }));
  };
  return (
    <div className="BreedersModal">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}

      <input className="modal-state" id="modal-1100" type="checkbox" />
      <div className="modal w-full">
        <label className="modal-overlay" htmlFor="modal-1100"></label>
        <div
          className="modal-content flex flex-col gap-5"
          style={{
            backgroundColor: "white",
            width: "2500px",
          }}
        >
          <div className="grid grid-cols-2">
            <div className="col-start-1">
              <h2 className="flex justify-start mission-type-txt">
                تعديل او اضافة مربي
              </h2>
            </div>
            <div className="col-start-2 flex justify-end">
              <div className="flex gap-4">
                <label htmlFor="modal-1100">
                  <img src={closeIcon} alt="close" />
                </label>
              </div>
            </div>

            <div className="col-start-1 mt-4 mb-4 pr-4">
              <div className="flex justify-start select-mission-type">
                اضف المربي
              </div>
            </div>
            <div className="col-span-full mb-4 pr-4 pl-4">
              <div className="form-control relative w-full">
                <input
                  type="text"
                  className="input input-lg max-w-full input-search"
                  placeholder="ابحث"
                  onChange={handleSearch}
                />

                <span className="absolute inset-y-0 right-4 inline-flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                      stroke="#64748B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.4993 17.5L14.166 14.1667"
                      stroke="#64748B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div className="col-span-full mb-4 pr-4 pl-4 h-40 overflow-y-auto">
              <div className="types-list">
                <ul className="list-none scrollable-list">
                  {searchResults.map((item) => {
                    return (
                      <li className="flex justify-between pl-4 py-2">
                        <span className="list-text">{item.name}</span>
                        <div>
                          {props.MultiSelect === true ? (
                            <>
                              <input
                                id={`checkbox ${item.id}`}
                                type="checkbox"
                                className="checkbox checkbox-bordered-success"
                                onChange={(e) =>
                                  handleSelectItem(item.id, item.name)
                                }
                              />
                            </>
                          ) : (
                            <>
                              <input
                                type="checkbox"
                                className="checkbox checkbox-bordered-success"
                                onChange={() =>
                                  handleChangeType(item.id, item.name)
                                }
                                checked={item.id === selectedItemId}
                              />
                            </>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-span-full mb-4">
              <div className="divider"></div>
            </div>
            <div className="col-span-full">
              <div className="flex actions gap-4">
                <label htmlFor="modal-1100">
                  <div
                    className="done"
                    onClick={() =>
                      props.MultiSelect === true
                        ? handleSubmitChoise()
                        : submitChoise()
                    }
                  >
                    تم
                  </div>
                </label>
                <label htmlFor="modal-1100">
                  <div className="cancel">الغاء</div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useNavigate, useLocation } from "react-router";
import userIcon from "../../../Assets/Icons/40px.svg";
import "./BreedersDetails.scss";
import { useEffect, useState } from "react";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import API from "../../../Api";
import moment from "moment";
import "moment/locale/ar"; // Import the Arabic locale
import { ar, enUS } from "date-fns/locale";
import { format } from "date-fns";
import { AccountData } from "./SubComponent/AccountData";
import { AccountFarms } from "./SubComponent/AccountFarms";
import { AccountNotes } from "./SubComponent/AccountNotes";
interface ApiResponse {
  id: string;
  created_at: string;
  name: string;
  village: string;
  city_id: string;
  governorate_id: string;
  full_address: string;
  mobile_number: string;
  whatsapp_number: string;
  email: string;
  avatar: string;
  account_status: number;
  national_id: string;
  specialty_id: string;
  level_id: string;
  ewallet_number: string;
  wallet_balance: number;
  activity_type: {
    id: string;
    name: string;
  };
  farms_count: number;
  approx_breeding_size: number;
}
export const BreedersDetails = () => {
  const { breederId } = useParams<{ breederId: string }>();
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rangeText, setRangeText] = useState("");
  const [activeType, setActiveType] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    moment.locale("ar");

    let mainHeaderName = "تفاصيل مربي";
    dispatch(setMainHeaderName({ mainHeaderName }));
    API.get(`breeders/${breederId}`).then((res) => {
      if (res) {
        const updatedFarmerData = {
          ...res.data,
          avatar: `${URL}${res.data.avatar}`,
        };
        // Set the locale to Arabic

        setApiResponse(updatedFarmerData);
        API.get(`work-areas`).then((response) => {
          const idAndNameData = response.data.map((item: any) => ({
            id: item.id,
            name: item.name,
          }));
          const governorateData = response.data.map((item: any) => ({
            governorateId: item.governorate.id,
            governorateName: item.governorate.name,
          }));
          const getDistinctValues = (
            arr: { governorateId: string; governorateName: string }[]
          ) => {
            const uniqueValues = arr.filter(
              (value, index, self) =>
                index ===
                self.findIndex((v) => v.governorateId === value.governorateId)
            );
            return uniqueValues;
          };
          const distinctGovernorateData = getDistinctValues(governorateData);

          // let govName = "";
          let cityName = "";
          if (
            distinctGovernorateData.length > 0 &&
            distinctGovernorateData != null &&
            idAndNameData.length > 0 &&
            idAndNameData != null
          ) {
            // govName = distinctGovernorateData.find(c => c.governorateId === res.data.gov_id)?.governorateName ?? "";
            cityName =
              idAndNameData.find((c: any) => c.id === res.data.city_id)?.name ??
              "";
          }
          setRangeText(`${cityName}`);
        });
        setIsLoading(false);
      }
    });
  }, []);
  const handleImageError = (event: any) => {
    // Set a fallback image source when the original image fails to load
    event.target.src = userIcon;
  };
  return (
    <div className="BreedersDetails">
      <div className="breeder-header grid grid-cols-2">
        <div className="col-start-1 pr-4 pt-4">
          <div className="flex gap-4">
            <div>
              <img
                src={apiResponse?.avatar}
                alt="user"
                onError={handleImageError}
              />
            </div>
            <div className="grid">
              <p className="user-name">{apiResponse?.name}</p>
              <div className="flex justify-between gap-4">
                <div className="flex items-center gap-2">
                  <span className="evalu">التقييم :</span>
                  <div className="flex gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M6 0.5L8.03951 4.18183L12 5.08359L9.3 8.26085L9.7082 12.5L6 10.7818L2.2918 12.5L2.7 8.26085L0 5.08359L3.96049 4.18183L6 0.5Z"
                        fill="#FFCB45"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M6 0.5L8.03951 4.18183L12 5.08359L9.3 8.26085L9.7082 12.5L6 10.7818L2.2918 12.5L2.7 8.26085L0 5.08359L3.96049 4.18183L6 0.5Z"
                        fill="#FFCB45"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M6 0.5L8.03951 4.18183L12 5.08359L9.3 8.26085L9.7082 12.5L6 10.7818L2.2918 12.5L2.7 8.26085L0 5.08359L3.96049 4.18183L6 0.5Z"
                        fill="#FFCB45"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M6 0.5L8.03951 4.18183L12 5.08359L9.3 8.26085L9.7082 12.5L6 10.7818L2.2918 12.5L2.7 8.26085L0 5.08359L3.96049 4.18183L6 0.5Z"
                        fill="#FFCB45"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M6 0.5L8.03951 4.18183L12 5.08359L9.3 8.26085L9.7082 12.5L6 10.7818L2.2918 12.5L2.7 8.26085L0 5.08359L3.96049 4.18183L6 0.5Z"
                        fill="#FFCB45"
                      />
                    </svg>
                  </div>
                </div>
                <span className="member-since">
                  عضو منذ{" "}
                  {moment(apiResponse?.created_at).format("DD MMMM YYYY")}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Delete Icon & Close Icon*/}
        <div className="col-start-2 pl-4 pt-4">
          <div className="flex justify-end items-center gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                stroke="#EB001B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <svg
              onClick={() => navigate(-1)}
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 13 12"
              fill="none"
            >
              <path
                d="M11.4473 1.21143L1.54777 11.1109"
                stroke="#64748B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.54688 1.21143L11.4464 11.1109"
                stroke="#64748B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <div className="col-start-1 mt-4 pr-4">
          <div className="flex gap-4">
            <div
              className={
                activeType === 1 ? "breeder-item-active" : "breeder-item"
              }
              onClick={() => setActiveType(1)}
            >
              بيانات الحساب
            </div>
            <div
              className={
                activeType === 2 ? "breeder-item-active" : "breeder-item"
              }
              onClick={() => setActiveType(2)}
            >
              العنابر
            </div>
            <div
              className={
                activeType === 3 ? "breeder-item-active" : "breeder-item"
              }
              onClick={() => setActiveType(3)}
            >
              عروض الشراء
            </div>
            <div
              className={
                activeType === 4 ? "breeder-item-active" : "breeder-item"
              }
              onClick={() => setActiveType(4)}
            >
              التقييمات
            </div>
            <div
              className={
                activeType === 5 ? "breeder-item-active" : "breeder-item"
              }
              onClick={() => setActiveType(5)}
            >
              الملاحظات
            </div>
          </div>
        </div>
        {activeType === 2 ? (
          <>
            <div className="col-start-2 pl-10 pt-6 flex justify-end">
              <div
                className="add-farm-btn"
                onClick={() => navigate(`Add/Farm`)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M10.5049 1.02539C6.02189 1.02539 2.375 4.67228 2.375 9.15527C2.375 13.6383 6.02189 17.2852 10.5049 17.2852C14.9879 17.2852 18.6348 13.6383 18.6348 9.15527C18.6348 4.67228 14.9879 1.02539 10.5049 1.02539ZM14.0617 9.83272H11.1823V12.7121C11.1823 13.0861 10.8789 13.3895 10.5049 13.3895C10.1309 13.3895 9.82743 13.0861 9.82743 12.7121V9.83272H6.94806C6.57404 9.83272 6.27061 9.52929 6.27061 9.15527C6.27061 8.78126 6.57404 8.47782 6.94806 8.47782H9.82743V5.59845C9.82743 5.22443 10.1309 4.921 10.5049 4.921C10.8789 4.921 11.1823 5.22443 11.1823 5.59845V8.47782H14.0617C14.4357 8.47782 14.7392 8.78126 14.7392 9.15527C14.7392 9.52929 14.4357 9.83272 14.0617 9.83272Z"
                    fill="white"
                  />
                </svg>
                اضافة عنبر
              </div>
            </div>
          </>
        ) : null}
      </div>
      {/* Content */}
      {activeType === 1 ? (
        <AccountData />
      ) : activeType === 2 ? (
        <AccountFarms />
      ) : (
        <AccountNotes />
      )}
    </div>
  );
};

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./EditMission.scss";
import moment from "moment";
import { Loading } from "../../Loading/Loading";
import { toast } from "react-toastify";
import arrow from "../../../Assets/Icons/arrow.jpeg";
import API from "../../../Api";
import { DatePickerComponent } from "../AddMission/SubComponents/DatePicker/DatePickerComponent";
import { MissionType } from "../AddMission/SubComponents/MissionType";
import { MissionRangePopUp } from "../AddMission/SubComponents/MissionRangePopUp/MissionRangePopUp";
import { MissionAssignPopUp } from "../AddMission/SubComponents/MissionAssignPopUp/MissionAssignPopUp";
import { BonusDatePicker } from "./SubComponents/BonusDatePicker";
import { Formik, FormikHelpers } from "formik";
import {
  selectedMissionType,
  setSelectedAssignTo,
  setSelectedWorkAreas,
  toggleShowEditMission,
} from "../../../redux/Slices/MissionSlice";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { EditBreederMission } from "./SubComponents/EditBreederMission/EditBreederMission";
import { EditFarmMission } from "./SubComponents/EditFarmMission/EditFarmMission";
import { EditHousingCycleMission } from "./SubComponents/EditHousingCycleMission/EditHousingCycleMission";

interface ApiResponse {
  id: string;
  created_at: string;
  updated_at: string;
  full_address: string;
  latitude: string;
  longitude: string;
  maps_url: string;
  name: string;
  audience: string;
  status: string;
  details: string;
  reward: number;
  due_at: string;
  early_bonus: number;
  early_bonus_due_at: string;
  accepted_at: string | null;
  req_review_at: string | null;
  completed_at: string | null;
  salesman_id: string | null;
  housing_id: string | null;
  farm_id: string;
  farmer_id: string;
  trader_id: string | null;
  salesman: {
    id: string;
    name: string;
    village: string;
    governorate_id: string;
    city_id: string;
    full_address: string;
    city: string;
  };
  type: {
    id: string;
    name: string;
  };
  workAreas: {
    id: string;
    name: string;
  }[];
  assignedUsers: {
    id: string;
    name: string;
  }[];
  assignedSpecialties: any[]; // Adjust the type accordingly
  breeder: {
    id: string;
    name: string;
    mobile_number: string;
    city: {
      id: string;
      name: string;
      governorate_id: string;
      governorate: {
        id: string;
        name: string;
      };
    };
  };
  farm: {
    id: string;
    name: string;
    latitude: number;
    longitude: number;
    governorate: {
      created_at: string;
      updated_at: string;
      id: string;
      name: string;
    };
    city: {
      id: string;
      name: string;
      governorate_id: string;
    };
  };
  count: number;
}

export const EditMission = () => {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [data, setData] = useState<{}>({});

  const [showTypePopUp, setShowTypePopUp] = useState(false);
  const [showRangePopUp, setShowRangePopUp] = useState(false);
  const [hasBonus, setHasBonus] = useState(false);
  const [showMissionAssignPopUp, setShowMissionAssignPopUp] = useState(false);
  const { MissionId } = useParams<{ MissionId: string }>();
  const stateFromMissionSlice = useSelector((state: any) => state.missions);
  const stateFromBreedersModalSlice = useSelector(
    (state: any) => state.breedersModal
  );
  const stateFromFarmsModalSlice = useSelector(
    (state: any) => state.FarmsModal
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [previousPath, setPreviousPath] = useState<string | null>(null);

  useEffect(() => {
    moment.locale("en");

    setPreviousPath(location.state.from);

    let assignedIds: number[] = [];
    let assignedType = "";
    let assignedText = "";
    let isChanged = false;
    let rangeIds: number[] = [];
    let workAreaChanged = false;
    let rangeTitle = "";

    dispatch(setSelectedWorkAreas({ rangeIds, rangeTitle, workAreaChanged }));
    dispatch(
      setSelectedAssignTo({
        assignedIds,
        assignedType,
        assignedText,
        isChanged,
      })
    );
    setIsLoading(true);
    let mainHeaderName = "تعديل مهمة";
    dispatch(setMainHeaderName({ mainHeaderName }));
    API.get(`dashboard/missions/${MissionId}`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          navigate(-1);
          setIsLoading(false);
        } else {
          // Set the locale to Arabic
          moment.locale("ar");

          setApiResponse(res.data);

          if (res.data.early_bonus === null || res.data.early_bonus === 0) {
            setHasBonus(false);
          } else {
            setHasBonus(true);
          }
          setData(res.data);
          setIsLoading(false);
        }
      }
    });
  }, []);

  const handleShowTypePopUp = () => setShowTypePopUp(!showTypePopUp);

  const handleShowRangePopUp = () => setShowRangePopUp(!showRangePopUp);

  const handleShowMissionAssignPopUp = () => {
    setShowMissionAssignPopUp(!showMissionAssignPopUp);
  };

  const handleShowEditComponent = () => {
    let type = 1;
    dispatch(selectedMissionType({ type }));
    let isVisible = false;
    let markAfterEdit = false;
    dispatch(toggleShowEditMission({ isVisible, markAfterEdit }));
    navigate(-1);
  };

  const handleChangeMission = (
    value: string | number | boolean,
    field: string,
    setValues: FormikHelpers<any>["setValues"]
  ) => {
    // Update the Formik form state with the changed values

    setValues((prevValues: { apiResponse: ApiResponse }) => ({
      ...prevValues,
      apiResponse: {
        ...prevValues.apiResponse,
        [field]: value,
      },
    }));
    if (apiResponse) {
      // Create a new object with the updated field
      const updatedApiResponse: ApiResponse = {
        ...apiResponse,
        [field]: value, // Replace 'New Name' with the new value
      };

      // Update the state with the new object
      setApiResponse(updatedApiResponse);
    }
  };

  const handleEditMission = () => {
    setIsLoading(true);
    if (
      stateFromMissionSlice.assignedIds.length === 0 &&
      apiResponse?.assignedUsers.length === 0 &&
      apiResponse?.salesman === null
    ) {
      toast.error("من فضلك قم بتحديد لمن ستعين المهمة");
      setIsLoading(false);
    } else if (
      stateFromMissionSlice.selecteTypeId === 0 &&
      apiResponse?.type === null
    ) {
      toast.error("من فضلك قم بأختيار نوع المهمة");
      setIsLoading(false);
    } else if (apiResponse?.reward === 0) {
      toast.warn("من فضلك قم بأدخال قيمة المقابل المادي");
      setIsLoading(false);
    } else if (apiResponse?.name === "") {
      toast.warn("من فضلك قم بأدخال عنوان المهمة");
      setIsLoading(false);
    } else if (apiResponse?.early_bonus === 0 && hasBonus === true) {
      toast.warn("من فضلك قم بأختيار قيمة الحافز");
      setIsLoading(false);
    } else {
      submitEditMission();
    }
  };

  const submitEditMission = () => {
    setIsLoading(true);
    moment.locale("en");

    let citiesStore = [];
    /*
     * In Missions With Type Self We Will Implement This Logic
     * #-By Default we will take Farm Location
     * #-If we don't have farm location we will take Breeder location
     * #-Else that we will take the selected work area
     */
    if (apiResponse?.farm != null && apiResponse.farm.city != null) {
      citiesStore.push(Number(apiResponse?.farm.city.id));
    } else if (
      apiResponse?.breeder != null &&
      apiResponse.breeder.city != null
    ) {
      citiesStore.push(Number(apiResponse?.breeder.city.id));
    }

    let assignedArr = [];
    if (apiResponse?.salesman != null) {
      assignedArr.push(Number(apiResponse?.salesman.id));
    }

    let values: { [key: string]: any } = {
      type_id:
        stateFromMissionSlice.selecteTypeId === 0
          ? Number(apiResponse?.type.id)
          : Number(stateFromMissionSlice.selecteTypeId),
      work_area_ids:
        stateFromMissionSlice.workAreasIds.length === 0
          ? apiResponse?.workAreas.map((item) => Number(item.id))
          : stateFromMissionSlice.workAreasIds.map((item: any) => Number(item)),
      assignment: {
        type:
          stateFromMissionSlice.assignedIds.length === 0
            ? apiResponse?.assignedUsers.length !== 0
              ? "اشخاص"
              : "تخصصات"
            : stateFromMissionSlice.assignedType,
        ids:
          apiResponse?.audience === "self"
            ? stateFromMissionSlice.assignedIds.length === 0
              ? assignedArr
              : stateFromMissionSlice.assignedIds.map((item: any) =>
                  Number(item)
                )
            : stateFromMissionSlice.assignedIds.length === 0
            ? apiResponse?.assignedUsers.map((item) => Number(item.id))
            : stateFromMissionSlice.assignedIds.map((item: any) =>
                Number(item)
              ),
      },
      due_at: moment(stateFromMissionSlice.dueDate).format("YYYY-MM-DD"),
      name: apiResponse?.name,
      reward: Number(apiResponse?.reward),
      early_bonus: Number(apiResponse?.early_bonus),
      early_bonus_due_at: apiResponse?.early_bonus_due_at,
      maps_url: apiResponse?.maps_url,
      farm_id:
        stateFromFarmsModalSlice.farmId === 0
          ? Number(apiResponse?.farm_id)
          : Number(stateFromFarmsModalSlice.farmId),
      farmer_id:
        stateFromBreedersModalSlice.breederId === 0
          ? Number(apiResponse?.farmer_id)
          : Number(stateFromBreedersModalSlice.breederId),
    };
    let valuesWithOutWorkAreas = {
      type_id:
        stateFromMissionSlice.selecteTypeId === 0
          ? Number(apiResponse?.type.id)
          : Number(stateFromMissionSlice.selecteTypeId),

      due_at: moment(stateFromMissionSlice.dueDate).format("YYYY-MM-DD"),
      name: apiResponse?.name,
      reward: Number(apiResponse?.reward),
      early_bonus: Number(apiResponse?.early_bonus),
      early_bonus_due_at: apiResponse?.early_bonus_due_at,
      farm_id:
        stateFromFarmsModalSlice.farmId === 0
          ? Number(apiResponse?.farm_id)
          : Number(stateFromFarmsModalSlice.farmId),
      farmer_id:
        stateFromBreedersModalSlice.breederId === 0
          ? Number(apiResponse?.farmer_id)
          : Number(stateFromBreedersModalSlice.breederId),
    };

    if (values.farm_id === 0) {
      delete values.farm_id;
    }
    if (values.work_area_ids.length === 0) {
      delete values.work_area_ids;
    }
    if (valuesWithOutWorkAreas.farmer_id === 0) {
      delete values.farmer_id;
    }
    if (values.maps_url === "") {
      delete values.maps_url;
    }

    if (
      apiResponse?.status === "revision" ||
      apiResponse?.status === "in_progress" ||
      previousPath === "in_progress" ||
      previousPath === "revision"
    ) {
      delete values.assignment;
      delete values.work_area_ids;
      API.post(`dashboard/missions/${MissionId}/complete`, values)
        .then((response) => {
          if (response) {
            toast.success("تمت تعديل المهمة وتعيينها كتامة بنجاح");
            navigate(`/Missions/All`);

            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log("error:", error);
          setIsLoading(false);

          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
        });
    } else {
      API.patch(`dashboard/missions/${MissionId}`, values)
        .then((response) => {
          if (response) {
            toast.success("تمت تعديل المهمة بنجاح");

            navigate(`/Missions/All`);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          toast.error(`${error.message}`);
          setIsLoading(false);
        });
    }
  };

  const renderWorkRangeText = () => {
    if (apiResponse?.audience === "self") {
      if (stateFromMissionSlice.workAreaChanged === false) {
        if (
          apiResponse?.workAreas != null &&
          apiResponse.workAreas.length > 0
        ) {
          if (apiResponse?.workAreas.length > 1) {
            return `${apiResponse?.workAreas[0].name} و ${
              apiResponse?.workAreas.length - 1
            } اخري`;
          } else {
            return apiResponse?.workAreas[0].name;
          }
        } else if (apiResponse?.farm != null && apiResponse.farm.city != null) {
          return apiResponse.farm.city.name;
        } else if (
          apiResponse.breeder != null &&
          apiResponse.breeder.city != null
        ) {
          return apiResponse.breeder.city.name;
        } else {
          return "قم بأختيار نطاق العمل";
        }
      } else if (stateFromMissionSlice.workAreasIds.length !== 0) {
        return stateFromMissionSlice.workAreasTitle;
      } else {
        return "قم بأختيار نطاق العمل";
      }
    } else {
      if (stateFromMissionSlice.workAreaChanged === false) {
        if (
          apiResponse?.workAreas != null &&
          apiResponse.workAreas.length > 0
        ) {
          if (apiResponse?.workAreas.length > 1) {
            return `${apiResponse?.workAreas[0].name} و ${
              apiResponse?.workAreas.length - 1
            } اخري`;
          } else {
            return apiResponse?.workAreas[0].name;
          }
        } else {
          return "قم بأختيار نطاق العمل";
        }
      } else if (stateFromMissionSlice.workAreasIds.length !== 0) {
        return stateFromMissionSlice.workAreasTitle;
      } else {
        return "قم بأختيار نطاق العمل";
      }
    }
  };

  const workRangeText = (
    <div className="select-stage pr-4">
      {stateFromMissionSlice.workAreaChanged === false
        ? apiResponse?.workAreas != null && apiResponse.workAreas.length > 0
          ? apiResponse?.workAreas.length > 1
            ? `${apiResponse?.workAreas[0].name} و ${
                apiResponse?.workAreas.length - 1
              } اخري`
            : apiResponse?.workAreas[0].name
          : "قم بأختيار نطاق العمل"
        : stateFromMissionSlice.workAreasIds.length !== 0
        ? stateFromMissionSlice.workAreasTitle
        : "قم بأختيار نطاق العمل"}
    </div>
  );

  const assignedToText = (
    <div className="select-stage pr-4">
      {stateFromMissionSlice.assignedToChanged === false
        ? apiResponse?.assignedUsers != null &&
          apiResponse.assignedUsers.length > 0
          ? apiResponse?.assignedUsers.length > 1
            ? `${apiResponse?.assignedUsers[0].name} و ${
                apiResponse?.assignedUsers.length - 1
              } اخري`
            : apiResponse?.assignedUsers[0].name
          : "قم بأختيار لمن ستعين المهمة"
        : stateFromMissionSlice.assignedIds.length !== 0
        ? stateFromMissionSlice.assignedText
        : "قم بأختيار لمن ستعين المهمة"}
    </div>
  );

  const selfAssignedToText = (
    <div className="select-stage pr-4">
      {stateFromMissionSlice.assignedToChanged === false
        ? apiResponse?.salesman != null
          ? apiResponse?.salesman.name
          : "قم بأختيار لمن ستعين المهمة"
        : stateFromMissionSlice.assignedIds.length !== 0
        ? stateFromMissionSlice.assignedText
        : "قم بأختيار لمن ستعين المهمة"}
    </div>
  );

  return (
    <div className="EditMission">
      <Formik
        onSubmit={() => handleEditMission()}
        initialValues={{ apiResponse: apiResponse || null }} // Handle null case
        validationSchema={null}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
        }) => (
          <>
            {/* First Section [Breeder] */}
            {apiResponse?.id !== undefined ? ( //To Check That Get Mission By Id Is returned Succesffuly
              <>
                <EditBreederMission
                  breederId={Number(apiResponse?.farmer_id)}
                />
                <EditFarmMission
                  farmId={Number(apiResponse.farm_id)}
                  breederId={Number(
                    apiResponse.breeder != null ? apiResponse.breeder.id : 0
                  )}
                />
                {/* Check if there is an housing cycle exist */}
                {apiResponse?.housing_id !== undefined &&
                apiResponse.housing_id !== null ? (
                  <EditHousingCycleMission
                    farmId={Number(apiResponse.farm_id)}
                    housingId={Number(apiResponse.housing_id)}
                  />
                ) : null}
              </>
            ) : null}
            <form onSubmit={handleSubmit}>
              {/* Third Section [Mission Range and Assign Mission] */}
              <div className="add-section mb-4">
                <div className="grid grid-cols-2">
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h2 className="flex justify-start mission-details">
                      النطاق واسناد المهمة
                    </h2>
                  </div>
                  <div className="col-span-full mb-4">
                    <div className="divider"></div>
                  </div>
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="mission-type"> نطاق المهمة</h3>
                  </div>
                  <div className="col-span-full pr-4">
                    <label htmlFor="modal-9">
                      <div
                        className="flex justify-between select-mission-container"
                        onClick={() => handleShowRangePopUp()}
                      >
                        <div className="select-mission-type pr-4">
                          {workRangeText}
                        </div>
                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>
                  {/* رابط الموقع الجغرافي */}
                  <div className="col-start-1 mt-4  pr-4">
                    <h3 className="mission-type"> رابط الموقع الجغرافي</h3>
                  </div>
                  <div className="col-span-full mt-4 mb-4 pr-4 pl-20">
                    <div>
                      <input
                        id="mapUrl"
                        type="text"
                        placeholder="قم بأدخال الرابط"
                        className="mission-map-input"
                        value={values.apiResponse?.maps_url}
                        onChange={(e) =>
                          handleChangeMission(
                            e.target.value,
                            "maps_url",
                            setValues
                          )
                        }
                      />
                    </div>
                  </div>
                  {/* تعيين المهمة ل */}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="mission-type"> تعيين المهمة ل </h3>
                  </div>
                  <div className="col-span-full pr-4">
                    <label htmlFor="modal-10">
                      <div
                        className="flex justify-between select-mission-container"
                        onClick={() => handleShowMissionAssignPopUp()}
                      >
                        <div className="select-mission-type pr-4">
                          {apiResponse?.audience === "self"
                            ? selfAssignedToText
                            : assignedToText}
                        </div>
                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              {/* Fourth Section [Mission Details] */}
              <div className="add-section mb-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="col-start-1 mt-4 pr-4">
                    <h2 className="flex justify-start mission-details">
                      تفاصيل المهمة
                    </h2>
                  </div>
                  <div className="col-span-full">
                    <div className="divider"></div>
                  </div>
                  {/* نوع المهمة */}
                  <div className="col-start-1 pr-4">
                    <h3 className="mission-type"> نوع المهمة</h3>
                  </div>
                  <div className="col-span-full pr-4">
                    <label htmlFor="modal-8">
                      <div
                        className="flex justify-between select-mission-container"
                        onClick={() => handleShowTypePopUp()}
                      >
                        <div className="select-mission-type pr-4">
                          {stateFromMissionSlice.selecteTypeId !== 0
                            ? stateFromMissionSlice.selectedTypeName
                            : apiResponse?.type.name}
                        </div>
                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="col-start-1   pr-4">
                    <h3 className="mission-type"> عنوان المهمة</h3>
                  </div>
                  <div className="col-start-2   pr-4">
                    <h3 className="mission-type"> تاريخ الأنتهاء</h3>
                  </div>

                  <div className="col-start-1 pr-4">
                    <div>
                      <input
                        type="text"
                        placeholder="عنوان المهمة"
                        className="mission-address-input"
                        value={values.apiResponse?.name}
                        name="apiResponse.name"
                        onChange={(e) =>
                          handleChangeMission(e.target.value, "name", setValues)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-start-2  pl-4">
                    <DatePickerComponent
                      component="EndMission"
                      activation={false}
                      due_date={
                        apiResponse?.due_at
                          ? new Date(apiResponse.due_at)
                          : new Date()
                      }
                      key={"EndMission"}
                      isEdit={true}
                    />
                  </div>
                  {/* Mission Details */}
                  <div className="col-start-1   pr-4">
                    <h3 className="mission-type"> تفاصيل المهمة</h3>
                  </div>
                  <div className="col-span-full pr-4 pl-4">
                    <div className="txt-area">
                      <textarea
                        style={{ width: "100%" }}
                        className="mission-text-area"
                        placeholder="تفاصيل المهمة"
                        value={apiResponse?.details}
                        onChange={(e) =>
                          handleChangeMission(
                            e.target.value,
                            "details",
                            setValues
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Fifth Section [المقابل المادي] */}
              <div className="add-reward-section mb-4">
                <div className="grid grid-cols-2">
                  <div className="col-start-1 mt-4 pr-4">
                    <h2 className="flex justify-start mission-details">
                      المقابل المادي
                    </h2>
                  </div>
                  <div className="col-span-full">
                    <div className="divider"></div>
                  </div>
                  <div className="col-start-1 pr-4">
                    <h3 className="mission-type"> المقابل المادي</h3>
                  </div>
                  <div className="col-span-full mt-4 mb-4 pr-4 pl-20">
                    <div>
                      <input
                        type="text"
                        placeholder="00"
                        className="mission-map-input"
                        value={values.apiResponse?.reward}
                        onChange={(e) =>
                          handleChangeMission(
                            e.target.value,
                            "reward",
                            setValues
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="col-start-1 mt-4  pr-4">
                    <div className="bonus flex gap-4 items-center">
                      <span className="bonus-text">
                        حافز للأداء الإستثنائي ؟
                      </span>
                      <input
                        type="checkbox"
                        className="switch switch-success"
                        checked={hasBonus}
                        onChange={() => setHasBonus(!hasBonus)}
                      />
                    </div>
                  </div>

                  {hasBonus ? (
                    <>
                      <div className="col-start-2 mt-4  pr-4">
                        <div className="bonus flex gap-4 items-center">
                          <span className="bonus-text">
                            تاريخ الحافز الاستثنائي
                          </span>
                        </div>
                      </div>
                      <div className="col-start-1 mt-4 mb-4 pr-4 pl-20">
                        <div>
                          <input
                            type="text"
                            placeholder="00"
                            className="mission-map-input"
                            value={values.apiResponse?.early_bonus}
                            onChange={(e) =>
                              handleChangeMission(
                                e.target.value,
                                "early_bonus",
                                setValues
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="col-start-2 mt-4 mb-4 pl-4">
                        <BonusDatePicker
                          activation={false}
                          bonus_date={
                            apiResponse?.early_bonus_due_at
                              ? new Date(apiResponse.early_bonus_due_at)
                              : new Date()
                          }
                          isEdit={true}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              {/* Fifth Section [Done Or cancel] */}
              <div className="add-actions p-5">
                <div className="grid grid-cols-2">
                  <div className="col-start-1">
                    <div className="flex gap-4">
                      <button className="add-btn" type="submit">
                        {apiResponse?.status === "revision" ||
                        apiResponse?.status === "in_progress" ||
                        previousPath === "in_progress" ||
                        previousPath === "revision"
                          ? "إتمام"
                          : "تعديل"}
                      </button>
                      <div
                        className="cancel-btn"
                        onClick={() => handleShowEditComponent()}
                      >
                        الغاء
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>

      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
      {showTypePopUp === true ? (
        <MissionType isEdit={true} typeId={apiResponse?.type.id} />
      ) : null}
      {showRangePopUp === true ? (
        <MissionRangePopUp isEdit={true} workAreas={apiResponse?.workAreas} />
      ) : null}
      {showMissionAssignPopUp === true ? (
        <MissionAssignPopUp
          farmCityId={
            apiResponse?.farm != null ? Number(apiResponse?.farm.city.id) : 0
          }
          farmGovernorateId={
            apiResponse?.farm != null && apiResponse?.farm.governorate != null
              ? Number(apiResponse?.farm.governorate.id)
              : 0
          }
          hasFarm={apiResponse?.farm != null ? true : false}
          breederCityId={
            apiResponse?.breeder != null && apiResponse.breeder.city != null
              ? Number(apiResponse.breeder.city.id)
              : 0
          }
          breederGovernorateId={
            apiResponse?.breeder != null &&
            apiResponse.breeder.city != null &&
            apiResponse.breeder.city.governorate != null
              ? Number(apiResponse.breeder.city.governorate.id)
              : 0
          }
        />
      ) : null}
    </div>
  );
};

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./RefusedMissions.scss";
import userIcon from "../../../Assets/Icons/user.jpeg";
import API from "../../../Api";
import { Loading } from "../../Loading/Loading";
import { toast } from "react-toastify";
import {
  handleSubmitFilterResult,
  sendMissionIdToPopUp,
  showDetailsPopUp,
} from "../../../redux/Slices/MissionSlice";
import moment from "moment";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { TotalCount } from "../../../SubComponents/TotalCount/TotalCount";
import { ExportExcel } from "../../../SubComponents/Export/ExcelExport";
export const RefusedMission = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const stateFromMissionSlice = useSelector((state: any) => state.missions);

  const ShowMissionDetailsPopUp = (missionId: number) => {
    dispatch(sendMissionIdToPopUp({ missionId }));
    let isDetailsActive = true;
    dispatch(showDetailsPopUp({ isDetailsActive }));
  };
  useEffect(() => {
    setIsLoading(true);
    let mainHeaderName = "المهام";
    dispatch(setMainHeaderName({ mainHeaderName }));
    if (
      stateFromMissionSlice.isFiltered === true &&
      stateFromMissionSlice.headerType === 6 &&
      stateFromMissionSlice.filterDataObj.length > 0
    ) {
      if (stateFromMissionSlice.filterDataObj[0].length > 0) {
        setData(stateFromMissionSlice.filterDataObj[0]);
        setTotalRows(stateFromMissionSlice.filterTotalCount);
        setIsLoading(false);
      } else {
        setData([]);
        setTotalRows(0);
        setIsLoading(false);
      }
    } else {
      setPageNumber(1);
      API.get(
        `dashboard/missions?status=rejected&sort_by=id&sort_order=DESC&page=${pageNumber}&limit=${pageSize}`
      ).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
            setIsLoading(false);
          } else {
            setData(res.data.items);
            setTotalRows(res.data.totalCount);
            setIsLoading(false);
          }
        }
      });
    }
  }, [stateFromMissionSlice.isFiltered]);
  const hanldeChangePage = (targetPN: number) => {
    setIsLoading(true);
    if (
      stateFromMissionSlice.isFiltered === true &&
      stateFromMissionSlice.headerType === 6 &&
      stateFromMissionSlice.filterDataObj.length > 0
    ) {
      if (stateFromMissionSlice.filterDataObj[0].length > 0) {
        API.get(
          `${stateFromMissionSlice.filterUrl}page=${targetPN}&limit=${pageSize}`
        ).then((res) => {
          if (res) {
            if (res.status === 403) {
              toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
            } else {
              setData(res.data.items);
              setTotalRows(res.data.totalCount);
              setPageNumber(targetPN);
              setIsLoading(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          }
        });
      }
    } else {
      API.get(
        `dashboard/missions?status=rejected&sort_by=id&sort_order=DESC&page=${targetPN}&limit=${pageSize}`
      ).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setData(res.data.items);
            setTotalRows(res.data.totalCount);
            setPageNumber(targetPN);
            setIsLoading(false);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        }
      });
    }
  };
  const renderPagination = () => {
    const nPages = Math.ceil(totalRows / pageSize);
    const numbers = Array.from({ length: nPages }, (_, index) => index + 1);
    let paginationItem = numbers.map((n) => {
      return (
        <>
          <button
            className={pageNumber === n ? "btn btn-pagination-active" : "btn"}
            onClick={() => hanldeChangePage(n)}
          >
            {n}
          </button>
        </>
      );
    });
    return (
      <div className="pagination w-full max-w-xs overflow-auto">
        {paginationItem}
      </div>
    );
  };
  return (
    <>
      {/* Total Count */}

      <div className="grid grid-cols-2 my-4 RefusedMissions">
        <TotalCount count={totalRows} title="مهام مرفوضة " />
        <ExportExcel
          url={`dashboard/missions?status=rejected&sort_by=id&sort_order=DESC&page=1&limit=100000000`}
          fileName="مهام  مرفوضة"
          page="rejected"
        />
        {isLoading === true ? (
          <>
            <Loading />
          </>
        ) : null}
        {data.map((item: any) => {
          const createdAtDate = moment(item.created_at);
          const dueDate = moment(
            item.due_at == null ? new Date() : item.due_at
          );

          // Set the locale to Arabic
          moment.locale("ar");
          const createdAtDate_Arabic = createdAtDate.format("DD MMM YYYY");
          const dueDate_Arabic = dueDate.format("DD MMMM YYYY");
          const audienceTxt =
            item.audience === "unassigned"
              ? "لم يتم تعيينها"
              : item.audience === "self"
              ? "مهمة ذاتية"
              : item.audience === "direct_people"
              ? "تم تعيينها لاشخاص مباشرين"
              : item.audience === "areas"
              ? "تم تعيينها لكل الاشخاص داخل منطة / مناطق عمل محددة"
              : item.audience === "specialties"
              ? "تم تعيينها للاشخاص اصحاب تخصص / تخصصات محددة"
              : item.audience === "specialties_within_areas"
              ? "تم تعيينها لتخصصات داخل مناطق عمل محددة"
              : "";

          return (
            <div className="col-span-full grid grid-cols-2 mr-4 ml-4 mt-4 mission-content">
              <div className="col-start-1">
                <span className="mission-type">
                  {item.type != null ? item.type.name : ""}
                </span>
              </div>

              <div className="col-start-2 flex justify-end pl-4">
                <span className="mission-type">{item.id}</span>
              </div>

              <div className="col-start-2 flex justify-end">
                <div className="popover" style={{ backgroundColor: "white" }}>
                  <svg
                    className="popover-trigger arrow"
                    tabIndex={0}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z"
                      fill="#A7AEC1"
                    />
                    <path
                      d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z"
                      fill="#A7AEC1"
                    />
                    <path
                      d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                      fill="#A7AEC1"
                    />
                  </svg>
                  <div
                    className="popover-content popover-bottom-left"
                    tabIndex={0}
                  >
                    <ul>
                      <label
                        className="three-dots-li gap-2 justify-center items-center pr-2"
                        htmlFor="modal-1"
                        onClick={() => ShowMissionDetailsPopUp(item.id)}
                      >
                        <div className="pt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="14"
                            viewBox="0 0 20 14"
                            fill="none"
                          >
                            <path
                              d="M17.6084 5.21092C18.5748 6.22765 18.5748 7.77236 17.6084 8.78909C15.9786 10.5039 13.1794 12.8333 9.99984 12.8333C6.82024 12.8333 4.02108 10.5039 2.39126 8.78909C1.42492 7.77236 1.42492 6.22765 2.39126 5.21092C4.02108 3.49612 6.82024 1.16667 9.99984 1.16667C13.1794 1.16667 15.9786 3.49612 17.6084 5.21092Z"
                              stroke="#64748B"
                              stroke-width="1.5"
                            />
                            <path
                              d="M12.4998 7.00001C12.4998 8.38072 11.3805 9.50001 9.99984 9.50001C8.61913 9.50001 7.49984 8.38072 7.49984 7.00001C7.49984 5.61929 8.61913 4.50001 9.99984 4.50001C11.3805 4.50001 12.4998 5.61929 12.4998 7.00001Z"
                              stroke="#64748B"
                              stroke-width="1.5"
                            />
                          </svg>
                        </div>
                        <span>عرض المهمة</span>
                      </label>
                      <input
                        className="modal-state"
                        id="modal-1"
                        type="checkbox"
                      />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-start-1">
                <span className="mission-address">{item.name}</span>
              </div>
              <div className="col-start-2"></div>
              <div className="col-start-1">
                <div className="flex gap-4">
                  <div className="mission-reward">السعر {item.reward} جنيه</div>
                  <div className="created-at">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <path
                        d="M1.875 6.6875C1.875 4.47836 3.66586 2.6875 5.875 2.6875H9.125C11.3341 2.6875 13.125 4.47836 13.125 6.6875V10.25C13.125 12.4591 11.3341 14.25 9.125 14.25H5.875C3.66586 14.25 1.875 12.4591 1.875 10.25V6.6875Z"
                        stroke="#9BA0B1"
                        stroke-width="1.5"
                      />
                      <path
                        d="M1.875 6.125H13.125"
                        stroke="#9BA0B1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M5 1.75L5 3.625"
                        stroke="#9BA0B1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 1.75V3.625"
                        stroke="#9BA0B1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle cx="7.5" cy="9.875" r="0.625" fill="#9BA0B1" />
                      <circle cx="10" cy="9.875" r="0.625" fill="#9BA0B1" />
                      <circle cx="5" cy="9.875" r="0.625" fill="#9BA0B1" />
                    </svg>
                    انشئت في : {createdAtDate_Arabic}
                  </div>

                  <div className="created-at">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <path
                        d="M1.875 6.6875C1.875 4.47836 3.66586 2.6875 5.875 2.6875H9.125C11.3341 2.6875 13.125 4.47836 13.125 6.6875V10.25C13.125 12.4591 11.3341 14.25 9.125 14.25H5.875C3.66586 14.25 1.875 12.4591 1.875 10.25V6.6875Z"
                        stroke="#9BA0B1"
                        stroke-width="1.5"
                      />
                      <path
                        d="M1.875 6.125H13.125"
                        stroke="#9BA0B1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M5 1.75L5 3.625"
                        stroke="#9BA0B1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 1.75V3.625"
                        stroke="#9BA0B1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle cx="7.5" cy="9.875" r="0.625" fill="#9BA0B1" />
                      <circle cx="10" cy="9.875" r="0.625" fill="#9BA0B1" />
                      <circle cx="5" cy="9.875" r="0.625" fill="#9BA0B1" />
                    </svg>
                    تنتهي في : {dueDate_Arabic}
                  </div>
                </div>
              </div>
              <div className="col-start-2 flex justify-end">
                <div className="flex gap-4 mission-status">
                  <div className="inprogress">مهمة مرفوضة</div>
                  {item.salesman != null || item.salesman !== undefined ? (
                    <>
                      <div className="flex gap-4 assigned-to">
                        <div>
                          <img src={userIcon} alt="user" />
                        </div>
                        <div className=" flex gap-1">
                          <span className="assigned-to-txt">اسند لي:</span>
                          <span className="assinged-user-name">
                            {item.salesman.name}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              {item.type != null && item.type.id.toString() !== "2" ? (
                <>
                  <div className="col-start-1">
                    <ol className="steps">
                      <li
                        className={`step step-primary ${
                          item.farmer_id != null ? "step-active" : "step-done"
                        } overflow-hidden`}
                      >
                        <div className="step-circle">1</div>
                        <h3 className="txt">مرحلة المربي</h3>
                      </li>

                      <li
                        className={`step step-primary ${
                          item.farm_id != null ? "step-active" : "step-done"
                        } step-active overflow-hidden`}
                      >
                        <div className="step-circle">2</div>
                        <h3 className="txt">مرحلة المزرعة</h3>
                      </li>
                      <li
                        className={`step step-primary ${
                          item.housing_id != null ? "step-active" : "step-done"
                        } step-active overflow-hidden`}
                      >
                        <div className="step-circle">3</div>
                        <h3 className="txt">مرحلة التسكين</h3>
                      </li>
                    </ol>
                  </div>
                </>
              ) : null}

              <div className="col-start-1">
                <div className="mission-adduience">{audienceTxt}</div>
              </div>
              <div className="col-start-2">
                <div className="mission-history">
                  اخر تعديل تم بواسطة : Mahmoud ELnabwy
                </div>
              </div>
            </div>
          );
        })}

        {/* Pagination */}
        <div className="flex justify-end pl-8 col-start-2 ">
          {renderPagination()}
        </div>
      </div>
    </>
  );
};

import { useEffect } from "react";
import "./MovementRecord.scss";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { useDispatch } from "react-redux";
export const MovementRecord = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    let mainHeaderName = "سجل الحركة للعنبر";
    dispatch(setMainHeaderName({ mainHeaderName }));
  }, []);
  return (
    <div className="MovementRecord">
      <div className="history-section pr-10 mt-4 grid grid-cols-2 gap-4">
        {/* Header */}
        <div className="col-start-1 pr-4 pt-4 flex items-center ">
          <div className="farm-info-title w-1/2">سجل الحركة للعنبر</div>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>
        <div className="content  p-5 col-start-1">
          <div className="flex gap-1">
            <span className="status-green">تم إدخال :</span>
            <span className="status-content">20000 وحدة من نوع أبيض</span>
          </div>
          {/* التاريخ */}
          <div className="flex gap-4 mt-4">
            <div className="created-at">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
              >
                <path
                  d="M1.875 6.6875C1.875 4.47836 3.66586 2.6875 5.875 2.6875H9.125C11.3341 2.6875 13.125 4.47836 13.125 6.6875V10.25C13.125 12.4591 11.3341 14.25 9.125 14.25H5.875C3.66586 14.25 1.875 12.4591 1.875 10.25V6.6875Z"
                  stroke="#9BA0B1"
                  stroke-width="1.5"
                />
                <path
                  d="M1.875 6.125H13.125"
                  stroke="#9BA0B1"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M5 1.75L5 3.625"
                  stroke="#9BA0B1"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 1.75V3.625"
                  stroke="#9BA0B1"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle cx="7.5" cy="9.875" r="0.625" fill="#9BA0B1" />
                <circle cx="10" cy="9.875" r="0.625" fill="#9BA0B1" />
                <circle cx="5" cy="9.875" r="0.625" fill="#9BA0B1" />
              </svg>
              يوليو ٢٠٢٣
            </div>
          </div>
        </div>

        <div className="content  p-5 col-start-1">
          <div className="flex gap-1">
            <span className="status-orange">تم عرض للبيع :</span>
            <span className="status-content">20000 وحدة من نوع أبيض</span>
          </div>
          {/* التاريخ */}
          <div className="flex gap-4 mt-4">
            <div className="created-at">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
              >
                <path
                  d="M1.875 6.6875C1.875 4.47836 3.66586 2.6875 5.875 2.6875H9.125C11.3341 2.6875 13.125 4.47836 13.125 6.6875V10.25C13.125 12.4591 11.3341 14.25 9.125 14.25H5.875C3.66586 14.25 1.875 12.4591 1.875 10.25V6.6875Z"
                  stroke="#9BA0B1"
                  stroke-width="1.5"
                />
                <path
                  d="M1.875 6.125H13.125"
                  stroke="#9BA0B1"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M5 1.75L5 3.625"
                  stroke="#9BA0B1"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 1.75V3.625"
                  stroke="#9BA0B1"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle cx="7.5" cy="9.875" r="0.625" fill="#9BA0B1" />
                <circle cx="10" cy="9.875" r="0.625" fill="#9BA0B1" />
                <circle cx="5" cy="9.875" r="0.625" fill="#9BA0B1" />
              </svg>
              يوليو ٢٠٢٣
            </div>
          </div>
        </div>

        <div className="content  p-5 col-start-1">
          <div className="flex gap-1">
            <span className="status-red">تم البيع :</span>
            <span className="status-content">20000 وحدة من نوع أبيض</span>
          </div>
          {/* التاريخ */}
          <div className="flex gap-4 mt-4">
            <div className="created-at">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
              >
                <path
                  d="M1.875 6.6875C1.875 4.47836 3.66586 2.6875 5.875 2.6875H9.125C11.3341 2.6875 13.125 4.47836 13.125 6.6875V10.25C13.125 12.4591 11.3341 14.25 9.125 14.25H5.875C3.66586 14.25 1.875 12.4591 1.875 10.25V6.6875Z"
                  stroke="#9BA0B1"
                  stroke-width="1.5"
                />
                <path
                  d="M1.875 6.125H13.125"
                  stroke="#9BA0B1"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M5 1.75L5 3.625"
                  stroke="#9BA0B1"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 1.75V3.625"
                  stroke="#9BA0B1"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle cx="7.5" cy="9.875" r="0.625" fill="#9BA0B1" />
                <circle cx="10" cy="9.875" r="0.625" fill="#9BA0B1" />
                <circle cx="5" cy="9.875" r="0.625" fill="#9BA0B1" />
              </svg>
              يوليو ٢٠٢٣
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  breedersIds: [],
  breedersText: "",
  breedersModalChanged: false,
  breederId: 0,
  breederName: "",
  breederCity: "",
  breederGovernorateCity: "",
  cityId: 0,
  governorateId: 0,
};
export const BreedersModalSlice = createSlice({
  name: "breedersModal",
  initialState,
  reducers: {
    setSelectedBreeders: (state, action) => {
      state.breedersIds = action.payload.breedersIds;
      state.breedersText = action.payload.breedersText;
      state.breedersModalChanged = action.payload.isChanged;
    },
    setSelectedOneBreeders: (state, action) => {
      state.breederId = action.payload.breederId;
      state.breederName = action.payload.breederName;
      state.breedersModalChanged = action.payload.isChanged;
      state.breederCity = action.payload.breederCity;
      state.breederGovernorateCity = action.payload.breederGovernorateCity;
      state.cityId = action.payload.cityId;
      state.governorateId = action.payload.governorateId;
    },
  },
});
export const { setSelectedBreeders, setSelectedOneBreeders } =
  BreedersModalSlice.actions;

export default BreedersModalSlice.reducer;

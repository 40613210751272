import "./TotalCount.scss";
type TotalCountProps = {
  title: string;
  count: number;
};
export const TotalCount = (props: TotalCountProps) => {
  return (
    <div className="TotalCount col-start-1 flex justify-start items-center pr-8">
      <span className="totalCount">
        اجمالي عدد {props.title} ({props.count}){" "}
      </span>
    </div>
  );
};

import { useEffect, useState } from "react";
import "./AddFarm.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import arrow from "../../../Assets/Icons/arrow.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { DatePickerComponent } from "../../Missions/AddMission/SubComponents/DatePicker/DatePickerComponent";
import { HenType } from "../SubComponents/HenType";
import { BreedType } from "../SubComponents/BreedType";
import { VentilationType } from "../SubComponents/VentilationType";
import { Facilities } from "../SubComponents/Facilities";
import { Governorates } from "../SubComponents/Governorates";
import { Cities } from "../SubComponents/Cities";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import API, { URL as url } from "../../../Api";
import { useParams } from "react-router-dom";
import { FarmingTypes } from "../SubComponents/FarmingTypes";
import { Loading } from "../../Loading/Loading";
import axios from "axios";

export const AddFarm = () => {
  const addSchema = Yup.object().shape({
    farmArea: Yup.number().required("من فضلك قم بأدخال مساحة العنبر"),
    name: Yup.string().required("من فضلك قم بأدخال اسم للعنبر"),
    birdTypeId: Yup.string().required("من فضلك قم بأختيار نوع الداجن"),
    breedId: Yup.string().required("من فضلك قم بأختيار نوع السلالة"),
    farmTypeId: Yup.string().required("من فضلك قم بأختيار نوع التربية"),
    farmCapacityId: Yup.string().required("من فضلك قم بأختيار سعة العنبر"),
    ventilationTypeId: Yup.string().required("من فضلك قم بأختيار نوع التهوئة"),
    facilitiesId: Yup.string().required("من فضلك قم بأختيار المرافق"),
    farmLicense: Yup.string().required("من فضلك قم بأختيار الترخيص"),
    cityId: Yup.string().required("من فضلك قم بأختيار المدينة"),
    ownership: Yup.string().required("من فضلك قم بأختيار تبعية ملكية العنبر"),
  });

  const stateFromFarmsSlice = useSelector((state: any) => state.farms);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startRentDate, setRentStartDate] = useState(new Date());
  const [finishRentDate, setRentFinishDate] = useState(new Date());

  const handleChangeStartRentDate = (date: any) => {
    setRentStartDate(date);
  };

  const handleChangeFinishRentDate = (date: any) => {
    setRentFinishDate(date);
  };

  const { breederId } = useParams<{ breederId: string }>();
  const [addObject, setAddObject] = useState({
    name: "",
    farmArea: 0,
    farmTypeId: "",
    farmCapacityId: 0,
    ventilationTypeId: "",
    cityId: "",
    village: "",
    detailedDescription: "",
    userId: "",
  });
  const [file, setFile] = useState<File | null>(null);
  const [imageUploadWrapClass, setImageUploadWrapClass] =
    useState("image-upload-wrap");
  const [fileUploadContentVisible, setFileUploadContentVisible] =
    useState(false);
  const [showTypePopUp, setShowTypePopUp] = useState(false);
  const [showBreedTypePopUp, setShowBreedTypePopUp] = useState(false);
  const [showVentilationTypePopUp, setShowVentilationTypePopUp] =
    useState(false);
  const [showFacilitiesPopUp, setShowFacilitiesPopUp] = useState(false);
  const [showGovernoratePopUp, setShowGovernoratePopUp] = useState(false);
  const [showCityPopUp, setShowCityPopUp] = useState(false);
  const [ownerShipId, setOwnerShipId] = useState(0);
  const [ownerShipTitle, setOwnerShipTitle] = useState("اختر");
  const [licenseId, setLicenseId] = useState(0);
  const [farmSizeId, setFarmSizeId] = useState(0);
  const [farmSizeTxt, setFarmSizeTxt] = useState("اختر سعة العنبر");
  const [licenseTitle, setLicenseTitle] = useState("اختر الترخيص");
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setisActive] = useState(false);

  const [showFarmingTypePopUp, setShowFarmingTypePopUp] = useState(false);
  useEffect(() => {
    let mainHeaderName = "اضافة عنبر";
    dispatch(setMainHeaderName({ mainHeaderName }));
  }, []);

  const removeUpload = () => {
    setFile(null);
    setImageUploadWrapClass("image-upload-wrap");
    setFileUploadContentVisible(false);
  };
  const readURL = (input: any) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageUploadWrapClass("image-upload-wrap image-dropping");
        setFileUploadContentVisible(true);
        setFile(input.files[0]);
      };

      reader.readAsDataURL(input.files[0]);
    } else {
      removeUpload();
    }
  };

  const handleDragOver = () => {
    setImageUploadWrapClass("image-upload-wrap image-dropping");
  };

  const handleDragLeave = () => {
    setImageUploadWrapClass("image-upload-wrap");
  };

  const handleChangeOwnerShip = (id: number) => {
    setOwnerShipId(id);

    if (id === 1) {
      setOwnerShipTitle("ايجار");
    } else if (id === 2) {
      setOwnerShipTitle("تمليك");
    } else {
      setOwnerShipTitle("اختر");
    }
  };
  const handleChangeFarmSize = (id: number) => {
    setIsLoading(true);
    setFarmSizeId(id);
    switch (id) {
      case 1:
        setFarmSizeTxt("اققل من 1000");
        break;
      case 2:
        setFarmSizeTxt("اققل من 5000");
        break;
      case 3:
        setFarmSizeTxt("اققل من 10000");
        break;
      case 4:
        setFarmSizeTxt("اققل من 25000");
        break;
      case 5:
        setFarmSizeTxt("اققل من 50000");
        break;
      case 6:
        setFarmSizeTxt("اققل من 100000");
        break;
      case 7:
        setFarmSizeTxt("اققل من 200000");
        break;
      case 8:
        setFarmSizeTxt("اققل من 500000");
        break;

      default:
        setFarmSizeTxt("اختر سعة العنبر");

        break;
    }
    setIsLoading(false);
  };
  const handleChangeLicense = (id: number) => {
    if (id === 1) {
      setLicenseTitle("مرخص");
      setLicenseId(id);
    } else if (id === 2) {
      setLicenseTitle("غير مرخص");
      setLicenseId(id);
    } else {
      setLicenseTitle("اختر الترخيص");
      setLicenseId(id);
    }
  };

  const handleShowTypePopUp = () => {
    setShowTypePopUp(!showTypePopUp);
  };

  const handleBreedTypePopUp = () => {
    setShowBreedTypePopUp(!showBreedTypePopUp);
  };

  const handleVentilationTypePopUp = () => {
    setShowVentilationTypePopUp(!showVentilationTypePopUp);
  };

  const handleFacilitiesPopUp = () => {
    setShowFacilitiesPopUp(!showFacilitiesPopUp);
  };

  const handleGovernoratePopUp = () => {
    setShowGovernoratePopUp(!showGovernoratePopUp);
  };

  const handleCityPopUp = () => {
    setShowCityPopUp(!showCityPopUp);
  };
  const handleFarmingTypePopUp = () => {
    setShowFarmingTypePopUp(!showFarmingTypePopUp);
  };
  const handleChangeValues = (value: any, fieldName: string) => {
    // Update addObject with the new value
    setAddObject((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    console.log(addObject);
  };
  const validations = () => {
    setIsLoading(true);
    if (addObject.name === "") {
      toast.warn("من فضلك قم بأدخال اسم العنبر");
      setIsLoading(false);
    } else if (stateFromFarmsSlice.selectedFarmingTypeId === 0) {
      toast.warn("من فضلك قم بأدخال نوع التربية");
      setIsLoading(false);
    } else if (addObject.farmArea === 0) {
      toast.warn("من فضلك قم بأدخال مساحة العنبر");
      setIsLoading(false);
    } else if (stateFromFarmsSlice.selectedVentilationType === 0) {
      toast.warn("من فضلك قم بأختيار نوع التهوئة");
      setIsLoading(false);
    } else if (stateFromFarmsSlice.selectedCityId === 0) {
      toast.warn("من فضلك قم بأختيار المدينة");
      setIsLoading(false);
    } else if (stateFromFarmsSlice.selectedGovernorateId === 0) {
      toast.warn("من فضلك قم بأختيار المحافظة");
      setIsLoading(false);
    } else {
      confirmAddMission();
    }
  };
  const handleFarmSubmit = () => {
    validations();
  };
  const confirmAddMission = () => {
    setIsLoading(true);

    addObject.cityId = stateFromFarmsSlice.selectedCityId;
    addObject.ventilationTypeId = stateFromFarmsSlice.selectedVentilationType;

    addObject.userId = breederId === undefined ? "" : breederId?.toString();

    const data = {
      photo: file,
      userId: breederId === undefined ? "" : breederId?.toString(),
      name: addObject.name,
      farmArea: addObject.farmArea,
      cityId: addObject.cityId,
      village: addObject.village,
      detailedDescription: addObject.detailedDescription,
      farmTypeId: stateFromFarmsSlice.selectedFarmingTypeId,
      farmCapacityId: farmSizeId,
      ventilationTypeId: stateFromFarmsSlice.selectedVentilationType,
    };
    axios
      .post(`${url}farms`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          toast.success("تمت أضافة العنبر بنجاح");
          setIsLoading(false);
          navigate(-1);
        } else if (res.status === 400) {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        } else {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
        setIsLoading(false);
      });
  };

  return (
    <div className="AddFarm">
      {showTypePopUp === true ? <HenType isEdit={false} /> : null}
      {showBreedTypePopUp === true ? <BreedType isEdit={false} /> : null}
      {showVentilationTypePopUp === true ? (
        <VentilationType isEdit={false} />
      ) : null}
      {showFacilitiesPopUp === true ? <Facilities isEdit={false} /> : null}
      {showGovernoratePopUp === true ? <Governorates isEdit={false} /> : null}
      {showCityPopUp === true ? <Cities isEdit={false} /> : null}
      {showFarmingTypePopUp === true ? <FarmingTypes isEdit={false} /> : null}
      {isLoading === true ? <Loading /> : null}
      <Formik
        onSubmit={() => handleFarmSubmit()}
        initialValues={addObject}
        validationSchema={null}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="farm-info-section mt-4 grid grid-cols-2 gap-4">
                {/* Header */}
                <div className="col-start-1 pr-4 pt-4 flex items-center ">
                  <div className="farm-info-title w-1/2">معلومات العنبر</div>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                {/* الأسم  */}
                <div className="col-start-1 pr-4  mt-4">
                  <p className="title">الأسم</p>
                </div>

                {/* مساحة العنبر     */}
                <div className="col-start-2 pr-4 mt-4">
                  <p className="title">مساحة العنبر</p>
                </div>

                {/* :input الأسم  */}
                <div className="col-start-1 pr-4 ">
                  <div>
                    <input
                      type="text"
                      placeholder="الاسم"
                      className="mission-address-input"
                      id="name"
                      name="name"
                      onChange={(e) =>
                        handleChangeValues(e.target.value, "name")
                      }
                      value={addObject.name}
                    />
                  </div>
                </div>

                {/* مساحة العنبر  input   */}
                <div className="col-start-2 pr-4  ">
                  <input
                    type="number"
                    placeholder="مساحة العنبر"
                    className="mission-address-input"
                    id="farmArea"
                    name="farmArea"
                    onChange={(e) =>
                      handleChangeValues(e.target.value, "farmArea")
                    }
                  />
                </div>

                {/* نوع التربية  */}
                <div className="col-start-1 pr-4  mt-4">
                  <p className="title">نوع التربية</p>
                </div>

                {/* سعة العنبر */}
                <div className="col-start-2 pr-4 mt-4">
                  <p className="title">سعة العنبر</p>
                </div>
                {/* :input نوع التربية  */}
                <div className="col-start-1 pr-4 ">
                  <label htmlFor="modal-14">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleFarmingTypePopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedFarmingTypeId === 0
                            ? "اختر نوع التربية"
                            : stateFromFarmsSlice.selectedFarmingTypeName
                        }
                        disabled
                        id="ventilationTypeId"
                        name="ventilationTypeId"
                      />

                      <div className="arrow">
                        <img
                          src={arrow}
                          style={{ height: "16px" }}
                          alt="arrow"
                        />
                      </div>
                    </div>
                  </label>
                </div>
                {/* سعة العنبر  input   */}
                <div className="col-start-2 pr-4  ">
                  <div className="dropdown-container">
                    <div className="dropdown w-full">
                      <label tabIndex={0}>
                        <div className="flex justify-between select-mission-container">
                          <input
                            className="select-mission-type pr-4"
                            value={farmSizeTxt}
                            disabled
                            id="facilitiesId"
                            name="facilitiesId"
                          />

                          <div className="arrow">
                            <img
                              src={arrow}
                              style={{ height: "16px" }}
                              alt="arrow"
                            />
                          </div>
                        </div>
                      </label>
                      <div className="dropdown-menu w-full bg-white">
                        <p
                          className="dropdown-item text-sm text-right"
                          onClick={() => handleChangeFarmSize(1)}
                        >
                          اققل من 1000
                        </p>
                        <p
                          className="dropdown-item text-sm text-right"
                          onClick={() => handleChangeFarmSize(2)}
                        >
                          اققل من5000
                        </p>

                        <p
                          className="dropdown-item text-sm text-right"
                          onClick={() => handleChangeFarmSize(3)}
                        >
                          اققل من 10000
                        </p>

                        <p
                          className="dropdown-item text-sm text-right"
                          onClick={() => handleChangeFarmSize(4)}
                        >
                          اققل من 25000
                        </p>

                        <p
                          className="dropdown-item text-sm text-right"
                          onClick={() => handleChangeFarmSize(5)}
                        >
                          اققل من 50000
                        </p>

                        <p
                          className="dropdown-item text-sm text-right"
                          onClick={() => handleChangeFarmSize(6)}
                        >
                          اققل من 100000
                        </p>
                        <p
                          className="dropdown-item text-sm text-right"
                          onClick={() => handleChangeFarmSize(7)}
                        >
                          اققل من 200000
                        </p>

                        <p
                          className="dropdown-item text-sm text-right"
                          onClick={() => handleChangeFarmSize(8)}
                        >
                          اققل من 500000
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* نوع التهوئة  */}
                <div className="col-start-1 pr-4  mt-4">
                  <p className="title">نوع التهوئة</p>
                </div>

                {/* :input نوع التهوئة  */}
                <div className="col-start-1 pr-4 ">
                  <label
                    htmlFor="modal-10"
                    onClick={() => handleVentilationTypePopUp()}
                  >
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleVentilationTypePopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        onClick={() => handleVentilationTypePopUp()}
                        value={
                          stateFromFarmsSlice.selectedVentilationType === 0
                            ? "اختر نوع التهوئة"
                            : stateFromFarmsSlice.selectedVentilationName
                        }
                        disabled
                        id="ventilationTypeId"
                        name="ventilationTypeId"
                      />

                      <div className="arrow">
                        <img
                          src={arrow}
                          style={{ height: "16px" }}
                          alt="arrow"
                        />
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              {/* التراخيص */}
              <div className="license-section mt-4 grid grid-cols-2 gap-4">
                {/* Header */}
                <div className="col-start-1 pr-4 pt-4 flex items-center ">
                  <div className="farm-info-title w-1/2">ترخيص العنبر</div>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>
                {/* Upload Image */}
                <div className="col-span-full flex gap-8 pr-4 items-center">
                  <div className={`${imageUploadWrapClass}`}>
                    <input
                      onDragOver={() => handleDragOver()}
                      onDragLeave={() => handleDragLeave()}
                      className="file-upload-input"
                      type="file"
                      onChange={(e) => readURL(e.target)}
                      accept="image/*"
                    />
                    <div className="drag-text">
                      <h3 className="add-photo-txt">ارفق صورة العنبر </h3>
                      <p className="">
                        دعم جميع الملفات، الحجم الأقصى 60 ميجابايت
                      </p>
                    </div>
                  </div>
                  <div onClick={() => removeUpload()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                        stroke="#EB001B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="col-span-full">
                  {fileUploadContentVisible && file && (
                    <div className="row mb-3">
                      <div className="file-upload-content">
                        <img
                          className="file-upload-image"
                          src={URL.createObjectURL(file)}
                          alt="your"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* العنوان */}
              <div className="location-section mt-4 grid grid-cols-2 gap-4">
                {/* Header */}
                <div className="col-start-1 pr-4 pt-4 flex items-center ">
                  <div className="farm-info-title w-1/2">العنوان</div>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                {/*  المحافظة */}
                <div className="col-start-1 pr-4 mt-4">
                  <p className="title">المحافظة</p>
                </div>

                {/*  المدينة */}
                <div className="col-start-2 pr-4 mt-4">
                  <p className="title">المدينة</p>
                </div>

                {/* المحافظة  input   */}
                <div className="col-start-1 pr-4 ">
                  <label htmlFor="modal-12">
                    <div className="flex justify-between select-mission-container">
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedGovernorateId === 0
                            ? "اختر المحافظة"
                            : stateFromFarmsSlice.selectedGovernorateName
                        }
                        disabled
                        id="govId"
                        name="govId"
                      />

                      <div
                        className="arrow"
                        onClick={() => handleGovernoratePopUp()}
                      >
                        <img
                          src={arrow}
                          style={{ height: "16px" }}
                          alt="arrow"
                        />
                      </div>
                    </div>
                  </label>
                </div>
                {/*  المدينة :input */}
                <div className="col-start-2 pr-4 ">
                  <label htmlFor="modal-13">
                    <div className="flex justify-between select-mission-container">
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedCityId === 0
                            ? "اختر المدينة"
                            : stateFromFarmsSlice.selectedCityName
                        }
                        disabled
                        id="cityId"
                        name="cityId"
                      />

                      <div className="arrow" onClick={() => handleCityPopUp()}>
                        <img
                          src={arrow}
                          style={{ height: "16px" }}
                          alt="arrow"
                        />
                      </div>
                    </div>
                  </label>
                </div>

                {/*  حي / قرية */}
                <div className="col-start-1 pr-4 mt-4">
                  <p className="title">حي / قرية</p>
                </div>

                {/*  الوصف التفصيلي */}
                <div className="col-start-2 pr-4 mt-4">
                  <p className="title">الوصف التفصيلي</p>
                </div>

                {/* حي / قرية  input   */}
                <div className="col-start-1 pr-4 ">
                  <input
                    type="text"
                    placeholder="القرية"
                    className="mission-address-input"
                    id="village"
                    name="village"
                    onChange={(e) =>
                      handleChangeValues(e.target.value, "village")
                    }
                  />
                </div>
                {/*  الوصف التفصيلي :input */}
                <div className="col-start-2 pr-4 ">
                  <textarea
                    placeholder="الوصف التفصيلي"
                    className="mission-address-input"
                    id="detailedDescription"
                    name="detailedDescription"
                    onChange={(e) =>
                      handleChangeValues(e.target.value, "detailedDescription")
                    }
                  />
                </div>
              </div>

              {/* Fourth Section [Done Or cancel] */}
              <div className="add-actions p-5">
                <div className="grid grid-cols-2">
                  <div className="col-start-1">
                    <div className="flex gap-4">
                      <button className="add-btn" type="submit">
                        اضافة
                      </button>
                      <div className="cancel-btn" onClick={() => navigate(-1)}>
                        الغاء
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

import { useState } from "react";
import "./BreederHeader.scss";
import { useLocation, useNavigate } from "react-router";
import { Loading } from "../../Loading/Loading";
import API from "../../../Api";
import { useDispatch } from "react-redux";
import { setBreadersSearchResult } from "../../../redux/Slices/FarmSlice";
import { toast } from "react-toastify";

export const BreederHeader = () => {
  const [activeElement, setActiveElement] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [usersIds, setUsersIds] = useState<number[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleActiveElement = (id: number) => {
    setIsLoading(true);
    setActiveElement(id);
    if (id === 2) {
      setIsLoading(false);
      navigate("/Breeders");
    } else {
      setIsLoading(false);
      navigate("/Farmers");
    }
  };
  const showFilterModal = () => {};

  const handleSelectRange = (userId: number) => {
    let copy = [...usersIds];
    const isSelectedBefore = copy.includes(userId);

    if (isSelectedBefore) {
      const indexToRemove = copy.indexOf(userId);

      // Remove the ID if it exists in the array
      if (indexToRemove !== -1) {
        copy.splice(indexToRemove, 1);
        setUsersIds(copy);
      } else {
        copy.push(userId);
        setUsersIds(copy);
      }
    } else {
      copy.push(userId);
      setUsersIds(copy);
    }
  };

  const handleSearchInput = (value: string) => {
    setIsLoading(true);
    if (value === "") {
      const searchResult: any[] = [];
      let isFiltered = false;
      dispatch(setBreadersSearchResult({ searchResult, isFiltered }));
      setSearchResults([]);
    } else {
      API.get(`breeders?mobile=${value}`).then((res) => {
        if (res.status === 200) {
          const characterCount: number = value.length;

          let searchResult = res.data.items;
          let isFiltered = true;
          let totalCount = res.data.totalCount;
          if (characterCount === 11) {
            if (res.data.items.length === 0) {
              toast.warn("لا يوجد بيانات لهذا الرقم");
            }
          }
          dispatch(
            setBreadersSearchResult({ searchResult, isFiltered, totalCount })
          );

          setSearchResults(res.data.items);
          setIsLoading(false);
        }
      });
    }
  };
  return (
    <div className="grid grid-cols-2 xs:grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 p-4  BreederHeader">
      <div className="col-start-1 flex  sm:col-start-1">
        <div
          onClick={() => handleActiveElement(2)}
          className={
            location.pathname === "/Breeders"
              ? "main-element-active"
              : "main-element"
          }
        >
          المربيين
        </div>
      </div>
      <div className="col-start-2  flex justify-end">
        <div className="flex" onClick={() => showFilterModal()}>
          {/* Filter Button */}
          <div className="filter-section flex">
            <div className="popover ">
              <label className="popover-trigger my-2" tabIndex={0}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M4.58342 4.16669H15.4168C15.5368 4.20878 15.6456 4.27778 15.7348 4.36841C15.824 4.45903 15.8913 4.56888 15.9315 4.68954C15.9718 4.81019 15.9838 4.93845 15.9668 5.06449C15.9498 5.19053 15.9042 5.31101 15.8334 5.41669L11.6668 10V15.8334L8.33342 13.3334V10L4.16676 5.41669C4.096 5.31101 4.05037 5.19053 4.03336 5.06449C4.01636 4.93845 4.02842 4.81019 4.06864 4.68954C4.10886 4.56888 4.17616 4.45903 4.26539 4.36841C4.35462 4.27778 4.46341 4.20878 4.58342 4.16669Z"
                    stroke="#64748B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                الفلتر
              </label>
              <div className="popover-content popover-bottom-left" tabIndex={0}>
                <div className="grid">
                  <div className="form-control relative">
                    <input
                      type="search"
                      className="input input-lg max-w-full search-input bg-white"
                      placeholder="ابحث برقم هاتف المربي"
                      onChange={(e) => handleSearchInput(e.target.value)}
                    />
                    {/* Search Icon */}
                    <span className="absolute inset-y-0 right-4 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.5001 17.5L14.1667 14.1667"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  {/* <div className="grid text-right gap-4 mt-4 overflow-y-auto">
                    {isLoading === true || searchResults.length === 0 ? (
                      <>
                        <div className="skeleton h-5 rounded-md"></div>
                        <div className="skeleton h-5 rounded-md"></div>
                        <div className="skeleton h-5 rounded-md"></div>
                      </>
                    ) : (
                      <>
                        {searchResults.map((item: any) => {
                          return (
                            <div className="flex items-center gap-4">
                              <input
                                type="checkbox"
                                className="checkbox-success checkbox"
                                onChange={() => handleSelectRange(item.id)}
                              />

                              <span className="breeder_name">{item.name}</span>
                            </div>
                          );
                        })}
                        <button className="submit-filter">تأكيد</button>
                      </>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* Add Button */}
          {location.pathname === "/Breeders" ? (
            <>
              <div
                className="add-farm-btn"
                onClick={() => navigate("/Breeders/Add")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M10.5049 1.02539C6.02189 1.02539 2.375 4.67228 2.375 9.15527C2.375 13.6383 6.02189 17.2852 10.5049 17.2852C14.9879 17.2852 18.6348 13.6383 18.6348 9.15527C18.6348 4.67228 14.9879 1.02539 10.5049 1.02539ZM14.0617 9.83272H11.1823V12.7121C11.1823 13.0861 10.8789 13.3895 10.5049 13.3895C10.1309 13.3895 9.82743 13.0861 9.82743 12.7121V9.83272H6.94806C6.57404 9.83272 6.27061 9.52929 6.27061 9.15527C6.27061 8.78126 6.57404 8.47782 6.94806 8.47782H9.82743V5.59845C9.82743 5.22443 10.1309 4.921 10.5049 4.921C10.8789 4.921 11.1823 5.22443 11.1823 5.59845V8.47782H14.0617C14.4357 8.47782 14.7392 8.78126 14.7392 9.15527C14.7392 9.52929 14.4357 9.83272 14.0617 9.83272Z"
                    fill="white"
                  />
                </svg>
                أضافة مربي
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

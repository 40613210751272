import "./DepitTransactions.scss";
import { useEffect, useState } from "react";
import API from "../../../Api";
import moment from "moment";
import { Loading } from "../../Loading/Loading";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
export const DepitTransactions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const stateFromWalletsSlice = useSelector((state: any) => state.wallets);
  const dispatch = useDispatch();
  useEffect(() => {
    let mainHeaderName = "ادارة المحافظ";
    dispatch(setMainHeaderName({ mainHeaderName }));
    setIsLoading(true);
    if (
      stateFromWalletsSlice.startDate === null ||
      stateFromWalletsSlice.finishDate === null
    ) {
      let type = encodeURIComponent("مدينة");

      API.get(`dashboard/wallets/transactions?type=${type}`).then((res) => {
        if (res) {
          setTransactions(res.data.items);
          setIsLoading(false);
        } else {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        }
      });
    } else {
      let type = "مدينة";
      API.get(
        `dashboard/wallets/transactions?type=${type}& date_from=${stateFromWalletsSlice.startDate}&date_to=${stateFromWalletsSlice.finishDate}`
      ).then((res) => {
        if (res) {
          setTransactions(res.data.items);
          setIsLoading(false);
        } else {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        }
      });
    }
  }, [stateFromWalletsSlice]);
  return (
    <div className="DepitTransactions grid grid-cols-2">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
      {/* Reports */}
      <div className="col-start-1">
        <div className="flex gap-4 mb-4 pr-10">
          <div className="report p-8 ">
            <div className="flex items-center gap-4">
              <h3 className="amount">١٣٠،٠٠٠</h3>
              <span className="coin">جنيه</span>
            </div>
            <div className="report-title">إجمالي المديونية</div>
          </div>
          <div className="report p-8 ">
            <div className="flex items-center gap-4">
              <h3 className="amount">١٣٠،٠٠٠</h3>
              <span className="coin">جنيه</span>
            </div>
            <div className="report-title">إجمالي المبالغ المدفوعة</div>
          </div>
        </div>
      </div>
      <div className="col-span-full">
        <div className="grid grid-cols-6">
          <div className="bannerText">التاريخ</div>
          <div className="bannerText">العميل</div>
          <div className="bannerText">مبلغ دائن</div>
          <div className="bannerText">مبلغ مدين</div>
          <div className="bannerText">الرصيد</div>
          <div className="bannerText">البيان</div>
        </div>
      </div>

      {transactions.map((item: any) => {
        return (
          <div className="col-span-full data-row rounded-full mt-4 mb-4">
            <div className="grid grid-cols-6">
              <div className="breeder-name cursor-pointer">
                {moment(item.created_at).format("YYYY/MM/DD")}
              </div>
              {item.user != null ? (
                <Link to={`/Users/Profile/${item.user.id}`}>
                  <div className="breeder-name cursor-pointer">
                    {item.user.name}
                  </div>
                </Link>
              ) : (
                <>
                  <div className="breeder-name cursor-pointer"></div>
                </>
              )}
              {/* مبلغ دائن */}
              <div className="breeder-name cursor-pointer">
                {" "}
                {item.type === "مدينة" ? item.amount : "٠"} جم
              </div>
              {/* مبلغ مدين */}
              <div className="breeder-name cursor-pointer">
                {" "}
                {item.type === "دائنة" ? item.amount : "٠"} جم
              </div>
              <div className="breeder-name cursor-pointer">
                {item.balance} جم
              </div>
              <div className="breeder-name cursor-pointer">
                قيمة مهمة <span className="number">رقم</span>{" "}
                {item.mission !== null ? item.mission.id : "٠"}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

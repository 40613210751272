import "./Academy_Courses.scss";
import courseCover from "../../../Assets/Icons/Chicken.jpg";
import userImg from "../../../Assets/Icons/UserTwo.svg";
import { useEffect } from "react";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const AcademyCourses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let mainHeaderName = "الأكاديمية";
    dispatch(setMainHeaderName({ mainHeaderName }));
  }, []);
  return (
    <div className="Academy_Courses pt-5 pr-5 pl-5">
      <div className="grid grid-cols-3 gap-6 mt-5 pr-4 pl-4">
        <div className="mt-4 courses-section">
          <div className="course-cover relative">
            <div className="status flex absolute bottom-1 left-1  p-2">
              كورسات امراض الدواجن
            </div>
            <div className="three_dots flex absolute top-4 left-3  p-2">
              <div className="popover" style={{ backgroundColor: "white" }}>
                <svg
                  className="popover-trigger mx-2 arrow"
                  tabIndex={0}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                    fill="#A7AEC1"
                  />
                </svg>
                <div
                  className="popover-content popover-right-top right-auto"
                  tabIndex={0}
                >
                  <ul>
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      onClick={() => navigate(`/DaginAcademy/Course/:1`)}
                    >
                      تفاصيل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      onClick={() => navigate(`/DaginAcademy/Course/Edit/:1`)}
                    >
                      تعديل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                  </ul>
                </div>
              </div>
            </div>
            <img src={courseCover} alt="course-cover" className="course-img" />
          </div>
          <h3 className="course-title">مقدمة اساسية عن تربية الدواجن</h3>
          <div className="flex w-full justify-between">
            <div className="flex gap-4">
              <div>
                <img src={userImg} alt="user-img" />
              </div>
              <span className="user-name">احمد محسن</span>
            </div>
            <div className="flex items-center gap-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 2.5L12.0279 7.20889L17.1329 7.68237L13.2811 11.0661L14.4084 16.0676L10 13.45L5.59161 16.0676L6.71886 11.0661L2.86708 7.68237L7.97214 7.20889L10 2.5Z"
                    fill="#FFBB54"
                  />
                </svg>
              </span>
              <span className="number">4.5</span>
            </div>
          </div>
          <div className="flex gap-4 justify-start">
            {/* عدد الطلاب */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.00033 8.00004C9.84127 8.00004 11.3337 6.50766 11.3337 4.66671C11.3337 2.82576 9.84127 1.33337 8.00033 1.33337C6.15938 1.33337 4.66699 2.82576 4.66699 4.66671C4.66699 6.50766 6.15938 8.00004 8.00033 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">500 طالب</p>
            </div>
            {/* الوحدات */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.99967 14.6667H9.99967C13.333 14.6667 14.6663 13.3334 14.6663 10V6.00004C14.6663 2.66671 13.333 1.33337 9.99967 1.33337H5.99967C2.66634 1.33337 1.33301 2.66671 1.33301 6.00004V10C1.33301 13.3334 2.66634 14.6667 5.99967 14.6667Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 6H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 10H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">5 وحدات</p>
            </div>
            {/* الساعة */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M14.6663 8.00004C14.6663 11.68 11.6797 14.6667 7.99967 14.6667C4.31967 14.6667 1.33301 11.68 1.33301 8.00004C1.33301 4.32004 4.31967 1.33337 7.99967 1.33337C11.6797 1.33337 14.6663 4.32004 14.6663 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4729 10.12L8.40626 8.88671C8.04626 8.67338 7.75293 8.16005 7.75293 7.74005V5.00671"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">ساعة و 30 د</p>
            </div>
          </div>
        </div>
        <div className="mt-4 courses-section">
          <div className="course-cover relative">
            <div className="status flex absolute bottom-1 left-1  p-2">
              كورسات امراض الدواجن
            </div>
            <div className="three_dots flex absolute top-4 left-3  p-2">
              <div className="popover" style={{ backgroundColor: "white" }}>
                <svg
                  className="popover-trigger mx-2 arrow"
                  tabIndex={0}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                    fill="#A7AEC1"
                  />
                </svg>
                <div
                  className="popover-content popover-right-top right-auto"
                  tabIndex={0}
                >
                  <ul>
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      // onClick={() => navigate(item.id)}
                    >
                      تفاصيل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      // onClick={() => navigate(`/Farmers/${item.id}/Edit`)}
                    >
                      تعديل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                  </ul>
                </div>
              </div>
            </div>
            <img src={courseCover} alt="course-cover" className="course-img" />
          </div>
          <h3 className="course-title">مقدمة اساسية عن تربية الدواجن</h3>
          <div className="flex w-full justify-between">
            <div className="flex gap-4">
              <div>
                <img src={userImg} alt="user-img" />
              </div>
              <span className="user-name">احمد محسن</span>
            </div>
            <div className="flex items-center gap-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 2.5L12.0279 7.20889L17.1329 7.68237L13.2811 11.0661L14.4084 16.0676L10 13.45L5.59161 16.0676L6.71886 11.0661L2.86708 7.68237L7.97214 7.20889L10 2.5Z"
                    fill="#FFBB54"
                  />
                </svg>
              </span>
              <span className="number">4.5</span>
            </div>
          </div>
          <div className="flex gap-4 justify-start">
            {/* عدد الطلاب */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.00033 8.00004C9.84127 8.00004 11.3337 6.50766 11.3337 4.66671C11.3337 2.82576 9.84127 1.33337 8.00033 1.33337C6.15938 1.33337 4.66699 2.82576 4.66699 4.66671C4.66699 6.50766 6.15938 8.00004 8.00033 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">500 طالب</p>
            </div>
            {/* الوحدات */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.99967 14.6667H9.99967C13.333 14.6667 14.6663 13.3334 14.6663 10V6.00004C14.6663 2.66671 13.333 1.33337 9.99967 1.33337H5.99967C2.66634 1.33337 1.33301 2.66671 1.33301 6.00004V10C1.33301 13.3334 2.66634 14.6667 5.99967 14.6667Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 6H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 10H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">5 وحدات</p>
            </div>
            {/* الساعة */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M14.6663 8.00004C14.6663 11.68 11.6797 14.6667 7.99967 14.6667C4.31967 14.6667 1.33301 11.68 1.33301 8.00004C1.33301 4.32004 4.31967 1.33337 7.99967 1.33337C11.6797 1.33337 14.6663 4.32004 14.6663 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4729 10.12L8.40626 8.88671C8.04626 8.67338 7.75293 8.16005 7.75293 7.74005V5.00671"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">ساعة و 30 د</p>
            </div>
          </div>
        </div>
        <div className="mt-4 courses-section">
          <div className="course-cover relative">
            <div className="status flex absolute bottom-1 left-1  p-2">
              كورسات امراض الدواجن
            </div>
            <div className="three_dots flex absolute top-4 left-3  p-2">
              <div className="popover" style={{ backgroundColor: "white" }}>
                <svg
                  className="popover-trigger mx-2 arrow"
                  tabIndex={0}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                    fill="#A7AEC1"
                  />
                </svg>
                <div
                  className="popover-content popover-right-top right-auto"
                  tabIndex={0}
                >
                  <ul>
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      // onClick={() => navigate(item.id)}
                    >
                      تفاصيل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      // onClick={() => navigate(`/Farmers/${item.id}/Edit`)}
                    >
                      تعديل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                  </ul>
                </div>
              </div>
            </div>
            <img src={courseCover} alt="course-cover" className="course-img" />
          </div>
          <h3 className="course-title">مقدمة اساسية عن تربية الدواجن</h3>
          <div className="flex w-full justify-between">
            <div className="flex gap-4">
              <div>
                <img src={userImg} alt="user-img" />
              </div>
              <span className="user-name">احمد محسن</span>
            </div>
            <div className="flex items-center gap-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 2.5L12.0279 7.20889L17.1329 7.68237L13.2811 11.0661L14.4084 16.0676L10 13.45L5.59161 16.0676L6.71886 11.0661L2.86708 7.68237L7.97214 7.20889L10 2.5Z"
                    fill="#FFBB54"
                  />
                </svg>
              </span>
              <span className="number">4.5</span>
            </div>
          </div>
          <div className="flex gap-4 justify-start">
            {/* عدد الطلاب */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.00033 8.00004C9.84127 8.00004 11.3337 6.50766 11.3337 4.66671C11.3337 2.82576 9.84127 1.33337 8.00033 1.33337C6.15938 1.33337 4.66699 2.82576 4.66699 4.66671C4.66699 6.50766 6.15938 8.00004 8.00033 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">500 طالب</p>
            </div>
            {/* الوحدات */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.99967 14.6667H9.99967C13.333 14.6667 14.6663 13.3334 14.6663 10V6.00004C14.6663 2.66671 13.333 1.33337 9.99967 1.33337H5.99967C2.66634 1.33337 1.33301 2.66671 1.33301 6.00004V10C1.33301 13.3334 2.66634 14.6667 5.99967 14.6667Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 6H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 10H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">5 وحدات</p>
            </div>
            {/* الساعة */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M14.6663 8.00004C14.6663 11.68 11.6797 14.6667 7.99967 14.6667C4.31967 14.6667 1.33301 11.68 1.33301 8.00004C1.33301 4.32004 4.31967 1.33337 7.99967 1.33337C11.6797 1.33337 14.6663 4.32004 14.6663 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4729 10.12L8.40626 8.88671C8.04626 8.67338 7.75293 8.16005 7.75293 7.74005V5.00671"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">ساعة و 30 د</p>
            </div>
          </div>
        </div>
        <div className="mt-4 courses-section">
          <div className="course-cover relative">
            <div className="status flex absolute bottom-1 left-1  p-2">
              كورسات امراض الدواجن
            </div>
            <div className="three_dots flex absolute top-4 left-3  p-2">
              <div className="popover" style={{ backgroundColor: "white" }}>
                <svg
                  className="popover-trigger mx-2 arrow"
                  tabIndex={0}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                    fill="#A7AEC1"
                  />
                </svg>
                <div
                  className="popover-content popover-right-top right-auto"
                  tabIndex={0}
                >
                  <ul>
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      // onClick={() => navigate(item.id)}
                    >
                      تفاصيل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      // onClick={() => navigate(`/Farmers/${item.id}/Edit`)}
                    >
                      تعديل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                  </ul>
                </div>
              </div>
            </div>
            <img src={courseCover} alt="course-cover" className="course-img" />
          </div>
          <h3 className="course-title">مقدمة اساسية عن تربية الدواجن</h3>
          <div className="flex w-full justify-between">
            <div className="flex gap-4">
              <div>
                <img src={userImg} alt="user-img" />
              </div>
              <span className="user-name">احمد محسن</span>
            </div>
            <div className="flex items-center gap-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 2.5L12.0279 7.20889L17.1329 7.68237L13.2811 11.0661L14.4084 16.0676L10 13.45L5.59161 16.0676L6.71886 11.0661L2.86708 7.68237L7.97214 7.20889L10 2.5Z"
                    fill="#FFBB54"
                  />
                </svg>
              </span>
              <span className="number">4.5</span>
            </div>
          </div>
          <div className="flex gap-4 justify-start">
            {/* عدد الطلاب */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.00033 8.00004C9.84127 8.00004 11.3337 6.50766 11.3337 4.66671C11.3337 2.82576 9.84127 1.33337 8.00033 1.33337C6.15938 1.33337 4.66699 2.82576 4.66699 4.66671C4.66699 6.50766 6.15938 8.00004 8.00033 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">500 طالب</p>
            </div>
            {/* الوحدات */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.99967 14.6667H9.99967C13.333 14.6667 14.6663 13.3334 14.6663 10V6.00004C14.6663 2.66671 13.333 1.33337 9.99967 1.33337H5.99967C2.66634 1.33337 1.33301 2.66671 1.33301 6.00004V10C1.33301 13.3334 2.66634 14.6667 5.99967 14.6667Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 6H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 10H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">5 وحدات</p>
            </div>
            {/* الساعة */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M14.6663 8.00004C14.6663 11.68 11.6797 14.6667 7.99967 14.6667C4.31967 14.6667 1.33301 11.68 1.33301 8.00004C1.33301 4.32004 4.31967 1.33337 7.99967 1.33337C11.6797 1.33337 14.6663 4.32004 14.6663 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4729 10.12L8.40626 8.88671C8.04626 8.67338 7.75293 8.16005 7.75293 7.74005V5.00671"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">ساعة و 30 د</p>
            </div>
          </div>
        </div>
        <div className="mt-4 courses-section">
          <div className="course-cover relative">
            <div className="status flex absolute bottom-1 left-1  p-2">
              كورسات امراض الدواجن
            </div>
            <div className="three_dots flex absolute top-4 left-3  p-2">
              <div className="popover" style={{ backgroundColor: "white" }}>
                <svg
                  className="popover-trigger mx-2 arrow"
                  tabIndex={0}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                    fill="#A7AEC1"
                  />
                </svg>
                <div
                  className="popover-content popover-right-top right-auto"
                  tabIndex={0}
                >
                  <ul>
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      // onClick={() => navigate(item.id)}
                    >
                      تفاصيل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      // onClick={() => navigate(`/Farmers/${item.id}/Edit`)}
                    >
                      تعديل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                  </ul>
                </div>
              </div>
            </div>
            <img src={courseCover} alt="course-cover" className="course-img" />
          </div>
          <h3 className="course-title">مقدمة اساسية عن تربية الدواجن</h3>
          <div className="flex w-full justify-between">
            <div className="flex gap-4">
              <div>
                <img src={userImg} alt="user-img" />
              </div>
              <span className="user-name">احمد محسن</span>
            </div>
            <div className="flex items-center gap-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 2.5L12.0279 7.20889L17.1329 7.68237L13.2811 11.0661L14.4084 16.0676L10 13.45L5.59161 16.0676L6.71886 11.0661L2.86708 7.68237L7.97214 7.20889L10 2.5Z"
                    fill="#FFBB54"
                  />
                </svg>
              </span>
              <span className="number">4.5</span>
            </div>
          </div>
          <div className="flex gap-4 justify-start">
            {/* عدد الطلاب */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.00033 8.00004C9.84127 8.00004 11.3337 6.50766 11.3337 4.66671C11.3337 2.82576 9.84127 1.33337 8.00033 1.33337C6.15938 1.33337 4.66699 2.82576 4.66699 4.66671C4.66699 6.50766 6.15938 8.00004 8.00033 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">500 طالب</p>
            </div>
            {/* الوحدات */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.99967 14.6667H9.99967C13.333 14.6667 14.6663 13.3334 14.6663 10V6.00004C14.6663 2.66671 13.333 1.33337 9.99967 1.33337H5.99967C2.66634 1.33337 1.33301 2.66671 1.33301 6.00004V10C1.33301 13.3334 2.66634 14.6667 5.99967 14.6667Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 6H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 10H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">5 وحدات</p>
            </div>
            {/* الساعة */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M14.6663 8.00004C14.6663 11.68 11.6797 14.6667 7.99967 14.6667C4.31967 14.6667 1.33301 11.68 1.33301 8.00004C1.33301 4.32004 4.31967 1.33337 7.99967 1.33337C11.6797 1.33337 14.6663 4.32004 14.6663 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4729 10.12L8.40626 8.88671C8.04626 8.67338 7.75293 8.16005 7.75293 7.74005V5.00671"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">ساعة و 30 د</p>
            </div>
          </div>
        </div>
        <div className="mt-4 courses-section">
          <div className="course-cover relative">
            <div className="status flex absolute bottom-1 left-1  p-2">
              كورسات امراض الدواجن
            </div>
            <div className="three_dots flex absolute top-4 left-3  p-2">
              <div className="popover" style={{ backgroundColor: "white" }}>
                <svg
                  className="popover-trigger mx-2 arrow"
                  tabIndex={0}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                    fill="#A7AEC1"
                  />
                </svg>
                <div
                  className="popover-content popover-right-top right-auto"
                  tabIndex={0}
                >
                  <ul>
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      // onClick={() => navigate(item.id)}
                    >
                      تفاصيل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      // onClick={() => navigate(`/Farmers/${item.id}/Edit`)}
                    >
                      تعديل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                  </ul>
                </div>
              </div>
            </div>
            <img src={courseCover} alt="course-cover" className="course-img" />
          </div>
          <h3 className="course-title">مقدمة اساسية عن تربية الدواجن</h3>
          <div className="flex w-full justify-between">
            <div className="flex gap-4">
              <div>
                <img src={userImg} alt="user-img" />
              </div>
              <span className="user-name">احمد محسن</span>
            </div>
            <div className="flex items-center gap-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 2.5L12.0279 7.20889L17.1329 7.68237L13.2811 11.0661L14.4084 16.0676L10 13.45L5.59161 16.0676L6.71886 11.0661L2.86708 7.68237L7.97214 7.20889L10 2.5Z"
                    fill="#FFBB54"
                  />
                </svg>
              </span>
              <span className="number">4.5</span>
            </div>
          </div>
          <div className="flex gap-4 justify-start">
            {/* عدد الطلاب */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.00033 8.00004C9.84127 8.00004 11.3337 6.50766 11.3337 4.66671C11.3337 2.82576 9.84127 1.33337 8.00033 1.33337C6.15938 1.33337 4.66699 2.82576 4.66699 4.66671C4.66699 6.50766 6.15938 8.00004 8.00033 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">500 طالب</p>
            </div>
            {/* الوحدات */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.99967 14.6667H9.99967C13.333 14.6667 14.6663 13.3334 14.6663 10V6.00004C14.6663 2.66671 13.333 1.33337 9.99967 1.33337H5.99967C2.66634 1.33337 1.33301 2.66671 1.33301 6.00004V10C1.33301 13.3334 2.66634 14.6667 5.99967 14.6667Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 6H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 10H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">5 وحدات</p>
            </div>
            {/* الساعة */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M14.6663 8.00004C14.6663 11.68 11.6797 14.6667 7.99967 14.6667C4.31967 14.6667 1.33301 11.68 1.33301 8.00004C1.33301 4.32004 4.31967 1.33337 7.99967 1.33337C11.6797 1.33337 14.6663 4.32004 14.6663 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4729 10.12L8.40626 8.88671C8.04626 8.67338 7.75293 8.16005 7.75293 7.74005V5.00671"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">ساعة و 30 د</p>
            </div>
          </div>
        </div>
        <div className="mt-4 courses-section">
          <div className="course-cover relative">
            <div className="status flex absolute bottom-1 left-1  p-2">
              كورسات امراض الدواجن
            </div>
            <div className="three_dots flex absolute top-4 left-3  p-2">
              <div className="popover" style={{ backgroundColor: "white" }}>
                <svg
                  className="popover-trigger mx-2 arrow"
                  tabIndex={0}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z"
                    fill="#A7AEC1"
                  />
                  <path
                    d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                    fill="#A7AEC1"
                  />
                </svg>
                <div
                  className="popover-content popover-right-top right-auto"
                  tabIndex={0}
                >
                  <ul>
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      // onClick={() => navigate(item.id)}
                    >
                      تفاصيل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                    <label
                      className="three-dots-li"
                      htmlFor="modal-1"
                      // onClick={() => navigate(`/Farmers/${item.id}/Edit`)}
                    >
                      تعديل الكورس
                    </label>
                    <input
                      className="modal-state"
                      id="modal-1"
                      type="checkbox"
                    />
                  </ul>
                </div>
              </div>
            </div>
            <img src={courseCover} alt="course-cover" className="course-img" />
          </div>
          <h3 className="course-title">مقدمة اساسية عن تربية الدواجن</h3>
          <div className="flex w-full justify-between">
            <div className="flex gap-4">
              <div>
                <img src={userImg} alt="user-img" />
              </div>
              <span className="user-name">احمد محسن</span>
            </div>
            <div className="flex items-center gap-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 2.5L12.0279 7.20889L17.1329 7.68237L13.2811 11.0661L14.4084 16.0676L10 13.45L5.59161 16.0676L6.71886 11.0661L2.86708 7.68237L7.97214 7.20889L10 2.5Z"
                    fill="#FFBB54"
                  />
                </svg>
              </span>
              <span className="number">4.5</span>
            </div>
          </div>
          <div className="flex gap-4 justify-start">
            {/* عدد الطلاب */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.00033 8.00004C9.84127 8.00004 11.3337 6.50766 11.3337 4.66671C11.3337 2.82576 9.84127 1.33337 8.00033 1.33337C6.15938 1.33337 4.66699 2.82576 4.66699 4.66671C4.66699 6.50766 6.15938 8.00004 8.00033 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">500 طالب</p>
            </div>
            {/* الوحدات */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.99967 14.6667H9.99967C13.333 14.6667 14.6663 13.3334 14.6663 10V6.00004C14.6663 2.66671 13.333 1.33337 9.99967 1.33337H5.99967C2.66634 1.33337 1.33301 2.66671 1.33301 6.00004V10C1.33301 13.3334 2.66634 14.6667 5.99967 14.6667Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 6H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 10H5.5"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">5 وحدات</p>
            </div>
            {/* الساعة */}
            <div className="flex gap-2 items-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M14.6663 8.00004C14.6663 11.68 11.6797 14.6667 7.99967 14.6667C4.31967 14.6667 1.33301 11.68 1.33301 8.00004C1.33301 4.32004 4.31967 1.33337 7.99967 1.33337C11.6797 1.33337 14.6663 4.32004 14.6663 8.00004Z"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4729 10.12L8.40626 8.88671C8.04626 8.67338 7.75293 8.16005 7.75293 7.74005V5.00671"
                    stroke="#9C9CA4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <p className="info-txt">ساعة و 30 د</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import "./Academy_Trainers.scss";
import trainer from "../../../Assets/Icons/trainer.png";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { useNavigate } from "react-router-dom";
export const AcademyTrainers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isActive, setisActive] = useState(false);
  useEffect(() => {
    let mainHeaderName = "الأكاديمية";
    dispatch(setMainHeaderName({ mainHeaderName }));
  }, []);
  return (
    <div className="Academy_Trainers">
      <div className="grid">
        {/* Header */}
        <div className="col-span-full">
          <div className="grid grid-cols-7 gap-6 mt-5 pt-4">
            <div className="col-start-1 flex justify-center">
              <p className="head_title"> الصورة</p>
            </div>
            <div className="col-start-2 flex justify-center">
              <p className="head_title"> الاسم</p>
            </div>
            <div className="col-start-3 col-span-3 flex justify-center">
              <p className="head_title"> الوصف</p>
            </div>
            <div className="col-start-6">
              <p className="head_title"> الأعتماد</p>
            </div>
            <div className="col-start-7">
              <p className="head_title"> Actions</p>
            </div>
          </div>
        </div>
        {/* Content */}
        <div className="col-span-full data-row rounded-full m-4">
          <div className="grid grid-cols-7">
            <div className="col-start-1 flex justify-start ">
              <img src={trainer} alt="trainer_img" className="trainer_img" />
            </div>
            <div className="col-start-2 flex justify-center items-center">
              <p className="content">د.مجدي القاضي</p>
            </div>
            <div className="col-start-3 col-span-3  flex justify-center items-center">
              <p className="content">
                استشاري أمراض الدواجن - عميد كلية الطب البيطري جام ...
              </p>
            </div>
            <div className="col-start-6  ml-14 flex justify-center  items-center">
              <input
                type="checkbox"
                className="switch switch-success"
                checked={isActive}
                onChange={() => setisActive(!isActive)}
              />
            </div>
            <div className="col-start-7 flex gap-4 justify-center items-center">
              {/* Edit Icon */}
              <div onClick={() => navigate("/DaginAcademy/Trainers/:1/Edit")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22 12V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2H12M15.6864 4.02275C15.6864 4.02275 15.6864 5.45305 17.1167 6.88334C18.547 8.31364 19.9773 8.31364 19.9773 8.31364M9.15467 15.9896L12.1583 15.5605C12.5916 15.4986 12.9931 15.2978 13.3025 14.9884L21.4076 6.88334C22.1975 6.09341 22.1975 4.81268 21.4076 4.02275L19.9773 2.59245C19.1873 1.80252 17.9066 1.80252 17.1167 2.59245L9.01164 10.6975C8.70217 11.0069 8.50142 11.4084 8.43952 11.8417L8.01044 14.8453C7.91508 15.5128 8.4872 16.0849 9.15467 15.9896Z"
                    stroke="#28303F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              {/* Delete Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                    stroke="#EB001B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-full data-row rounded-full m-4">
          <div className="grid grid-cols-7">
            <div className="col-start-1 flex justify-start ">
              <img src={trainer} alt="trainer_img" className="trainer_img" />
            </div>
            <div className="col-start-2 flex justify-center items-center">
              <p className="content">د.مجدي القاضي</p>
            </div>
            <div className="col-start-3 col-span-3  flex justify-center items-center">
              <p className="content">
                استشاري أمراض الدواجن - عميد كلية الطب البيطري جام ...
              </p>
            </div>
            <div className="col-start-6  ml-14 flex justify-center  items-center">
              <input
                type="checkbox"
                className="switch switch-success"
                checked={isActive}
                onChange={() => setisActive(!isActive)}
              />
            </div>
            <div className="col-start-7 flex gap-4 justify-center items-center">
              {/* Edit Icon */}
              <div onClick={() => navigate("/DaginAcademy/Trainers/:1/Edit")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22 12V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2H12M15.6864 4.02275C15.6864 4.02275 15.6864 5.45305 17.1167 6.88334C18.547 8.31364 19.9773 8.31364 19.9773 8.31364M9.15467 15.9896L12.1583 15.5605C12.5916 15.4986 12.9931 15.2978 13.3025 14.9884L21.4076 6.88334C22.1975 6.09341 22.1975 4.81268 21.4076 4.02275L19.9773 2.59245C19.1873 1.80252 17.9066 1.80252 17.1167 2.59245L9.01164 10.6975C8.70217 11.0069 8.50142 11.4084 8.43952 11.8417L8.01044 14.8453C7.91508 15.5128 8.4872 16.0849 9.15467 15.9896Z"
                    stroke="#28303F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              {/* Delete Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                    stroke="#EB001B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-full data-row rounded-full m-4">
          <div className="grid grid-cols-7">
            <div className="col-start-1 flex justify-start ">
              <img src={trainer} alt="trainer_img" className="trainer_img" />
            </div>
            <div className="col-start-2 flex justify-center items-center">
              <p className="content">د.مجدي القاضي</p>
            </div>
            <div className="col-start-3 col-span-3  flex justify-center items-center">
              <p className="content">
                استشاري أمراض الدواجن - عميد كلية الطب البيطري جام ...
              </p>
            </div>
            <div className="col-start-6  ml-14 flex justify-center  items-center">
              <input
                type="checkbox"
                className="switch switch-success"
                checked={isActive}
                onChange={() => setisActive(!isActive)}
              />
            </div>
            <div className="col-start-7 flex gap-4 justify-center items-center">
              {/* Edit Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22 12V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2H12M15.6864 4.02275C15.6864 4.02275 15.6864 5.45305 17.1167 6.88334C18.547 8.31364 19.9773 8.31364 19.9773 8.31364M9.15467 15.9896L12.1583 15.5605C12.5916 15.4986 12.9931 15.2978 13.3025 14.9884L21.4076 6.88334C22.1975 6.09341 22.1975 4.81268 21.4076 4.02275L19.9773 2.59245C19.1873 1.80252 17.9066 1.80252 17.1167 2.59245L9.01164 10.6975C8.70217 11.0069 8.50142 11.4084 8.43952 11.8417L8.01044 14.8453C7.91508 15.5128 8.4872 16.0849 9.15467 15.9896Z"
                    stroke="#28303F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              {/* Delete Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                    stroke="#EB001B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-full data-row rounded-full m-4">
          <div className="grid grid-cols-7">
            <div className="col-start-1 flex justify-start ">
              <img src={trainer} alt="trainer_img" className="trainer_img" />
            </div>
            <div className="col-start-2 flex justify-center items-center">
              <p className="content">د.مجدي القاضي</p>
            </div>
            <div className="col-start-3 col-span-3  flex justify-center items-center">
              <p className="content">
                استشاري أمراض الدواجن - عميد كلية الطب البيطري جام ...
              </p>
            </div>
            <div className="col-start-6  ml-14 flex justify-center  items-center">
              <input
                type="checkbox"
                className="switch switch-success"
                checked={isActive}
                onChange={() => setisActive(!isActive)}
              />
            </div>
            <div className="col-start-7 flex gap-4 justify-center items-center">
              {/* Edit Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22 12V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2H12M15.6864 4.02275C15.6864 4.02275 15.6864 5.45305 17.1167 6.88334C18.547 8.31364 19.9773 8.31364 19.9773 8.31364M9.15467 15.9896L12.1583 15.5605C12.5916 15.4986 12.9931 15.2978 13.3025 14.9884L21.4076 6.88334C22.1975 6.09341 22.1975 4.81268 21.4076 4.02275L19.9773 2.59245C19.1873 1.80252 17.9066 1.80252 17.1167 2.59245L9.01164 10.6975C8.70217 11.0069 8.50142 11.4084 8.43952 11.8417L8.01044 14.8453C7.91508 15.5128 8.4872 16.0849 9.15467 15.9896Z"
                    stroke="#28303F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              {/* Delete Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                    stroke="#EB001B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import "./MissionDetails.scss";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../../Loading/Loading";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import API from "../../../Api";
import "moment/locale/ar"; // Import the Arabic locale
import { ar, enUS } from "date-fns/locale";
import { format } from "date-fns";
import { toast } from "react-toastify";
import moment from "moment";
import userPhoto from "../../../Assets/Icons/user.jpeg";
import { Link, NavLink } from "react-router-dom";
import { DeleteMission } from "../DeleteMission/DeleteMission";
import mapImg from "../../../Assets/Icons/Mapsicle Map.png";
import chicken from "../../../Assets/Icons/Chicken.jpg";
interface ApiResponse {
  id: string;
  created_at: string;
  updated_at: string;
  full_address: string;
  latitude: string;
  longitude: string;
  maps_url: string;
  name: string;
  audience: string;
  reward: number;
  details: string;
  due_at: string;
  early_bonus: number;
  early_bonus_due_at: string;
  accepted_at: string | null;
  req_review_at: string | null;
  completed_at: string | null;
  salesman_id: string | null;
  housing_id: string | null;
  farm_id: string | null;
  farmer_id: string | null;
  trader_id: string | null;

  salesman: {
    id: string;
    name: string;
  };
  type: {
    id: string;
    name: string;
  };
  workAreas: {
    id: string;
    name: string;
  }[];
  assignedUsers: {
    id: string;
    name: string;
  }[];
  breeder: {
    id: string;
    name: string;
    created_at: string;
    mobile_number: string;
    whatsapp_number: string;
    village: string;
    email: string;
    approx_breeding_size: number;
    farms_count: number;
    activity_type: {
      id: string;
      name: string;
    };
  };
  farm: {
    village: string;
    id: string;
    name: string;
    full_address: string;
    area: number;
    photo: string;
    capacity: {
      id: string;
      capacity: string;
    };
    farmingType: {
      id: string;
      type: string;
    };
    ventilationType: {
      id: string;
      type: string;
    };
    governorate: {
      created_at: string;
      updated_at: string;
      id: string;
      name: string;
    };
    city: {
      id: string;
      name: string;
      governorate_id: string;
    };
    status: {
      id: string;
      status: string;
    };
    latitude: number;
    longitude: number;
  };

  assignedSpecialties: any[]; // Adjust the type accordingly
  housing: {
    count: number;
    housing_date: string;
    id: string;
  };
}
interface HousingResponse {
  badi_price: any;
  chick_price: any;
  company: {
    id: string;
    type: string;
  };
  creator: {
    id: string;
    name: string;
    mobile_number: string;
  };
  dajinBreed: {
    id: string;
    breed_type: string;
    dajin_id: string;
    dajinType: {
      id: string;
      image: string;
      type: string;
    };
  };
  expected_sale_date: string;
  farm_id: string;
  farmer_id: string;
  feedCompany: {
    id: string;
    name: string;
  };
  housing_date: string;
  id: string;
  nahi_price: any;
  nami_price: any;
  other_brooder_co: any;
  other_feed_co: any;
  suggested_traders: [];
}
let createdAtDate_Arabic = "";
let dueDate_Arabic = "";
let assignDateArabic = "";
let early_bonus_due_at = "";
let breederCreatedDate = "";
export const MissionDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const { MissionId } = useParams<{ MissionId: string }>();
  const [rangeText, setRangeText] = useState("");
  const [showDeletePopUp, seShowDeletePopUp] = useState(false);
  const [housingResponse, setHousingResponse] =
    useState<HousingResponse | null>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleShowDeletePopUp = (missionId: number) => {
    seShowDeletePopUp(!showDeletePopUp);
  };
  useEffect(() => {
    setIsLoading(true);
    let mainHeaderName = "تفاصيل المهمة";
    dispatch(setMainHeaderName({ mainHeaderName }));

    API.get(`dashboard/missions/${MissionId}`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          setIsLoading(false);
        } else {
          if (res.data.farm_id != null && res.data.housing_id != null) {
            API.get(
              `farms/${res.data.farm_id}/housing/${res.data.housing_id}`
            ).then((res) => {
              setHousingResponse(res.data);
            });
          }
          const createdAtDate = moment(res.data.created_at);
          const dueDate = moment(res.data.due_at);
          const assingedAt = moment(res.data.created_at);
          const bonusDate = moment(res.data.early_bonus_due_at);

          const breederDate =
            res.data.breeder != null
              ? moment(res.data.breeder.created_at)
              : null;
          // Set the locale to Arabic
          moment.locale("ar");
          createdAtDate_Arabic = createdAtDate.format("DD MMM YYYY");
          dueDate_Arabic = dueDate.format("DD MMMM YYYY");
          early_bonus_due_at = bonusDate.format("DD MMMM YYYY");
          breederCreatedDate =
            breederDate != null ? breederDate.format("DD MM YYYY") : "";
          const assignedHour = assingedAt.hours();
          let date_type = "مساءً";
          if (assignedHour < 12) date_type = "صباحا";

          assignDateArabic = assingedAt.format(
            `DD MMMM YYYY -   HH:mm  ${date_type}`
          );
          setApiResponse(res.data);
          setIsLoading(false);
        }
      }
    });
    setIsLoading(false);
  }, []);

  const handleImageError = (event: any) => {
    // Set a fallback image source when the original image fails to load
    event.target.src = chicken;
  };
  return (
    <div className="MissionDetails">
      {isLoading === true ? <Loading /> : null}
      {showDeletePopUp === true ? <DeleteMission /> : null}
      {/* المعلومات الرئيسية */}
      <div className="grid grid-cols-2 account-data-section mt-4">
        <div className="col-start-1 mt-4 pr-4">
          <span className="main-info">المعلومات الرئيسية</span>
        </div>
        {/* Delete Icon */}
        <div className="col-start-2 mt-4 flex items-center justify-end gap-4 pl-4">
          <label
            htmlFor="modal-11"
            onClick={() => handleShowDeletePopUp(Number(apiResponse?.id))}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                stroke="#EB001B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </label>
          {/* Edit Icon */}
          <Link
            to={`/Missions/Edit/${apiResponse?.id}`}
            state={{
              from: `${location.state != null ? location.state.from : ""}`,
            }}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M22 12.267V18.4006C22 20.6589 20.2091 22.4896 18 22.4896H6C3.79086 22.4896 2 20.6589 2 18.4006V6.13346C2 3.87515 3.79086 2.04443 6 2.04443H12M15.6864 4.1122C15.6864 4.1122 15.6864 5.57434 17.1167 7.03647C18.547 8.4986 19.9773 8.4986 19.9773 8.4986M9.15467 16.3454L12.1583 15.9067C12.5916 15.8435 12.9931 15.6382 13.3025 15.3219L21.4076 7.03646C22.1975 6.22895 22.1975 4.91971 21.4076 4.1122L19.9773 2.65007C19.1873 1.84256 17.9066 1.84256 17.1167 2.65007L9.01164 10.9355C8.70217 11.2518 8.50142 11.6623 8.43952 12.1052L8.01044 15.1757C7.91508 15.858 8.4872 16.4428 9.15467 16.3454Z"
                  stroke="#28303F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </Link>
          {/* Close Icon */}
          <svg
            onClick={() => navigate(-1)}
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 13 12"
            fill="none"
          >
            <path
              d="M11.4473 1.21143L1.54777 11.1109"
              stroke="#64748B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.54688 1.21143L11.4464 11.1109"
              stroke="#64748B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/* الاسم*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">الاسم</p>
        </div>

        {/*  نطاق المهمة*/}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">نطاق المهمة</p>
        </div>

        {/*  الاسم :input*/}
        <div className="col-start-1  pr-4">
          <span className="data">{apiResponse?.name}</span>
        </div>

        {/*  نطاق المهمة  :input*/}
        <div className="col-start-2 flex justify-start">
          <div className="flex gap-4 mb-4">
            {apiResponse?.workAreas.length !== undefined &&
            apiResponse.workAreas.length > 1 ? (
              <>
                <span
                  className="tooltip tooltip-top tooltip-success"
                  data-tooltip={`${apiResponse?.workAreas.map((item) => {
                    return `${item.name}`;
                  })}`}
                >
                  <div className="range">
                    <svg
                      className="location-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="14"
                      viewBox="0 0 11 14"
                      fill="none"
                    >
                      <path
                        d="M2.14575 2.3796L2.14575 2.3796C3.9996 0.493217 7.00045 0.493221 8.85427 2.37959C10.7192 4.2773 10.7088 7.40685 8.86152 9.23578L8.8559 9.24135L8.8504 9.24703L5.49605 12.7111L2.14574 9.3021C0.284754 7.40848 0.28475 4.27324 2.14575 2.3796ZM3.58905 5.81128C3.58905 6.8629 4.43502 7.74263 5.50003 7.74263C6.56499 7.74263 7.41093 6.86287 7.41093 5.81128C7.41093 4.75973 6.56497 3.88 5.50003 3.88C4.43505 3.88 3.58905 4.7597 3.58905 5.81128Z"
                        stroke="#9BA0B1"
                        stroke-width="1.5"
                      />
                    </svg>
                  </div>
                  <span className="data">
                    {apiResponse?.workAreas[0].name}
                    {"  "}و {apiResponse?.workAreas.length - 1} أخرون
                  </span>
                </span>
              </>
            ) : (
              <span className="data">{apiResponse?.workAreas[0].name}</span>
            )}
            {/* {apiResponse?.workAreas.map((item) => {
              return (
                <div className="range" key={item.id}>
                  <span className="range-txt">{item.name}</span>
                </div>
              );
            })} */}
          </div>
        </div>

        {/* انشئت في*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">انشئت في</p>
        </div>

        {/*  تنتهي في */}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">تنتهي في</p>
        </div>

        {/*  انشئت في :input*/}
        <div className="col-start-1  pr-4">
          <div className="created-at">{createdAtDate_Arabic}</div>
        </div>

        {/*   تنتهي في  :input*/}
        <div className="col-start-2 flex justify-start">
          <div className="created-at">{dueDate_Arabic}</div>
        </div>

        {/* نوع المهمة*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">نوع المهمة</p>
        </div>

        {/*  المقابل المادي */}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">المقابل المادي</p>
        </div>

        {/*  نوع المهمة :input*/}
        <div className="col-start-1  pr-4">
          <span className="data">{apiResponse?.type.name}</span>
        </div>

        {/*  المقابل المادي  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data">{apiResponse?.reward}</span>
        </div>

        {/*  قيمة الحافز الاستثنائي */}
        <div className="col-start-1  mt-4 pr-4">
          <p className="title">قيمة الحافز الاستثنائي</p>
        </div>

        {/* تاريخ الحافز الاستثنائي
         */}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">تاريخ الحافز الاستثنائي</p>
        </div>
        {/*  قيمة الحافز الاستثنائي  :input*/}
        <div className="col-start-1 pr-4">
          <span className="data">
            {apiResponse?.early_bonus === null
              ? "لا يوجد"
              : apiResponse?.early_bonus}
          </span>
        </div>
        {/*  تاريخ الحافز الاستثنائي:input*/}
        <div className="col-start-2  flex justify-start">
          <div className="created-at">
            {apiResponse?.early_bonus === null ? "لا يوجد" : early_bonus_due_at}
          </div>
        </div>

        {/* اسندت الي */}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">اسندت الي </p>
        </div>

        {/*  اسندت الي :input*/}
        <div className="col-start-1  pr-4">
          <div className="flex gap-4 mb-4">
            {apiResponse?.assignedUsers.map((item) => {
              return (
                <div className="flex user-card gap-4">
                  <div>
                    <img src={userPhoto} alt="user" />
                  </div>
                  <span className="user-name">{item.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* معلومات المندوب */}
      <div className="grid grid-cols-2 activity-information-section mt-4">
        <div className="col-start-1 mt-4 pr-4">
          <span className="main-info">معلومات المندوب</span>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/* اسم المندوب*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">اسم المندوب</p>
        </div>
        {/*  اسم المندوب :input*/}
        <div className="col-start-1  mt-2 pr-4">
          {apiResponse?.salesman != null ||
          apiResponse?.salesman !== undefined ? (
            <>
              <div className="flex gap-4 assigned-to">
                <div>
                  <img src={userPhoto} alt="user" />
                </div>
                {apiResponse?.salesman != null ? (
                  <>
                    <Link to={`/Users/Profile/${apiResponse?.salesman.id}`}>
                      <a className="flex gap-1 cursor-pointer">
                        <span className="data">
                          {apiResponse?.salesman != null
                            ? apiResponse?.salesman.name
                            : "لم تسند بعد"}
                        </span>
                      </a>
                    </Link>
                  </>
                ) : (
                  <>
                    <div className="flex gap-1">
                      <span className="data">اسند لي:</span>
                      <span className="data">غير معرف</span>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
      {apiResponse?.type != null && apiResponse?.type.id.toString() !== "2" ? (
        <>
          {/* معلومات المربي */}
          <div className="grid grid-cols-2 activity-information-section mt-4">
            <div className="col-start-1 mt-4 pr-4">
              <span className="main-info">معلومات المربي</span>
            </div>
            <div className="col-span-full">
              <div className="divider"></div>
            </div>

            {/* اسم المربي*/}
            <div className="col-start-1 mt-4 pr-4">
              <p className="title">اسم المربي</p>
            </div>

            <div className="col-start-2 mt-4 pr-4">
              <p className="title">رقم الهاتف</p>
            </div>
            {/*  اسم المربي :input*/}
            <div className="col-start-1  mt-2 pr-4">
              {apiResponse?.breeder != null &&
              apiResponse?.breeder !== undefined ? (
                <>
                  <Link to={`/Breeders/${apiResponse?.breeder.id}`}>
                    <span className="data">{apiResponse?.breeder.name}</span>
                  </Link>
                </>
              ) : (
                ""
              )}
            </div>
            {/* رقم الهاتف */}
            <div className="col-start-2  mt-2 pr-4">
              {apiResponse?.breeder != null ? (
                <>
                  <span className="data">
                    {apiResponse?.breeder.mobile_number}
                  </span>
                </>
              ) : null}
            </div>
            <>
              {/* رقم الواتساب*/}
              <div className="col-start-1 mt-4 pr-4">
                <p className="title">رقم الواتساب</p>
              </div>
              {/* القرية */}
              <div className="col-start-2 mt-4 pr-4">
                <p className="title">القرية</p>
              </div>
              {/*  رقم الواتساب :input*/}
              <div className="col-start-1  mt-2 pr-4">
                {apiResponse?.breeder != null &&
                apiResponse?.breeder !== undefined ? (
                  <>
                    <span className="data">
                      {apiResponse?.breeder.whatsapp_number}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
              {/* القرية */}
              <div className="col-start-2  mt-2 pr-4">
                {apiResponse?.breeder != null ? (
                  <>
                    <span className="data">{apiResponse?.breeder.village}</span>
                  </>
                ) : null}
              </div>
            </>

            <>
              {/* حجم التربية التقريبي*/}
              <div className="col-start-1 mt-4 pr-4">
                <p className="title">حجم التربية التقريبي</p>
              </div>
              {/* عدد العنابر */}
              <div className="col-start-2 mt-4 pr-4">
                <p className="title">عدد العنابر</p>
              </div>
              {/*  حجم التربية التقريبي :input*/}
              <div className="col-start-1  mt-2 pr-4">
                {apiResponse?.breeder != null &&
                apiResponse?.breeder !== undefined ? (
                  <>
                    <span className="data">
                      {apiResponse?.breeder.approx_breeding_size}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
              {/* عدد العنابر input */}
              <div className="col-start-2  mt-2 pr-4">
                {apiResponse?.breeder != null ? (
                  <>
                    <span className="data">
                      {apiResponse?.breeder.farms_count}
                    </span>
                  </>
                ) : null}
              </div>
            </>

            {/* نوع النشاط*/}
            <div className="col-start-1 mt-4 pr-4">
              <p className="title">نوع النشاط</p>
            </div>
            {/* عضو منذ*/}
            <div className="col-start-2 mt-4 pr-4">
              <p className="title">عضو منذ</p>
            </div>

            {/*  نوع النشاط :input*/}
            <div className="col-start-1  mt-2 pr-4">
              {apiResponse?.breeder != null &&
              apiResponse.breeder.activity_type != null &&
              apiResponse?.breeder !== undefined ? (
                <>
                  <span className="data">
                    {apiResponse?.breeder.activity_type.name}
                  </span>
                </>
              ) : (
                ""
              )}
            </div>

            {/*  عضو منذ :input*/}
            <div className="col-start-2  mt-2 pr-4">
              {apiResponse?.breeder != null &&
              apiResponse?.breeder !== undefined ? (
                <>
                  <span className="data">{breederCreatedDate}</span>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          {/* معلومات العنبر */}
          <div className="grid grid-cols-2 activity-information-section mt-4">
            <div className="col-start-1 mt-4 pr-4">
              <span className="main-info">معلومات العنبر</span>
            </div>
            <div className="col-span-full">
              <div className="divider"></div>
            </div>

            {/* اسم العنبر*/}
            <div className="col-start-1 mt-4 pr-4">
              <p className="title">اسم العنبر</p>
            </div>

            {/* مساحة العنبر*/}
            <div className="col-start-2 mt-4 pr-4">
              <p className="title">مساحة العنبر</p>
            </div>

            {/*  اسم العنبر :input*/}
            <div className="col-start-1  mt-2 pr-4">
              {apiResponse?.farm != null && apiResponse?.farm !== undefined ? (
                <>
                  <Link to={`/AllFarms/${apiResponse?.farm.id}`}>
                    <span className="cursor-pointer data">
                      {apiResponse?.farm.name}
                    </span>
                  </Link>
                </>
              ) : (
                ""
              )}
            </div>

            {/*  مساحة العنبر العنبر :input*/}
            <div className="col-start-2  mt-2 pr-4">
              {apiResponse?.farm != null && apiResponse?.farm !== undefined ? (
                <>
                  <span className="data">{apiResponse?.farm.area}</span>
                </>
              ) : (
                ""
              )}
            </div>

            {/* الحالة*/}
            <div className="col-start-1 mt-4 pr-4">
              <p className="title">الحالة</p>
            </div>

            {/* سعة العنبر*/}
            <div className="col-start-2 mt-4 pr-4">
              <p className="title">سعة العنبر</p>
            </div>
            {/*  الحالة :input*/}
            <div className="col-start-1  mt-2 pr-4">
              {apiResponse?.farm != null && apiResponse?.farm !== undefined ? (
                <>
                  <span className="data">
                    {apiResponse?.farm.status.status}
                  </span>
                </>
              ) : (
                ""
              )}
            </div>

            {/*  سعة العنبر :input*/}
            <div className="col-start-2  mt-2 pr-4">
              {apiResponse?.farm != null && apiResponse?.farm !== undefined ? (
                <>
                  <span className="data">
                    {apiResponse?.farm.capacity.capacity}
                  </span>
                </>
              ) : (
                ""
              )}
            </div>
            {/* نوع التربية*/}
            <div className="col-start-1 mt-4 pr-4">
              <p className="title">نوع التربية</p>
            </div>

            {/* نوع التهوئة*/}
            <div className="col-start-2 mt-4 pr-4">
              <p className="title">نوع التهوئة</p>
            </div>

            {/*  نوع التربية :input*/}
            <div className="col-start-1  mt-2 pr-4">
              {apiResponse?.farm != null && apiResponse?.farm !== undefined ? (
                <>
                  <span className="data">
                    {apiResponse?.farm.farmingType.type}
                  </span>
                </>
              ) : (
                ""
              )}
            </div>

            {/*  نوع التهوئة :input*/}
            <div className="col-start-2  mt-2 pr-4">
              {apiResponse?.farm != null && apiResponse?.farm !== undefined ? (
                <>
                  <span className="data">
                    {apiResponse?.farm.ventilationType.type}
                  </span>
                </>
              ) : (
                ""
              )}
            </div>
            {/* النطاق*/}
            <div className="col-start-1 mt-4 pr-4">
              <p className="title">النطاق</p>
            </div>

            {/* القرية*/}
            <div className="col-start-2 mt-4 pr-4">
              <p className="title">القرية</p>
            </div>
            {/*  النطاق :input*/}
            <div className="col-start-1  mt-2 pr-4">
              {apiResponse?.farm != null && apiResponse?.farm !== undefined ? (
                <>
                  <span className="data">
                    {apiResponse?.farm != null
                      ? apiResponse?.farm.governorate != null
                        ? apiResponse?.farm.governorate.name
                        : ""
                      : ""}
                    {"  "}|{" "}
                    {apiResponse?.farm != null
                      ? apiResponse.farm.city != null
                        ? apiResponse.farm.city.name
                        : ""
                      : ""}
                  </span>
                </>
              ) : (
                ""
              )}
            </div>
            {/*  القرية :input*/}
            <div className="col-start-2  mt-2 pr-4">
              {apiResponse?.farm != null && apiResponse?.farm !== undefined ? (
                <>
                  <span className="data">{apiResponse?.farm.village}</span>
                </>
              ) : (
                ""
              )}
            </div>
            {/* صورة العنبر */}
            <div className="col-span-full pl-4 pr-4 mt-4">
              {apiResponse?.farm != null && apiResponse.farm.photo != null ? (
                <>
                  <div>
                    <img
                      src={apiResponse?.farm.photo}
                      className="farm-img"
                      alt="farm-img"
                      onError={handleImageError}
                    />
                  </div>
                </>
              ) : null}
            </div>
            {/* Map URL */}
            <div className="col-span-full mt-4">
              {apiResponse?.farm != null && apiResponse?.farm !== undefined ? (
                <>
                  <div className="map-container">
                    <a
                      href={`https://www.google.com/maps/dir/?api=1&destination=${apiResponse?.farm.latitude},${apiResponse?.farm.longitude}`}
                      target="_blank"
                      className="w-full"
                    >
                      <img src={mapImg} alt="map" className="map" />
                    </a>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <div className="grid grid-cols-2 breedingCycle mt-4">
            <div className="col-start-1 pr-4">
              <p className="cycle_title">دورة التسكين </p>
            </div>
            <div className="col-span-full">
              <div className="divider"></div>
            </div>
            <div className="col-start-1 pr-4 mt-4">
              <p className="cycle_title">نوع الداجن</p>
            </div>
            <div className="col-start-2 pl-4 mt-4">
              <p className="cycle_title">نوع السلالة</p>
            </div>
            {/* نوع الداجن input */}
            <div className="col-start-1 pr-4 ">
              <span className="cycle-content">
                {housingResponse?.dajinBreed.dajinType.type}
              </span>
            </div>
            {/* نوع السلالة input */}
            <div className="col-start-2 pr-4">
              <span className="cycle-content">
                {housingResponse?.dajinBreed.breed_type}
              </span>
            </div>
            {/* <div className="col-start-1 pr-4 mt-4">
          <p className="cycle_title">نوع التربية</p>
        </div> */}
            <div className="col-start-1 pr-4 mt-4">
              <p className="cycle_title">الشركة</p>
            </div>
            <div className="col-start-2 pr-4 mt-4">
              <p className="cycle_title">العدد</p>
            </div>
            {/* الشركة input */}
            <div className="col-start-1 pr-4  ">
              <span className="cycle-content">
                {housingResponse?.company.type}
              </span>
            </div>
            {/* العدد input */}
            <div className="col-start-2 pr-4">
              <span className="cycle-content">
                {apiResponse?.housing != null ? apiResponse?.housing.count : 0}
              </span>
            </div>
            <div className="col-start-1 pl-4 mt-4">
              <p className="cycle_title">تاريخ البيع المتوقع</p>
            </div>
            <div className="col-start-2 pr-4 mt-4">
              <p className="cycle_title">تاريخ الأدخال</p>
            </div>
            {/* تاريخ البيع المتوقع input */}
            <div className="col-start-1 pr-4">
              <span className="cycle-content">
                {housingResponse?.expected_sale_date}
              </span>
            </div>
            {/* تاريخ الأدخال input */}
            <div className="col-start-2 pr-4">
              <span className="cycle-content">
                {housingResponse?.housing_date}
              </span>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

import "./Users.scss";
import userIcon from "../../Assets/Icons/40px.svg";
import { useEffect, useState } from "react";
import API from "../../Api";
import { Loading } from "../Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/ar"; // Import the Arabic locale
import { ar, enUS } from "date-fns/locale";
import { format } from "date-fns";
import {
  setActiveUserData,
  setUserCreationDate,
  setUserId,
  setUserName,
  toggleShowUserProfile,
} from "../../redux/Slices/UsersSlice";
import { SuspendPopUp } from "./SubComponents/suspend/SuspendPopUp";
import { useNavigate } from "react-router-dom";
import { TotalCount } from "../../SubComponents/TotalCount/TotalCount";
export const ActiveUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [showSuspendPopUp, setShowSuspendPopUp] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const stateFromUserSlice = useSelector((state: any) => state.users);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    moment.locale("ar");
    if (
      stateFromUserSlice.isFiltered === true &&
      stateFromUserSlice.headerType === 1 &&
      stateFromUserSlice.filterDataObj.length > 0
    ) {
      if (stateFromUserSlice.filterDataObj[0].length > 0) {
        setUsers(stateFromUserSlice.filterDataObj[0]);
        setTotalRows(stateFromUserSlice.filterTotalCount);
        setIsLoading(false);
      } else {
        setUsers([]);
        setTotalRows(0);
        setIsLoading(false);
      }
    } else {
      API.get(
        `dashboard/salesman?account_status=2&page=${pageNumber}&limit=${pageSize}`
      ).then((res) => {
        if (res) {
          setUsers(res.data.items);
          setTotalRows(res.data.totalCount);
          let activeUserData = res.data.items;
          dispatch(setActiveUserData({ activeUserData }));
          setIsLoading(false);
        }
      });
    }
  }, [stateFromUserSlice.isFiltered]);
  const hanldeChangePage = (targetPN: number) => {
    setIsLoading(true);
    API.get(
      `dashboard/salesman?account_status=2&page=${pageNumber}&limit=${pageSize}`
    ).then((res) => {
      if (res) {
        setUsers(res.data.items);
        setTotalRows(res.data.totalCount);
        setPageNumber(targetPN);
        let activeUserData = res.data.items;
        dispatch(setActiveUserData({ activeUserData }));
        setIsLoading(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    });
  };

  const renderPagination = () => {
    const nPages = Math.ceil(totalRows / pageSize);
    const numbers = Array.from({ length: nPages }, (_, index) => index + 1);
    let paginationItem = numbers.map((n) => {
      return (
        <>
          <button
            className={pageNumber === n ? "btn btn-pagination-active" : "btn"}
            onClick={() => hanldeChangePage(n)}
          >
            {n}
          </button>
        </>
      );
    });
    return (
      <div
        className={
          totalRows > 5
            ? "pagination w-full max-w-xs overflow-auto"
            : "pagination"
        }
      >
        {paginationItem}
      </div>
    );
  };
  const showEditUser = (userId: number) => {
    navigate(`/Users/Edit/${userId}`);

    dispatch(setUserId({ userId }));
  };
  const showUserDetailsComponent = (
    userId: Number,
    userName: string,
    creationDate: string
  ) => {
    dispatch(setUserId({ userId }));
    dispatch(setUserName({ userName }));
    dispatch(setUserCreationDate({ creationDate }));
    navigate(`/Users/Profile/${userId}`);
  };
  const handleShowSuspendPopUp = (userId: number) => {
    dispatch(setUserId({ userId }));
    setShowSuspendPopUp(!showSuspendPopUp);
  };
  return (
    <div className="Users">
      <div className="mt-4">
        <TotalCount count={totalRows} title="المستخدمين المفعلين" />
      </div>
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
      <div className="grid grid-cols-2 gap-4">
        {users.map((user: any, index: any) => (
          <div
            key={index}
            className={`grid col-start-${index % 2 === 0 ? 1 : 2} p-4`}
          >
            <div className="userCard pr-4 pt-8 transition ease-in-out delay-150  duration-300">
              {showSuspendPopUp === true ? <SuspendPopUp /> : null}

              <div className="flex justify-between">
                <div className="flex gap-4">
                  <div>
                    <img src={userIcon} alt="user" />
                  </div>
                  <div className="grid">
                    <span
                      className="user-name cursor-pointer"
                      onClick={() =>
                        showUserDetailsComponent(
                          Number(user.id),
                          user.name,
                          user.created_at
                        )
                      }
                    >
                      {user.name}
                    </span>
                    <div className="flex">
                      <span className="typeOfSpecialization">التخصص</span>
                      <span className="type-status">
                        {`. ${user.graduationStatus} . عضو منذ ${moment(
                          user.created_at
                        ).format("YYYY/MM/DD")}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-full mt-8 flex justify-center">
                <label htmlFor="modal-454">
                  <div
                    className="de-active-btn"
                    onClick={() => handleShowSuspendPopUp(Number(user.id))}
                  >
                    الغاء تفعيل الحساب
                  </div>
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Pagination */}
      <div className="flex justify-center">{renderPagination()}</div>
    </div>
  );
};

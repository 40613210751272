import { configureStore } from "@reduxjs/toolkit";
import MissionSlice from "../Slices/MissionSlice";
import LoginSlice from "../Slices/LoginSlice";
import MainHeaderSlice from "../Slices/MainHeaderSlice";
import SidebarSlice from "../Slices/SidebarSlice";
import UsersSlice from "../Slices/UsersSlice";
import WalletsSlice from "../Slices/WalletsSlice";
import CompetitionsSlice from "../Slices/CompetitionsSlice";
import FarmSlice from "../Slices/FarmSlice";
import AcademySlice from "../Slices/AcademySlice";
import BreedersModalSlice from "../Slices/BreedersModalSlice";
import FarmsModalSlice from "../Slices/FarmsModalSlice";
import SupplierSlice from "../Slices/SupplierSlice";
const store = configureStore({
  reducer: {
    missions: MissionSlice,
    login: LoginSlice,
    mainHeader: MainHeaderSlice,
    sideBar: SidebarSlice,
    users: UsersSlice,
    wallets: WalletsSlice,
    competitions: CompetitionsSlice,
    farms: FarmSlice,
    academy: AcademySlice,
    breedersModal: BreedersModalSlice,
    FarmsModal: FarmsModalSlice,
    Suppliers: SupplierSlice,
  },
});
export default store;

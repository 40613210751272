import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLogged: false,
  userType: 1,
  selectedSupplierHeader: 1,
  displayTypeId: 1,
};
export const SupplierSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setSelectedSupplierHeader: (state, action) => {
      state.selectedSupplierHeader = action.payload.type;
    },
    setSelectedSupplierDisplayType: (state, action) => {
      state.displayTypeId = action.payload.displayTypeId;
    },
  },
});
export const { setSelectedSupplierHeader, setSelectedSupplierDisplayType } =
  SupplierSlice.actions;

export default SupplierSlice.reducer;

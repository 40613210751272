import moment from "moment";
import "moment/locale/ar"; // Import the Arabic locale
import { ar, enUS } from "date-fns/locale";
import { format } from "date-fns";
import API from "../../../../Api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setMainHeaderName } from "../../../../redux/Slices/MainHeaderSlice";

interface ApiResponse {
  id: string;
  created_at: string;
  name: string;
  village: string;
  city_id: string;
  governorate_id: string;
  full_address: string;
  mobile_number: string;
  whatsapp_number: string;
  email: string;
  avatar: string;
  account_status: number;
  national_id: string;
  specialty_id: string;
  level_id: string;
  ewallet_number: string;
  wallet_balance: number;
  activity_type: {
    id: string;
    name: string;
  };
  farms_count: number;
  approx_breeding_size: number;
  creator: {
    id: string;
    name: string;
    mobile_number: string;
  };
}
export const AccountData = () => {
  const { breederId } = useParams<{ breederId: string }>();
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rangeText, setRangeText] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    moment.locale("ar");

    let mainHeaderName = "تفاصيل مربي";
    dispatch(setMainHeaderName({ mainHeaderName }));
    API.get(`breeders/${breederId}`).then((res) => {
      if (res) {
        const updatedFarmerData = {
          ...res.data,
          avatar: `${URL}${res.data.avatar}`,
        };
        // Set the locale to Arabic

        setApiResponse(updatedFarmerData);
        API.get(`work-areas`).then((response) => {
          const idAndNameData = response.data.map((item: any) => ({
            id: item.id,
            name: item.name,
          }));
          const governorateData = response.data.map((item: any) => ({
            governorateId: item.governorate.id,
            governorateName: item.governorate.name,
          }));
          const getDistinctValues = (
            arr: { governorateId: string; governorateName: string }[]
          ) => {
            const uniqueValues = arr.filter(
              (value, index, self) =>
                index ===
                self.findIndex((v) => v.governorateId === value.governorateId)
            );
            return uniqueValues;
          };
          const distinctGovernorateData = getDistinctValues(governorateData);

          // let govName = "";
          let cityName = "";
          if (
            distinctGovernorateData.length > 0 &&
            distinctGovernorateData != null &&
            idAndNameData.length > 0 &&
            idAndNameData != null
          ) {
            // govName = distinctGovernorateData.find(c => c.governorateId === res.data.gov_id)?.governorateName ?? "";
            cityName =
              idAndNameData.find((c: any) => c.id === res.data.city_id)?.name ??
              "";
          }
          setRangeText(`${cityName}`);
        });
        setIsLoading(false);
      }
    });
  }, []);
  return (
    <>
      {/* المعلومات الرئيسية */}
      <div className="grid grid-cols-2 account-data-section mt-4">
        <div className="col-start-1 mt-4 pr-4">
          <span className="main-info">المعلومات الرئيسية</span>
        </div>
        {/* Edit Icon */}
        <div className="col-start-2 mt-4 flex justify-end pl-4">
          <div onClick={() => navigate("Edit")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M22 12.267V18.4006C22 20.6589 20.2091 22.4896 18 22.4896H6C3.79086 22.4896 2 20.6589 2 18.4006V6.13346C2 3.87515 3.79086 2.04443 6 2.04443H12M15.6864 4.1122C15.6864 4.1122 15.6864 5.57434 17.1167 7.03647C18.547 8.4986 19.9773 8.4986 19.9773 8.4986M9.15467 16.3454L12.1583 15.9067C12.5916 15.8435 12.9931 15.6382 13.3025 15.3219L21.4076 7.03646C22.1975 6.22895 22.1975 4.91971 21.4076 4.1122L19.9773 2.65007C19.1873 1.84256 17.9066 1.84256 17.1167 2.65007L9.01164 10.9355C8.70217 11.2518 8.50142 11.6623 8.43952 12.1052L8.01044 15.1757C7.91508 15.858 8.4872 16.4428 9.15467 16.3454Z"
                stroke="#28303F"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>

        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/* الاسم*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">الاسم</p>
        </div>

        {/*  العنوان*/}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">العنوان</p>
        </div>

        {/*  الاسم :input*/}
        <div className="col-start-1  pr-4">
          <span className="data">{apiResponse?.name}</span>
        </div>

        {/*  العنوان  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data">{rangeText}</span>
        </div>

        {/* البريد الألكتروني*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">البريد الألكتروني</p>
        </div>

        {/*  رقم الهاتف */}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">رقم الهاتف</p>
        </div>

        {/*  البريد الألكتروني :input*/}
        <div className="col-start-1  pr-4">
          <span className="data">{apiResponse?.email}</span>
        </div>

        {/*  رقم الهاتف  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data">{apiResponse?.mobile_number}</span>
        </div>

        {/* رقم الواتساب*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">رقم الواتساب</p>
        </div>

        {/*  حساب الفيسبوك */}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">حساب الفيسبوك</p>
        </div>

        {/*  رقم الواتساب :input*/}
        <div className="col-start-1  pr-4">
          <span className="data">{apiResponse?.whatsapp_number}</span>
        </div>

        {/*  حساب الفيسبوك  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data"></span>
        </div>

        {/* تمت الاضافة بواسطة*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">تمت الاضافة بواسطة</p>
        </div>

        {/*  تمت الاضافة بواسطة  :input*/}
        <div className="col-start-1  pr-4">
          <span className="data cursor-pointer">
            {apiResponse?.creator != null ? (
              <>
                <Link to={`/Users/Profile/${apiResponse?.creator.id}`}>
                  <a>{apiResponse?.creator.name}</a>
                </Link>
              </>
            ) : (
              ""
            )}
          </span>
        </div>
      </div>
      {/* معلومات النشاط */}
      <div className="grid grid-cols-2 activity-information-section mt-4">
        <div className="col-start-1 mt-4 pr-4">
          <span className="main-info">معلومات النشاط</span>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/* توع النشاط*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">نوع النشاط</p>
        </div>

        {/*  حجم التربية التقريبي*/}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">حجم التربية التقريبي</p>
        </div>

        {/*  توع النشاط :input*/}
        <div className="col-start-1  pr-4">
          {apiResponse?.activity_type != null ? (
            <>
              <span className="data">{apiResponse?.activity_type.name}</span>
            </>
          ) : null}
        </div>

        {/*  حجم التربية التقريبي  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data">{apiResponse?.approx_breeding_size}</span>
        </div>

        {/* إجمالي عنابر التربية*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">إجمالي عنابر التربية</p>
        </div>
        {/*  إجمالي عنابر التربية :input*/}
        <div className="col-start-1  pr-4">
          <span className="data">{apiResponse?.farms_count}</span>
        </div>
      </div>

      {/* التفعيل */}
      <div className="grid grid-cols-2 activation-section mt-4">
        <div className="col-start-1 mt-4 pr-4">
          <span className="main-info">معلومات اضافية</span>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/* الاهتمامات*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">الاهتمامات</p>
        </div>

        {/*  الاهتمامات :input*/}
        <div className="col-start-1 mt-1 pr-4">
          <div className="flex gap-4">
            <div className="active-interest">إشراف على مدار الساعة</div>
            <div className="active-interest">خدمات الأمن الحيوي</div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 additional-information-section mt-4">
        <div className="col-start-1 mt-4 pr-4">
          <span className="main-info">التفعيل</span>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/*  التفعيل :input*/}
        <div className="col-start-1 mt-1 pr-4">
          <div className="flex gap-4">
            <div className="flex gap-4 items-center">
              <input
                type="radio"
                className="radio-primary radio"
                checked={apiResponse?.account_status === 2 ? true : false}
              />
              <span className="active-status">مفعل</span>
            </div>

            <div className="flex gap-4 items-center">
              <input
                type="radio"
                className="radio-primary radio"
                checked={apiResponse?.account_status === 1 ? true : false}
              />
              <span className="active-status">غير مفعل</span>
            </div>

            <div className="flex gap-4 items-center">
              <input
                type="radio"
                className="radio-primary radio"
                checked={apiResponse?.account_status === 3 ? true : false}
              />
              <span className="active-status">موقوف</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { Login } from "../Components/Login/Login";
import { AddMission } from "../Components/Missions/AddMission/AddMission";
import { PendingMission } from "../Components/Missions/PendingMission/PendingMission";
import { Competitions } from "../Pages/Competitions/Competitions";
import { FarmDetailsPage } from "../Pages/Farms/FarmDetailsPage";
import { FarmsPage } from "../Pages/Farms/FarmsPage";
import { BreedersPage } from "../Pages/Breeders/BreedersPage";
import { MainPage } from "../Pages/MainPage/MainPage";
import { AddMissionPage } from "../Pages/Missions/AddMissionPage";
import { EditMissionPage } from "../Pages/Missions/EditMissionPage";
import { MissionsPage } from "../Pages/Missions/MissionsPage";
import { AddUserPage } from "../Pages/Users/AddUserPage";
import { EditUserPage } from "../Pages/Users/EditUserPage";
import { UserProfilePage } from "../Pages/Users/UserProfilePage";
import { Users } from "../Pages/Users/Users";
import { SettlementWalletsPage } from "../Pages/Wallets/SettlementWalletsPage";
import { Wallets } from "../Pages/Wallets/Wallets";
import { AuthProvider, useAuth } from "./auth";
import { Route, Routes } from "react-router-dom";
import { BreederDetailsPage } from "../Pages/Breeders/BreederDetailsPage";
import { AddFarmPage } from "../Pages/Farms/AddFarmPage";
import { EditFarmPage } from "../Pages/Farms/EditFarmPage";
import { AddBreedersPage } from "../Pages/Breeders/AddBreedersPage";
import { HousingPage } from "../Pages/Farms/HousingPage";
import { EditBreedersPage } from "../Pages/Breeders/EditBreedersPage";
import { CoursesPage } from "../Pages/Courses/CoursesPage";
import { ReportsPage } from "../Pages/Reports/ReportsPage";
import { EditHousingPage } from "../Pages/Farms/EditHousingPage";
import { FarmSaleOfferPage } from "../Pages/Farms/FarmSaleOfferPage";
import { FarmMovementRecordPage } from "../Pages/Farms/FarmMovementRecordPage";
import { NotFound } from "../Pages/NotFound/NotFound";
import { AcademyPage } from "../Pages/Academy/AcademyPage";
import { AddCoursePage } from "../Pages/Academy/AddCoursePage";
import { EditCoursePage } from "../Pages/Academy/EditCoursePage";
import { DetailsCoursePage } from "../Pages/Academy/DetailsCoursePage";
import { AddLecturePage } from "../Pages/Academy/AddLecturePage";
import { EditLecturePage } from "../Pages/Academy/EditLecturePage";
import { AddTrainerPage } from "../Pages/Academy/AddTrainerPage";
import { EditTrainerPage } from "../Pages/Academy/EditTrainerPage";
import { AddForumsPage } from "../Pages/Academy/AddForumsPage";
import { EditForumsPage } from "../Pages/Academy/EditForumsPage";
import { MissionDetailsPage } from "../Pages/Missions/MissionDetailsPage";
import { SuppliersPage } from "../Pages/Suppliers/SuppliersPage";
import { CommingSoon } from "../Components/CommingSoon/CommingSoon";

export const Views = () => {
  const auth = useAuth();
  return (
    <AuthProvider>
      <Routes>
        {!auth?.user && <Route path="/login" element={<Login />} />}
        <Route path="/Home" element={<MainPage />} />

        <Route
          path="/Missions/:MissionId"
          element={<MissionDetailsPage />}
        ></Route>
        <Route path="/Missions/All" element={<MissionsPage />}></Route>
        <Route path="/Missions/Pending" element={<MissionsPage />}></Route>
        <Route path="/Missions/InProgress" element={<MissionsPage />}></Route>
        <Route path="/Missions/Late" element={<MissionsPage />}></Route>
        <Route path="/Missions/Evaluation" element={<MissionsPage />}></Route>
        <Route path="/Missions/Finished" element={<MissionsPage />}></Route>
        <Route path="/Missions/Refused" element={<MissionsPage />}></Route>
        <Route path="/Missions/Add" element={<AddMissionPage />} />
        <Route path="/Missions/Edit/:MissionId" element={<EditMissionPage />} />

        <Route path="/Exams" element={<NotFound />}></Route>

        <Route path="/AllFarms" element={<FarmsPage />}></Route>
        <Route path="/FarmsUnderRevision" element={<FarmsPage />}></Route>
        <Route path="/FarmsActive" element={<FarmsPage />}></Route>
        <Route path="/AllFarms/:farmId" element={<FarmDetailsPage />} />
        <Route path="/AllFarms/:farmId/Edit" element={<EditFarmPage />} />
        <Route
          path="/AllFarms/:farmId/:breederId/Housing"
          element={<HousingPage />}
        />
        <Route
          path="/AllFarms/:farmId/:housingId/HousingEdit"
          element={<EditHousingPage />}
        />
        <Route
          path="/AllFarms/:farmId/SalesOffer"
          element={<FarmSaleOfferPage />}
        />
        <Route
          path="/AllFarms/:farmId/MovementRecord"
          element={<FarmMovementRecordPage />}
        />

        <Route path="/Breeders" element={<BreedersPage />}></Route>
        <Route path="/Breeders/:breederId" element={<BreederDetailsPage />} />
        <Route path="/Breeders/:breederId/Add/Farm" element={<AddFarmPage />} />
        <Route path="/Breeders/Add" element={<AddBreedersPage />} />
        <Route
          path="/Breeders/:breederId/Edit"
          element={<EditBreedersPage />}
        />

        {/* Suppliers Module */}
        <Route path="/AllSuppliers" element={<SuppliersPage />}></Route>
        <Route
          path="/Suppliers/NewRequests"
          element={<SuppliersPage />}
        ></Route>
        <Route path="/Suppliers/Archive" element={<SuppliersPage />}></Route>

        <Route path="/Competitions" element={<Competitions />}></Route>

        <Route path="/Courses" element={<CoursesPage />}></Route>

        <Route path="/DaginAcademy/Course" element={<AcademyPage />}></Route>
        <Route
          path="/DaginAcademy/Course/:courseId"
          element={<DetailsCoursePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/:courseId/:trainerId/trainer"
          element={<DetailsCoursePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/:courseId/:trainerId/lectures"
          element={<DetailsCoursePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/:courseId/:trainerId/lecture/Details/:lectureId"
          element={<DetailsCoursePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/:courseId/lecture/Add"
          element={<AddLecturePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/:courseId/lecture/:lectureId/Edit"
          element={<EditLecturePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/Add"
          element={<AddCoursePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/Edit/:CourseId"
          element={<EditCoursePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Categories"
          element={<AcademyPage />}
        ></Route>
        <Route path="/DaginAcademy/Trainers" element={<AcademyPage />}></Route>
        <Route
          path="/DaginAcademy/Trainers/Add"
          element={<AddTrainerPage />}
        ></Route>
        <Route
          path="/DaginAcademy/Trainers/:trainerId/Edit"
          element={<EditTrainerPage />}
        ></Route>
        <Route path="/DaginAcademy/Forums" element={<AcademyPage />}></Route>
        <Route
          path="/DaginAcademy/Forums/Add"
          element={<AddForumsPage />}
        ></Route>
        <Route
          path="/DaginAcademy/Forums/:forumId/Edit"
          element={<EditForumsPage />}
        ></Route>

        <Route path="/Users/ActiveSalesMan" element={<Users />}></Route>
        <Route path="/Users/DisabledSalesMan" element={<Users />}></Route>
        <Route path="/Users/StoppedSalesMan" element={<Users />}></Route>
        <Route path="/Users/Add" element={<AddUserPage />} />
        <Route path="/Users/Edit/:userId" element={<EditUserPage />} />
        <Route path="/Users/Profile/:userId" element={<UserProfilePage />} />

        <Route path="/Wallets" element={<Wallets />}></Route>
        <Route
          path="/Wallets/Settlement/:userId"
          element={<SettlementWalletsPage />}
        />

        <Route path="/Reports" element={<ReportsPage />}></Route>
        <Route path="/Settings" element={<NotFound />}></Route>
        <Route path="/Notification" element={<NotFound />}></Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </AuthProvider>
  );
};

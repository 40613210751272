import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedMission: 1,
  missionId: 0,
  showAddMission: false,
  selecteTypeId: 0,
  selectedTypeName: "",
  workAreasIds: [],
  workAreaChanged: false,
  workAreasTitle: "",
  assignedIds: [],
  assignedType: "",
  assignedToChanged: false,
  assignedText: "",
  dueDate: new Date(),
  expectedDate: new Date(),
  housingDate: new Date(),
  isHousingDateDateChanged: false,
  isDateChanged: false,
  isExpectedDateDateChanged: false,
  showDetailsPopUp: false,
  showEditMission: false,
  BonusDate: new Date(),
  isBonusDateChanged: false,
  markAfterEdit: false,
  headerType: 0,
  filterDataObj: [],
  isFiltered: false,
  filterTotalCount: 0,
  filterUrl: "",
  currentDate: "",
};
export const MissionSlice = createSlice({
  name: "mission",
  initialState,
  reducers: {
    selectedMissionType: (state, action) => {
      state.selectedMission = action.payload.type;
    },
    toggleShowAddMission: (state, action) => {
      state.showAddMission = action.payload.isVisible;
    },
    toggleShowEditMission: (state, action) => {
      state.showEditMission = action.payload.isVisible;
      state.markAfterEdit = action.payload.markAfterEdit;
    },
    setSelectedMissionType: (state, action) => {
      state.selecteTypeId = action.payload.typeId;
      state.selectedTypeName = action.payload.typeName;
    },
    setSelectedWorkAreas: (state, action) => {
      state.workAreasIds = action.payload.rangeIds;
      state.workAreasTitle = action.payload.rangeTitle;
      state.workAreaChanged = action.payload.workAreaChanged;
    },
    setSelectedAssignTo: (state, action) => {
      state.assignedIds = action.payload.assignedIds;
      state.assignedType = action.payload.assignedType;
      state.assignedText = action.payload.assignedText;
      state.assignedToChanged = action.payload.isChanged;
    },
    setDueDateValue: (state, action) => {
      state.dueDate = action.payload.dueDate;
      state.isDateChanged = action.payload.isDateChanged;
    },
    setExpectedDateValue: (state, action) => {
      state.expectedDate = action.payload.dueDate;
      state.isExpectedDateDateChanged = action.payload.isDateChanged;
    },
    setHousingDateValue: (state, action) => {
      state.housingDate = action.payload.dueDate;
      state.isHousingDateDateChanged = action.payload.isDateChanged;
    },
    setBonusDate: (state, action) => {
      state.BonusDate = action.payload.bonusDate;
      state.isBonusDateChanged = action.payload.isBonusDateChanged;
    },
    sendMissionIdToPopUp: (state, action) => {
      state.missionId = action.payload.missionId;
    },
    showDetailsPopUp: (state, action) => {
      state.showDetailsPopUp = action.payload.isDetailsActive;
    },
    handleSubmitFilterResult: (state, action) => {
      state.headerType = action.payload.type;
      state.filterDataObj = action.payload.filterObj;
      state.isFiltered = action.payload.isFiltered;
      state.filterTotalCount = action.payload.totalCount;
      state.filterUrl = action.payload.filterUrl;
    },
    //Todo Move to new Main Page Slice
    handleSubmitCurrentDateForMainPage: (state, action) => {
      state.currentDate = action.payload.currentMainPageDate;
    },
  },
});
export const {
  selectedMissionType,
  toggleShowAddMission,
  setSelectedMissionType,
  setSelectedWorkAreas,
  setSelectedAssignTo,
  setDueDateValue,
  sendMissionIdToPopUp,
  showDetailsPopUp,
  toggleShowEditMission,
  setBonusDate,
  handleSubmitFilterResult,
  setExpectedDateValue,
  setHousingDateValue,
  handleSubmitCurrentDateForMainPage,
} = MissionSlice.actions;

export default MissionSlice.reducer;

import "./MissionFilter.scss";
import Fuse from "fuse.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import API from "../../../Api";
import { Loading } from "../../Loading/Loading";
import { useLocation } from "react-router-dom";
import { handleSubmitFilterResult } from "../../../redux/Slices/MissionSlice";
export const MissionFilter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setLocationData] = useState([]);
  const [salesManData, setSalesManData] = useState([]);
  const [breedersData, setBreedersData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(1);
  const [selectedLocationId, setSelectedLocationId] = useState(1);
  const [selectedItemName, setSelectedItemName] = useState("");
  const [selectedTypeId, setSelectedTypeId] = useState(0);
  const [selectedTypeName, setSelectedTypeName] = useState("");
  const [selectedAddedById, setSelectedAddedById] = useState(0);
  const [selectedAddedByName, setSelectedAddedByName] = useState("");
  const [selectedSalesManId, setSelectedSalesManId] = useState(0);
  const [selectedBreederId, setSelectedBreederId] = useState(0);
  const [locationSearchResults, setLocationSearchResults] = useState<any[]>([
    {},
  ]);
  const [salesManSearchResults, setSalesManSearchResults] = useState<any[]>([
    {},
  ]);
  const [breederSearchResults, setBreederSearchResults] = useState<any[]>([{}]);

  const [filterObject, setFilterObject] = useState({
    salesManId: 0,
    breederId: 0,
    createdBy: "",
    has_breeder: false,
    has_housing: false,
    has_farm: false,
    gov_id: 0,
    city_id: 0,
  });
  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["name"],
  };
  const fuse = new Fuse(locationSearchResults, options);
  const fuseSalesMan = new Fuse(salesManSearchResults, options);
  const fuseBreeders = new Fuse(salesManSearchResults, options);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    //governorates API
    API.get(`governorates`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setLocationSearchResults(res.data);
          setLocationData(res.data);
          setIsLoading(false);
        }
      }
    });
    //Sales Man Api
    API.get("salesman?page=1&limit=500000000").then((res) => {
      setIsLoading(true);
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setSalesManSearchResults(res.data.items);
          setSalesManData(res.data.items);

          setIsLoading(false);
        }
      }
    });
    //Breeders API
    API.get(`breeders?page=1&limit=500000000`).then((res) => {
      setIsLoading(true);
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setBreederSearchResults(res.data.items);
          setBreedersData(res.data.items);
          setIsLoading(false);
        }
      }
    });
  }, []);

  const handleLocationSearch = (event: any) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setLocationSearchResults(data);
      return;
    }

    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setLocationSearchResults(items);
  };

  const handleSalesManSearch = (event: any) => {
    setIsLoading(true);
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setIsLoading(false);
      setSalesManSearchResults(salesManData);
      return;
    }
    API.get(
      `dashboard/salesman?sort_by=id&sort_order=DESC&page=1&limit=10000000000&search=${event.target.value}`
    ).then((res) => {
      setSalesManSearchResults(res.data.items);
      setIsLoading(false);
    });
  };

  const handleBreedersSearch = (event: any) => {
    setIsLoading(true);
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setBreederSearchResults(breedersData);
      return;
    }
    API.get(
      `breeders?sort_by=id&sort_order=DESC&page=1&limit=10000000000&search=${event.target.value}`
    ).then((res) => {
      setBreederSearchResults(res.data.items);
      setIsLoading(false);
    });
  };

  const handleChangeLocationType = (itemId: number, itemName: string) => {
    setIsLoading(true);
    setSelectedLocationId(0);
    setSelectedItemId(itemId === selectedItemId ? 0 : itemId);
    setSelectedItemName(itemId === selectedItemId ? "null" : itemName);
    if (itemId === 2) {
      API.get(`cities`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    } else {
      API.get(`governorates`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    }
  };

  const handleChangeLocationContent = (itemId: number, itemName: string) => {
    setSelectedLocationId(itemId === selectedLocationId ? 0 : itemId);
    if (selectedItemId === 1) {
      //محافظات
      setFilterObject((prevState) => ({
        ...prevState,
        ["gov_id"]: Number(itemId),
        ["city_id"]: 0,
      }));
    } else {
      //مناطق
      setFilterObject((prevState) => ({
        ...prevState,
        ["city_id"]: Number(itemId),
        ["gov_id"]: 0,
      }));
    }
  };

  const handleChangeSalesManContent = (itemId: number, itemName: string) => {
    setSelectedSalesManId(itemId === selectedSalesManId ? 0 : itemId);
    setFilterObject((prevState) => ({
      ...prevState,
      ["salesManId"]: Number(itemId),
    }));
  };

  const handleChangeBreederContent = (itemId: number, itemName: string) => {
    setSelectedBreederId(itemId === selectedBreederId ? 0 : itemId);
    setFilterObject((prevState) => ({
      ...prevState,
      ["breederId"]: Number(itemId),
    }));
  };

  const handleChangeStageType = (itemId: number, itemName: string) => {
    setSelectedTypeId(itemId === selectedTypeId ? 0 : itemId);
    setSelectedTypeName(itemId === selectedTypeId ? "null" : itemName);
    switch (itemId) {
      case 1:
        setFilterObject((prevState) => ({
          ...prevState,
          ["has_breeder"]: true,
        }));
        break;

      case 2:
        setFilterObject((prevState) => ({
          ...prevState,
          ["has_housing"]: true,
          ["has_breeder"]: false,
          ["has_farm"]: false,
        }));
        break;

      case 3:
        setFilterObject((prevState) => ({
          ...prevState,
          ["has_farm"]: true,
          ["has_housing"]: false,
          ["has_breeder"]: false,
        }));
        break;

      default:
        break;
    }
  };

  const handleChangeAddedByType = (itemId: number, itemName: string) => {
    setSelectedAddedById(itemId === selectedAddedById ? 0 : itemId);
    setSelectedAddedByName(itemId === selectedAddedById ? "null" : itemName);
  };

  const handleSubmitFilter = () => {
    setIsLoading(true);
    let param1 =
      filterObject.salesManId !== 0
        ? `salesman_id=${filterObject.salesManId}&`
        : "";
    let param2 =
      filterObject.breederId !== 0
        ? `breeder_id=${filterObject.breederId}&`
        : "";
    let param3 =
      filterObject.createdBy !== ""
        ? `created_by${filterObject.createdBy}&`
        : "";
    let param4 =
      filterObject.has_breeder === true
        ? `has_breeder=${true}&has_farm=false&`
        : "";
    let param5 =
      filterObject.has_housing === true
        ? `has_housing=${true}&has_farm=true&has_breeder=${true}&`
        : "";
    let param6 =
      filterObject.has_farm === true
        ? `has_farm=${true}&has_housing=false`
        : "";
    let param7 =
      filterObject.gov_id !== 0 ? `gov_id=${filterObject.gov_id}&` : "";
    let param8 =
      filterObject.city_id !== 0 ? `city_id=${filterObject.city_id}&` : "";
    let status = `pending`;
    let type = 0;
    let filterObj: {}[] = [];
    let isFiltered = true;
    let totalCount = 0;

    if (location.pathname.includes("Pending")) {
      type = 1;
      status = `pending`;
      let filterUrl = `dashboard/missions?status=${status}&sort_by=id&sort_order=DESC&${param1}${param2}${param3}${param4}${param5}${param6}${param7}${param8}`;
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("InProgress")) {
      type = 2;
      status = `in_progress`;
      let filterUrl = `dashboard/missions?status=${status}&sort_by=id&sort_order=DESC&${param1}${param2}${param3}${param4}${param5}${param6}${param7}${param8}`;
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("Late")) {
      type = 3;
      status = `late`;
      let filterUrl = `dashboard/missions?status=${status}&sort_by=id&sort_order=DESC&${param1}${param2}${param3}${param4}${param5}${param6}${param7}${param8}`;
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("Evaluation")) {
      type = 4;
      status = `revision`;
      let filterUrl = `dashboard/missions?status=${status}&sort_by=id&sort_order=DESC&${param1}${param2}${param3}${param4}${param5}${param6}${param7}${param8}`;
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("Finished")) {
      type = 5;
      status = `completed`;
      let filterUrl = `dashboard/missions?status=${status}&sort_by=id&sort_order=DESC&${param1}${param2}${param3}${param4}${param5}${param6}${param7}${param8}`;
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("Refused")) {
      type = 6;
      status = `rejected`;
      let filterUrl = `dashboard/missions?status=${status}&sort_by=id&sort_order=DESC&${param1}${param2}${param3}${param4}${param5}${param6}${param7}${param8}`;
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("All")) {
      type = 7;
      let filterUrl = `dashboard/missions?sort_by=id&${param1}${param2}${param3}${param4}${param5}${param6}${param7}${param8}`;
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;

        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    }
  };

  return (
    <div className="MissionFilter accordion-group">
      {isLoading === true ? <Loading /> : null}
      <input className="modal-state" id="modal-2" type="checkbox" />
      <div className="modal w-screen ">
        <label className="modal-overlay" htmlFor="modal-2"></label>
        <div
          className="modal-content flex flex-col gap-5 max-w-3xl"
          style={{ width: "580px" }}
        >
          <div className="grid grid-cols-2">
            {/* المحافظة / المكان */}
            <>
              <div className="col-start-1 flex justify-start pr-4">
                <div className="head">المكان</div>
              </div>
              <div className="col-span-full mt-4 flex gap-8 items-center">
                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeLocationType(1, "governorate")}
                    checked={1 === selectedItemId}
                  />
                  <span className="txt">المحافظة</span>
                </div>

                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeLocationType(2, "city")}
                    checked={2 === selectedItemId}
                  />
                  <span className="txt">المنطقة</span>
                </div>
              </div>
              {/* اكتب كلمة البحث للمحافظة */}
              <div className="col-span-full p-4">
                <div className="form-control relative w-full">
                  <input
                    type="text"
                    className="input input-lg max-w-full input-search"
                    onChange={handleLocationSearch}
                    placeholder={
                      selectedItemId === 1
                        ? "ابحث عن المحافظة"
                        : "ابحث عن المنطقة"
                    }
                  />

                  <span className="absolute inset-y-0 right-4 inline-flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.4998 17.4998L14.1665 14.1665"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              {/* قائمة المحافظات */}
              <div className="col-span-full mt-4 p-4 mb-4 h-32 overflow-y-auto ">
                <div className="types-list ">
                  <ul className="list-none scrollable-list">
                    {locationSearchResults.map((item) => {
                      return (
                        <>
                          {isLoading === true ? (
                            <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                          ) : (
                            <li className="flex justify-between pl-4 py-2">
                              <span className="list-text">{item.name}</span>
                              <div>
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-bordered-success"
                                  onChange={() =>
                                    handleChangeLocationContent(
                                      item.id,
                                      item.name
                                    )
                                  }
                                  checked={item.id === selectedLocationId}
                                />
                              </div>
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </>
            <div className="col-span-full">
              <div className="divider"></div>
            </div>
            {/* المناديب */}
            <>
              <div className="col-start-1 flex justify-start pr-4">
                <div className="head">المندوب</div>
              </div>
              {/* اكتب كلمة البحث للمندوب */}
              <div className="col-span-full p-4">
                <div className="form-control relative w-full">
                  <input
                    type="text"
                    className="input input-lg max-w-full input-search"
                    onChange={handleSalesManSearch}
                    placeholder="ابحث عن المندوب"
                  />

                  <span className="absolute inset-y-0 right-4 inline-flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.4998 17.4998L14.1665 14.1665"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              {/* قائمة المناديب */}
              <div className="col-span-full mt-4 p-4 mb-4 h-32 overflow-y-auto ">
                <div className="types-list ">
                  <ul className="list-none scrollable-list">
                    {salesManSearchResults.map((item) => {
                      return (
                        <>
                          {isLoading === true ? (
                            <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                          ) : (
                            <li className="flex justify-between pl-4 py-2">
                              <span className="list-text">{item.name}</span>
                              <div>
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-bordered-success"
                                  onChange={() =>
                                    handleChangeSalesManContent(
                                      item.id,
                                      item.name
                                    )
                                  }
                                  checked={item.id === selectedSalesManId}
                                />
                              </div>
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </>
            <div className="col-span-full">
              <div className="divider"></div>
            </div>

            {/* المربيين */}
            <>
              <div className="col-start-1 flex justify-start pr-4">
                <div className="head">المربي</div>
              </div>
              {/* اكتب كلمة البحث للمربي */}
              <div className="col-span-full p-4">
                <div className="form-control relative w-full">
                  <input
                    type="text"
                    className="input input-lg max-w-full input-search"
                    onChange={handleBreedersSearch}
                    placeholder="ابحث عن المربي"
                  />

                  <span className="absolute inset-y-0 right-4 inline-flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.4998 17.4998L14.1665 14.1665"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              {/* قائمة المربيين */}
              <div className="col-span-full mt-4 p-4 mb-4 h-32 overflow-y-auto ">
                <div className="types-list ">
                  <ul className="list-none scrollable-list">
                    {breederSearchResults.map((item) => {
                      return (
                        <li className="flex justify-between pl-4 py-2">
                          <span className="list-text">{item.name}</span>
                          <div>
                            <input
                              type="checkbox"
                              className="checkbox checkbox-bordered-success"
                              onChange={() =>
                                handleChangeBreederContent(item.id, item.name)
                              }
                              checked={item.id === selectedBreederId}
                            />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </>
            <div className="col-span-full">
              <div className="divider"></div>
            </div>
            {/* المرحلة */}
            <>
              <div className="col-start-1 flex justify-start pr-4">
                <div className="head">المرحلة</div>
              </div>
              {/* انواع المراحل */}
              <div className="col-span-full mt-4 flex gap-8 items-center">
                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeStageType(1, "farmer")}
                    checked={1 === selectedTypeId}
                  />
                  <span className="txt">مرحلة المربي</span>
                </div>
                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeStageType(2, "housing")}
                    checked={2 === selectedTypeId}
                  />
                  <span className="txt">مرحلة التسكين</span>
                </div>

                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeStageType(3, "farm")}
                    checked={3 === selectedTypeId}
                  />
                  <span className="txt">مرحلة المزرعة </span>
                </div>
              </div>
            </>
            <div className="col-span-full">
              <div className="divider"></div>
            </div>

            {/* الاضافة من */}
            <>
              <div className="col-start-1 flex justify-start pr-4">
                <div className="head">الاضافة من</div>
              </div>

              {/* انواع الاضافة من */}
              <div className="col-span-full mt-4 flex gap-8 items-center">
                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeAddedByType(1, "admin")}
                    checked={1 === selectedAddedById}
                  />
                  <span className="txt">الأدمن</span>
                </div>
                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeAddedByType(2, "sales")}
                    checked={2 === selectedAddedById}
                  />
                  <span className="txt">السيلز</span>
                </div>

                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeAddedByType(3, "normal")}
                    checked={3 === selectedAddedById}
                  />
                  <span className="txt">طبيعي</span>
                </div>
              </div>
            </>
          </div>

          <div className="pt-4">
            <div className="grid grid-cols-2">
              <div className="col-span-full">
                <label htmlFor="modal-2">
                  <div
                    className="add-btn w-full p-4"
                    onClick={() => handleSubmitFilter()}
                  >
                    تأكيد
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
